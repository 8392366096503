/**
 * 媒體 code 紀錄
 */
import { isEmptyValue } from '@/helper/data-process';
import { objectToQueryString } from '@/helper/string';
import { clearTracking, cookieKey, cookieTimeKey } from '@/helper/tracking-code';
import { logEvent } from '@/helper/gtm-webview';
import { blackLogStyle } from '@/helper/debug';
import { getGtmDataLayer } from '@/helper/gtm';

const checkTrackingType = (query) => {
  // shopback
  if (!isEmptyValue(query.co_name)) {
    return 'shopback';
  }

  // 美安
  if (!isEmptyValue(query.RID) && !isEmptyValue(query.Click_ID)) {
    return 'shop.com';
  }

  // SDAPP
  if (!isEmptyValue(query.utm_source) && query.utm_source === 'sdapp') {
    return 'sdapp';
  }

  // omo線下導流
  if (!isEmptyValue(query.utm_source) && ['printed', 'pr'].includes(query.utm_source)) {
    return 'omo';
  }

  // 虛擬貨架
  if (!isEmptyValue(query.utm_source) && query.utm_source === 'metime' && !isEmptyValue(query.utm_medium) && query.utm_medium === 'etv') {
    return 'virtualShelf';
  }

  // AP推薦連結導購
  if (!isEmptyValue(query.utm_source) && query.utm_source === 'e_ap') {
    return 'AP';
  }

  // other
  if (!isEmptyValue(query.utm_source)) {
    return 'other';
  }

  return '';
};

/**
 * 媒體 code 紀錄
 * @param route
 */
export default function (context) {
  const { route, pluginContext } = context;
  const { $gtm } = pluginContext;
  const gtmSend = getGtmDataLayer($gtm);

  // page view
  gtmSend.push({
    event: 'nuxtRoute', // pageview
    pageTitle: '誠品線上 - 閱讀與生活的無盡想像',
    pageType: 'PageView',
    pageUrl: route.fullPath,
    routeName: route.name,
  });
  logEvent('pageview', { page: route.fullPath });

  // query event
  const type = checkTrackingType(route.query);
  // 埋 code
  if (isEmptyValue(type)) return;
  if (import.meta.env.VITE_TYPE !== 'prd') console.info('%cUTM', blackLogStyle, { type, path: route.fullPath });

  clearTracking();
  const query = { ...route.query };
  // 過濾掉 jwttoken
  delete query.jwttoken;
  const queryObject = objectToQueryString(query);

  localStorage.setItem(cookieKey, queryObject);
  localStorage.setItem(cookieTimeKey, new Date());
}
