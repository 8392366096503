<template>
  <div class="banner">
    <div v-if="(hasHorizontalPaint || isShowSkeleton) && isMoreThenmediumSize" class="top-banner-bar tw-hidden md:tw-flex tw-justify-center" :style="backGroundColor">
      <div class="box">
        <div class="image-wrapper">
          <horizontal-paint-image-link :is-show-skeleton="isShowSkeleton" :image-source="horizontalPaint" link-id="header-banner"></horizontal-paint-image-link>
        </div>
      </div>
    </div>
    <!-- mobile-ad-->
    <div v-if="(hasMobileHorizontalPaint || isShowSkeleton) && Ad && !isMoreThenmediumSize" class="mobile-top-banner-bar tw-flex tw-justify-center md:tw-hidden" :style="mobileBackGroundColor">
      <div class="close icon-order-fail" @click="closeAd"><span class="path1"></span><span class="path2"></span></div>
      <div class="box">
        <div class="image-wrapper">
          <horizontal-paint-image-link :is-show-skeleton="isShowSkeleton" :image-source="mobileHorizontalPaint" link-id="mobile-header-banner"></horizontal-paint-image-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalAdStore } from '@/stores/global-ad';
import dayjs from '@/helper/filter/date';
import { isEmptyValue } from '@/helper/data-process';
import HorizontalPaintImageLink from '@/components/ad/horizontal-paint-image-link.vue';
import { breakpoints } from '@/constant/breakpoint.js';

const HEADER_BANNER_KEY = `headerBannerTime`;

const props = {
  isLoaded: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'HorizontalPaint',
  components: { HorizontalPaintImageLink },
  props,
  setup(props) {
    const $screen = inject('$screen');
    const globalAdStore = useGlobalAdStore();
    const { getHorizontalPaint: horizontalPaint, getMobileHorizontalPaint: mobileHorizontalPaint } = storeToRefs(globalAdStore);

    // data
    const Ad = ref(false);
    // computed
    const isMoreThenmediumSize = computed(() => $screen.width >= breakpoints.medium);
    const hasHorizontalPaint = computed(() => Object.keys(horizontalPaint.value).length > 0);
    const backGroundColor = computed(() => {
      return { '--back-ground-color': horizontalPaint.value.color };
    });
    const hasMobileHorizontalPaint = computed(() => Object.keys(mobileHorizontalPaint.value).length > 0);
    const mobileBackGroundColor = computed(() => {
      return { '--back-ground-color': mobileHorizontalPaint.value.color };
    });
    const isUseMobileBanner = computed(() => {
      return $screen.isExtraSmallWidth || $screen.isSmallWidth;
    });
    const isShowSkeleton = computed(() => {
      if (props.isLoaded) return false;
      if (isUseMobileBanner.value && hasMobileHorizontalPaint.value) return false;
      if (!isUseMobileBanner.value && hasHorizontalPaint.value) return false;
      return true;
    });

    // methods
    function closeAd() {
      Ad.value = false;
      const time = +dayjs().add(1, 'day');
      localStorage.setItem(HEADER_BANNER_KEY, +time);
    }
    function checkToOpenAd() {
      const time = localStorage.getItem(HEADER_BANNER_KEY);
      const oldTime = isEmptyValue(time) ? 0 : +dayjs(parseInt(time, 10));
      const now = +dayjs();
      if (isEmptyValue(time) || oldTime < now) Ad.value = true;
    }

    // created
    checkToOpenAd();

    return {
      Ad,
      // computed
      isMoreThenmediumSize,
      horizontalPaint,
      mobileHorizontalPaint,
      hasHorizontalPaint,
      backGroundColor,
      hasMobileHorizontalPaint,
      mobileBackGroundColor,
      isUseMobileBanner,
      isShowSkeleton,
      // methods
      closeAd,
    };
  },
};
</script>

<style scoped lang="scss">
.banner {
  overflow: hidden;
}
.top-banner-bar {
  .box {
    width: 100%;
    max-width: 1920px;
  }
  .image-wrapper {
    vertical-align: top;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;

    @include mediaMax($grid-breakpoints-xl) {
      width: 150%;
    }
  }
}

@include mediaMax($grid-breakpoints-md) {
  .mobile-top-banner-bar {
    position: relative;
    .box {
      width: 100%;
      max-width: 540px;
    }
    .image-wrapper {
      width: 100%;
    }
  }
  .close {
    position: absolute;
    z-index: 2;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    text-shadow: none;
    font-weight: normal;
    float: none;
  }
}
</style>
