import { filter } from 'ramda';
import { defineStore } from 'pinia';
import { toQueryString } from '@/helper/url/query-string';

const basePath = '/';

const state = () => ({
  previous: basePath,
  now: basePath,
  currentParams: {},
  previousParams: {},
  bringQuery: ['/search'],
});

const getters = {
  getCurrent: (state) => state.now,
  getPrevious: (state) => state.previous,
  getPreviousParams: (state) => state.previousParams,
  getCurrentParams: (state) => state.currentParams,
  getPreviousQuery: (state) => toQueryString(state.previousParams),
  getCurrentQuery: (state) => toQueryString(state.currentParams),
};

const actions = {
  setChangePage(uri) {
    this.setPreviousUri(this.now);
    this.setCurrentUri(uri);
  },
  setPreviousUri(uri) {
    this.previous = uri || basePath;
  },
  setCurrentUri(uri) {
    this.now = uri || basePath;
  },
  setPreviousParams(params) {
    this.previousParams = params;
  },
  setCurrentParams(params) {
    this.currentParams = params;
  },
  addUrlSearchPage(page) {
    this.bringQuery = [...this.bringQuery, page];
  },
  removeUrlSearchPage(page) {
    filter((x) => x !== page)(this.bringQuery);
  },
};

export const useVisitStore = defineStore('visit', {
  state,
  getters,
  actions,
});
