/**
 * 購物車項目 －銷售提示
 * 0：一般商品，無提示
 * 1: 預購商品
 * 2: 客製商品
 * 9: 單獨出貨（拆單）商品
 * @type {{"0": string, "1": string, "2": string, "9": string}}
 */
export const cartItemSaleWarningEnum = {
  normal: 0,
  preOrder: 1,
  customized: 2,
  shippingLimit: 9,
};

const additionalItemWarningType = 20;
export const additionalItemWarning = { type: additionalItemWarningType, text: '' };

export const saleWarningLabelEnum = {
  0: '一般商品',
  1: '預購商品',
  2: '訂製商品',
  9: '運送限制',
};

export const saleWarningCssClass = {
  0: '',
  1: 'e-blue',
  2: 'e-purple',
  9: 'e-green',
};

/**
 * 購物車項目 －整車優惠
 *   price 價格優惠
 *   gift  贈品優惠
 * @type {{giftDiscount: string, priceDiscount: string}}
 */
export const cartDiscountTypeEnum = {
  priceDiscount: 'price',
  giftDiscount: 'gift',
};

/**
 * 優惠券類型
 *   coupon 優惠券
 *   serial 即享券（序號）
 *   '' 無
 * @type {{serialNumber: string, coupon: string, none: string}}
 */
export const cartOtherDiscountTypeEnum = {
  coupon: 'coupon',
  serialNumber: 'serial',
  none: '',
};

/**
 * 誠品點足夠與否狀態
 * @type {{insufficient: boolean, enough: boolean}}
 */
export const pointStatus = {
  enough: true,
  insufficient: false,
};

export const cartNotAvailableFlag = 'cartNotAvailableFlag';
export const cartNotAvailableStatus = {
  disable: 'disable',
};

export const giftType = {
  product: 'product',
  cart: 'cart',
  none: '',
};
export const giftTypeText = {
  [giftType.product]: '贈品',
  [giftType.cart]: '活動贈品',
  [giftType.none]: '贈品',
};

// 收件人資訊-選用模式
export const recipientUseTypes = { addressBooks: 'address_books', default: 'default' };

/**
 * 購物車 step2 狀態列表
 * @type {{key: string}}
 */
export const statusList = {
  empty: 'empty', // 預設空值（不動作）
  created: 'created', // created
  mounted: 'mounted', // mounted
  delivery: 'delivery', // 配送方式
  subscriber: 'subscriber', // 訂購人表單（驗證 success 觸發）
  recipient: 'recipient', // 收件人表單（驗證 success 觸發）
  recipientType: 'recipientType', // 收件人狀態 （近期寄送, 自填）
  selectStore: 'selectStore', // 收件人超取選擇門市
  updatePoints: 'updatePoints', // 誠品點異動
  payment: 'payment', // 付款方式
  validate: 'validate', // 全表單驗證
  checkout: 'checkout', // 表單送出
};

/** @const {array} islandCodes 台灣離島郵遞區號 */
export const islandCodes = /^(88|89|209|21|951|952|929|817|819|290)/;
