import { includes } from 'ramda';
import { defineStore } from 'pinia';
import { emptyRecipient, emptySubscriber, emptyInvoiceData } from '@/constant/checkout-result';
import { deliveryTypeEnum } from '@/constant/delivery-type';
import { isEmptyValue } from '@/helper/data-process';
import { recipientUseTypes, statusList } from '@/constant/cart';
import cryptoUtility from '@/helper/crypto.js';

const defaultCountry = 'TW';

const baseState = {
  // header shopping cart numbers
  quantity: null,
  step: 1,
  status: statusList.empty,
  subTotal: 0,
  // button
  itemLocked: false,
  remainTimesToUpdateCartItemsFromChangeQuantity: 0,
  changingItemQuantity: false,
  // step 2 todo: 上下一步還沒寫 store 喔喔喔！
  payment: '',
  delivery: '',
  deliveries: [],
  subscriber: { ...emptySubscriber },
  recipient: { ...emptyRecipient },
  invoice: { ...emptyInvoiceData },
  // 完成訂單會取得 orderId
  orderId: '',
  // 金額計算
  cartsSummary: 0,
  singleSummary: 0,
  cartDiscount: 0,
  otherDiscount: 0,
  fee: 0,
  // 初始資料是否為加密狀態
  encode: false,
  // 訂購人預設資料來源: null, 'order', 'member'
  type: null,
  // 訂購人資料來源若為訂單時的訂單編號
  order_number: '',
  email: '',
  recipientUseType: 'default',
  // 是否為首購免運 (會同步連動運費部分)
  firstPurchase: false,
};

const state = () => ({
  ...baseState,
});

const getRecipientCity = (state) => state.recipient.city;
const getRecipientDistrict = (state) => state.recipient.district;
const getRecipientAddress = (state) => state.recipient.address;
const getRecipientFullAddress = (state) => state.recipient.full_address;
const getRecipientCountry = (state) => state.recipient.country;

const getRecipientMobile = (state) => state.recipient.mobile;
const getRecipientTelephone = (state) => state.recipient.telephone;
const getRecipientStore = (state) => state.recipient.store;
const getSubscriberCity = (state) => state.subscriber.city;
const getSubscriberDistrict = (state) => state.subscriber.district;
const getSubscriberAddress = (state) => state.subscriber.address;
const getSubscriberFullAddress = (state) => state.subscriber.full_address;
const getSubscriberCountry = (state) => state.subscriber.country;

const getSubscriberMobile = (state) => state.subscriber.mobile;
const getSubscriberTelephone = (state) => state.subscriber.telephone;
const getStoreCity = (state) => state.recipient.store.city;
const getStoreId = (state) => state.recipient.store.id;
const getInvoiceType = (state) => state.invoice.type;
/**
 * 取得門市資訊
 * @param {object} state store
 * @returns {object} store 門市資訊
 * @returns {string} store.id 門市 id
 * @returns {string} store.name 門市名稱
 * @returns {string} store.city 門市縣市
 * @returns {string} store.address 門市地址
 */
const getStore = (state) => state.recipient.store;

const getCartsSummary = (state) => state.cartsSummary;
const getSingleSummary = (state) => state.singleSummary;
const getCartDiscount = (state) => state.cartDiscount;
const getOtherDiscount = (state) => state.otherDiscount;
const getFee = (state) =>
  state.firstPurchase && [deliveryTypeEnum.convenienceStore, deliveryTypeEnum.familyMart, deliveryTypeEnum.sevenEleven, deliveryTypeEnum.hongKongStore].includes(state.delivery) ? 0 : state.fee;
const getFirstPurchase = (state) => state.firstPurchase;

export const getters = {
  // 訂購人 subscriber
  getSubscriberCity,
  getSubscriberDistrict,
  getSubscriberAddress,
  getSubscriberFullAddress,
  getSubscriberCountry,
  getSubscriberMobile,
  getSubscriberTelephone,
  // 收件人 recipient
  getRecipientCity,
  getRecipientDistrict,
  getRecipientAddress,
  getRecipientFullAddress,
  getRecipientCountry,
  getRecipientMobile,
  getRecipientTelephone,
  getRecipientStore,
  // 門市
  getStoreCity,
  getStoreId,
  getStore,
  // 近期配送
  // 其他
  getInvoiceType,
  getCartsSummary,
  getSingleSummary,
  getCartDiscount,
  getOtherDiscount,
  getFee,
  getFirstPurchase,
};

const actions = {
  setStatus(type) {
    if (includes(type, Object.keys(statusList)) === false) return false;
    this.status = type;
    return this.status;
  },
  setStep(step) {
    this.step = step;
  },
  setOrderId(id) {
    this.orderId = id;
  },
  setDelivery(type) {
    if (type === this.delivery) return false;
    this.delivery = type;
    this.setStatus(statusList.delivery);
    return this.delivery;
  },
  setDeliveries(type) {
    this.deliveries = type;
  },
  setEncode(type = false) {
    this.encode = type;
  },
  setType(type = null) {
    this.type = type;
  },
  setOrderNumber(number = '') {
    this.order_number = number;
  },
  setEmail(email = '') {
    this.email = email;
  },
  setRecipientUseType(type = null) {
    this.recipientUseType = includes(type, [recipientUseTypes.addressBooks, recipientUseTypes.default]) ? type : recipientUseTypes.default;
  },
  // recipient 非整包Object的其他
  setRecipient(data) {
    delete data.mobile;
    delete data.telephone;
    delete data.store;
    if (this.recipient.country === '') this.recipient.country = defaultCountry;
    if (!isEmptyValue(data.address)) data.full_address = `${data.city}${data.district}${data.address}`;
    else data.full_address = '';
    this.recipient = {
      ...data,
      name: isEmptyValue(data.name) ? '' : cryptoUtility.encrypt(data.name),
      mobile: this.recipient.mobile,
      telephone: this.recipient.telephone,
      store: this.recipient.store,
    };
    data = null;
  },
  // recipient 地址組
  setRecipientCity(data) {
    this.recipient.city = decodeURI(data);
  },
  setRecipientDistrict(data) {
    this.recipient.district = decodeURI(data);
  },
  setRecipientAddress(data) {
    this.recipient.address = decodeURI(data);
  },
  setRecipientFullAddress(data) {
    this.recipient.full_address = decodeURI(data);
  },
  setRecipientCountry(data = defaultCountry) {
    this.recipient.country = data;
  },
  // recipient 整包Object的組合
  setRecipientMobile(data) {
    this.recipient.mobile = { ...data, number: cryptoUtility.encrypt(data.number) };
    data = null;
  },
  setRecipientTelephone(data) {
    this.recipient.telephone = { ...data, number: cryptoUtility.encrypt(data.number) };
    data = null;
  },
  setRecipientStore(data) {
    this.recipient.store = { ...data };
  },
  // subscriber 非整包Object的其他
  setSubscriber(data) {
    delete data.mobile;
    delete data.telephone;
    delete data.store;
    delete data.email;
    if (this.subscriber.country === '') this.subscriber.country = defaultCountry;
    if (!isEmptyValue(data.address)) data.full_address = `${data.city || ''}${data.district || ''}${data.address}`;
    else data.full_address = '';
    this.subscriber = {
      ...data,
      name: isEmptyValue(data.name) ? '' : cryptoUtility.encrypt(data.name),
      mobile: this.subscriber.mobile,
      telephone: this.subscriber.telephone,
    };
    data = null;
  },
  // subscriber 地址組
  setSubscriberCity(data) {
    this.subscriber.city = decodeURI(data);
  },
  setSubscriberDistrict(data) {
    this.subscriber.district = decodeURI(data);
  },
  setSubscriberAddress(data) {
    this.subscriber.address = decodeURI(data);
  },
  setSubscriberFullAddress(data) {
    this.subscriber.full_address = decodeURI(data);
  },
  setSubscriberCountry(data = defaultCountry) {
    this.subscriber.country = data;
  },
  // subscriber 整包Object的組合
  setSubscriberMobile(data) {
    this.subscriber.mobile = {
      ...data,
      country: isEmptyValue(data.country) || data.country === 0 ? 886 : data.country,
      number: cryptoUtility.encrypt(data.number),
    };
    data = null;
  },
  setSubscriberTelephone(data) {
    if (typeof data.country === 'string') {
      data.country = Number(data.country);
    }

    this.subscriber.telephone = {
      ...data,
      country: isEmptyValue(data.country) || data.country === 0 ? 886 : data.country,
      number: cryptoUtility.encrypt(data.number),
    };
    data = null;
  },
  setPayment(type) {
    this.payment = type;
  },
  setStoreCity(store) {
    this.recipient.store.city = store.city;
  },
  setStoreId(store) {
    this.recipient.store.city = store.id;
  },
  setInvoiceType(type) {
    this.invoice.type = type;
  },
  setCartsSummary(cartsSummary) {
    this.cartsSummary = cartsSummary;
  },
  setSingleSummary(singleSummary) {
    this.singleSummary = singleSummary;
  },
  setCartDiscount(cartDiscount) {
    this.cartDiscount = cartDiscount;
  },
  setOtherDiscount(otherDiscount) {
    this.otherDiscount = otherDiscount;
  },
  setFee(fee) {
    this.fee = fee;
  },
  clearAmount() {
    this.cartsSummary = 0;
    this.singleSummary = 0;
    this.cartDiscount = 0;
    this.otherDiscount = 0;
    this.fee = 0;
  },
  setFirstPurchase(type) {
    this.firstPurchase = type;
  },
  /**
   * 發票資訊 (TODO: 目前只有 type 有值)
   * @param {object} data
   * @param {string} data.type type
   * @param {string} data.company 公司抬頭
   * @param {string} data.number 統一編號
   * @param {string} data.cellphone_barcode 手機載具
   * @param {string} data.citizen_digital_certificate_barcode 自然人憑證
   */
  setInvoice(data = emptyInvoiceData) {
    this.invoice = { ...this.invoice, ...data };
  },
  /**
   * 門取資訊
   * @param {object} store 門市資訊
   * @param {string} store.id 門市 id
   * @param {string} store.name 門市名稱
   * @param {string} store.address 門市地址
   * @param {string} store.delivery 配送方式
   */
  setStore(store) {
    this.recipient.store.type = store.type;
    this.recipient.store.id = store.id;
    this.recipient.store.name = store.name;
    this.recipient.store.city = store.city || '';
    this.recipient.store.address = store.address || '';
    this.recipient.store.delivery = store.delivery || '';
  },
  /**
   * 按鈕狀態
   * @return {boolean}
   */
  releaseItem() {
    this.itemLocked = false;
    this.endChangingItemQuantity();
  },
  lockItem() {
    this.itemLocked = true;
  },
  startChangingItemQuantity() {
    this.changingItemQuantity = true;
  },
  endChangingItemQuantity() {
    this.changingItemQuantity = false;
  },
  addRemainTimesToUpdateCartItemsFromChangeQuantity() {
    this.remainTimesToUpdateCartItemsFromChangeQuantity += 1;
  },
  subRemainTimesToUpdateCartItemsFromChangeQuantity() {
    if (this.remainTimesToUpdateCartItemsFromChangeQuantity <= 0) {
      this.remainTimesToUpdateCartItemsFromChangeQuantity = 0;
      return;
    }
    this.remainTimesToUpdateCartItemsFromChangeQuantity -= 1;
  },
  cleanCart() {
    Object.assign(state, { ...baseState });
    sessionStorage.removeItem('step2-store');
  },
};

export const useCartStore = defineStore('cart', {
  state,
  getters,
  actions,
});
