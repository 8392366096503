<template>
  <div v-if="!hasHotSearch" class="hot-search-skeleton d-flex flex-column">
    <div class="title serif-font-bold text-center" data-test-id="title">流行熱搜</div>
    <div class="content"></div>
  </div>
  <div v-else class="hot-search px-3 py-3">
    <img v-if="hotSearchAd.image" :src="hotSearchAd.image" :alt="hotSearchAd.alt" class="searchBg" />
    <div class="title text-center serif-font-bold" data-test-id="title">流行熱搜<span class="ps-1 icon-trends"></span></div>
    <div class="container mt-3">
      <div class="row">
        <div v-for="(item, index) in filterData" :key="`filter-data-${index}`" class="tag px-0 mb-3 d-flex justify-content-center">
          <a :id="getGtmId(index * 2 + 1)" :href="getLink(item[0])" :class="{ active: filterBg(index * 2 + 1) }" class="d-flex align-items-center">
            <span># {{ getText(item[0]) }}</span>
            <span class="icon-fa-angle-right"></span>
          </a>
          <a :id="getGtmId(index * 2 + 2)" :href="getLink(item[1])" :class="{ active: filterBg(index * 2 + 2) }" class="ms-2 d-flex align-items-center">
            <span># {{ getText(item[1]) }}</span>
            <span class="icon-fa-angle-right"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useGetHomeAdStore } from '@/composables/store/home-ad';

const listActive = [1, 4, 5, 8, 9, 12];
const getGtmId = (index = 1) => `home-search-${index}`;
const getLink = (item) => item.link;
const getText = (item) => item.text;
const filterBg = (e) => listActive.includes(e);

const setup = function () {
  const { hotSearch: hotSearchAd } = useGetHomeAdStore();
  const hasHotSearch = computed(() => hotSearchAd.value?.items?.length > 0);
  const filterData = computed(() => {
    if (!hasHotSearch.value) return [];
    const copyData = [...hotSearchAd.value.items];
    const resetData = [];
    while (copyData.length > 0) {
      resetData.push(copyData.splice(0, 2));
    }
    return resetData;
  });

  return {
    hotSearchAd,
    hasHotSearch,
    filterData,
    getGtmId,
    getLink,
    getText,
    filterBg,
  };
};

export default {
  name: 'HotSearch',
  setup,
};
</script>

<style lang="scss" scoped>
$focus-color: var(--rouge);
.hot-search {
  position: relative;
  z-index: 0;
  img.searchBg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
.title {
  color: $focus-color;
  text-shadow: 1px 1px 0px var(--white);
  @include font18;
  line-height: 2.5rem;
}
.tag {
  @include font16;
  line-height: 2rem;
  width: 100%;
  a {
    text-decoration: none;
    width: auto;
    max-width: 100%;
    color: $focus-color;
    background-color: var(--white);
    border: solid 2px $focus-color;
    border-radius: 173.8px;
    padding: 3px 10px;
    overflow: hidden;
    span:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    span:nth-child(2) {
      max-width: 100%;
      padding-left: 5px;
    }
  }
  .active {
    background-color: $focus-color;
    color: var(--white);
  }
}

.hot-search-skeleton {
  background-color: var(--l-gray-f4);
  .content {
    margin-top: 8rem;
    margin-bottom: 4rem;
  }
  .title {
    padding: 1rem 0;
    @include fontSize(20);
  }
}
</style>
