import VueGtag from 'vue-gtag';

export default ({ app }) => {
  app.use(VueGtag, {
    config: {
      id: import.meta.env.VITE_GTAG_CODE,
      params: {
        anonymize_ip: true,
        send_page_view: false,
      },
    },
  });
};
