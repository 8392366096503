<template>
  <div v-if="notInlineApp" class="header" :class="activeMenu">
    <horizontal-paint ref="horizontalRef" :is-loaded="isLoaded"></horizontal-paint>
    <div ref="headerFixedWrapperRef" class="header-fixed-wrapper" :class="{ 'is-header-fixed': isHeaderFixed }">
      <div class="header-fixable header-bg-color" data-test-id="header">
        <div class="header-main header-bg-color">
          <div class="tw-container lg:tw-mt-4">
            <div class="tw-row eslite-header tw-flex tw-justify-between lg:!tw-pr-0">
              <span class="tw-col-2 tw-flex lg:tw-hidden align-items-center icon icon-fa-bars" data-test-id="mobile-hamburger" @click="openMenu"></span>
              <div class="logo-wrapper tw-col-6 sm:tw-col-4 lg:tw-col-2">
                <router-link-usage id="header-logo" class="navbar-brand" link="/" title="誠品線上" :need-check-protected-page="true">
                  <div class="logo"></div>
                </router-link-usage>
              </div>
              <!-- 搜尋 bar-->
              <div v-if="!$screen.isMobileSize" class="search-block tw-row tw-col-6 tw-hidden lg:tw-flex">
                <div class="tw-col-12 tw-mx-0 tw-px-0">
                  <search-bar></search-bar>
                </div>
                <fullsite-keyword class="tw-mx-0 tw-pl-2" data-test-id="desktop-keywords"></fullsite-keyword>
              </div>
              <!-- 小廣告-->
              <div class="header-little-banner tw-col-2 tw-hidden lg:tw-flex tw-ml-0 tw-px-0 tw-mr-2 md:tw-mr-6">
                <a v-if="haslittleBanner" id="header-sbanner" :href="littleBanner.link" :target="linkTarget(littleBanner.blank)" :title="littleBanner.alt"
                  ><img :src="littleBanner.image" :alt="littleBanner.alt"
                /></a>
              </div>
              <loginHeader class="header-login tw-col-2 tw-px-0 lg:tw-mx-0"></loginHeader>
            </div>
          </div>
        </div>
        <!-- mobile search bar-->
        <div class="search-bar-mobile header-bg-color lg:tw-hidden">
          <div class="tw-container search-bar-min-height">
            <static-search-bar v-if="$screen.isMobileSize === true" @focus="setTypeStatus"></static-search-bar>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowMobileFullsiteKeyword" class="tw-container tw-bg-white">
      <fullsite-keyword data-test-id="mobile-keywords"></fullsite-keyword>
    </div>
    <fraud-prevention class="tw-container"></fraud-prevention>
    <menu-bar :open-menu="showMobileMenu" @closeMenu="closeMenu"></menu-bar>
    <mask-search-bar-modal v-if="isShowMaskSearch" :top="mobileSearchBarFloatTop" @close="closeSearchBarModal"></mask-search-bar-modal>
    <div v-if="hasCoverBanner && coverBannerIsActive" class="fixed-banner tw-text-white" :class="{ active: coverBannerIsActive }">
      <nav class="tw-mx-0">
        <div class="tw-absolute tw-w-full tw-h-full tw-bg-black/70" @click.prevent="fullFixedBanner"></div>
        <div class="tw-relative tw-w-full tw-h-full tw-max-w-[600px] tw-max-h-[800px]">
          <div class="close-btn" data-test-id="cover-banner-close-button" @click.prevent="fullFixedBanner"><i class="icon-fa-times"></i></div>
          <a id="header-fullbanner" :href="coverBanner.link" :target="target" :title="coverBanner.alt">
            <webp-image :src="coverBanner.image" :alt="coverBanner.alt" @load="setTimeClose"></webp-image
          ></a>
        </div>
      </nav>
    </div>
    <div v-if="hasCoverBannerWaterMark && waterMarkIsActive" class="little-banner">
      <div class="close-banner" data-test-id="cover-banner-water-mark-close-button" @click.prevent="closeFixedBanner">
        <div class="icon-fa-times"></div>
      </div>
      <a id="header-menu-fullbanner" :href="coverBannerWatermark.link" :target="targetLittle" :title="coverBannerWatermark.alt"
        ><img :src="coverBannerWatermark.image" :alt="coverBannerWatermark.alt"
      /></a>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash-es/throttle';
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';
import { ref, computed, defineAsyncComponent, inject, onUnmounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalAdStore } from '@/stores/global-ad';
import FullsiteKeyword from '@/components/ad/fullsite-keyword.vue';
import HorizontalPaint from '@/components/ad/horizontal-paint.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import getGlobalPage from '@/api/ad/global-page';
import { isElementTopBelowWindowTop } from '@/helper/scroll';
import checkIsInlineApp from '@/helper/check-inline-app';
import { isEmptyValue, linkTarget } from '@/helper/data-process';
import dayjs from '@/helper/filter/date';
import loginHeader from '@/components/layout/login-header.vue';
import WebpImage from '@/components/utility/image/webp-image.vue';
import { preloadImage } from '@/helper/meta/preload-image';
import WebpPathConvertService from '@/services/webp-path-convert-service';
import FraudPrevention from '@/components/header/fraud-prevention.vue';
import { useCategoriesStore } from '@/stores/categories';

const searchBar = defineAsyncComponent(() => import('@/components/layout/searchbar.vue'));
const menuBar = defineAsyncComponent(() => import('@/components/layout/menu-bar.vue'));
const StaticSearchBar = defineAsyncComponent(() => import('@/components/search/searchBar/static-search-bar.vue'));
const MaskSearchBarModal = defineAsyncComponent(() => import('@/components/search/mask-search-bar-modal.vue'));

// 檢查時間有無過期
function actionWhenGetCoverBannerExpiredTime(expiredTime) {
  const isExpired = dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(expiredTime).format('YYYY-MM-DD'));
  return isExpired;
}

// 檢查浮水印是否關閉
function getSwitchWaterMark() {
  if (JSON.parse(localStorage.getItem('onSwitchWaterMark')) === null) return true;
  return JSON.parse(localStorage.getItem('onSwitchWaterMark'));
}

function getTriggerTime(val) {
  return localStorage.getItem(val);
}

const setup = () => {
  const $screen = inject('$screen');
  const route = useRoute();

  /** Store */
  const globalAdStore = useGlobalAdStore();
  const { getCoverBannerWatermark: coverBannerWatermark, getCoverBanner: coverBanner, getLittleBanner: littleBanner, getRecommendKeyword: keyword } = storeToRefs(globalAdStore);
  const { setData: setAdData, setMobileData } = globalAdStore;
  const categoriesStore = useCategoriesStore();
  const { getGeneralCategories } = storeToRefs(categoriesStore);
  const { fetchCategories } = categoriesStore;

  const list = computed(() => getGeneralCategories.value);

  const horizontalRef = ref(null);
  const headerFixedWrapperRef = ref(null);

  const showMobileMenu = ref(false);
  const isHeaderFixed = ref(false);
  const isLoaded = ref(false);
  const notInlineApp = ref(false);
  const isTyping = ref(false);
  const mobileSearchBarFloatTop = ref(0);
  const coverBannerIsActive = ref(false);
  const waterMarkIsActive = ref(false);
  const showFixedBannerTimer = ref(null);
  const showWatermarkTimer = ref(null);
  // pageYOffset: 0,
  // isScrollDown: false,
  // searchKeyword: '',

  /**
   * computed
   */
  const target = computed(() => linkTarget(coverBanner.value?.blank));
  const targetLittle = computed(() => linkTarget(coverBannerWatermark.value?.blank));
  const hasCoverBanner = computed(() => {
    return Object.keys(coverBanner.value).length > 0 && !isEmptyValue(coverBanner.value.image);
  });
  const hasCoverBannerWaterMark = computed(() => {
    return Object.keys(coverBannerWatermark.value).length > 0 && !isEmptyValue(coverBannerWatermark.value.image);
  });
  const haslittleBanner = computed(() => Object.keys(littleBanner.value).length > 0);
  const activeMenu = computed(() => {
    return { active: showMobileMenu.value };
  });
  const isShowMobileFullsiteKeyword = computed(() => {
    if ($screen.isDesktopSize) return false;
    return route.path === '/';
  });

  const metaInfo = computed(() => {
    if (hasCoverBanner.value && !coverBannerIsActive.value) return {};
    if (coverBanner.value.image) preloadImage(WebpPathConvertService.convertPath(coverBanner.value.image));
    return {};
  });
  useMeta(metaInfo);

  /**
   * methods
   */
  // 啟用蓋版，最後再丟值到 localStorage
  const activateCoverBanner = () => {
    coverBannerIsActive.value = true;
    localStorage.setItem('coverBannerTriggeredAt', new Date());
  };
  // 啟用蓋版，最後再丟值到 localStorage
  const activateWaterMark = () => {
    waterMarkIsActive.value = true;
    localStorage.setItem('onSwitchWaterMark', true);
    localStorage.setItem('waterMarkTriggeredAt', new Date());
  };
  // 手動關閉浮水印
  const isCloseWaterMark = () => {
    if (getSwitchWaterMark()) {
      waterMarkIsActive.value = true;
    } else waterMarkIsActive.value = false;
  };
  // 6秒後再關起來
  const setTimeClose = () => {
    showFixedBannerTimer.value = setTimeout(() => {
      coverBannerIsActive.value = false;
    }, 6000);
  };
  // 蓋板廣告是否過期，在做後續處理
  const checkCoverBannerExpiredTime = (triggerTime) => {
    if (actionWhenGetCoverBannerExpiredTime(triggerTime)) {
      activateCoverBanner();
    } else coverBannerIsActive.value = false;
  };
  // 浮水印是否過期，在做後續處理
  const checkWaterMarkExpiredTime = (triggerTime) => {
    if (actionWhenGetCoverBannerExpiredTime(triggerTime)) {
      activateWaterMark();
    } else {
      // 手動關閉
      isCloseWaterMark();
    }
  };
  const fullFixedBanner = () => {
    coverBannerIsActive.value = false;
    console.log({
      IsActive: coverBannerIsActive.value,
      Timer: showFixedBannerTimer.value,
    });
    if (showFixedBannerTimer.value !== null) clearTimeout(showFixedBannerTimer.value);
  };
  const closeFixedBanner = () => {
    waterMarkIsActive.value = false;
    if (showWatermarkTimer.value !== null) clearTimeout(showWatermarkTimer.value);
    localStorage.setItem('onSwitchWaterMark', false);
  };
  const closeSearchBarModal = () => {
    isTyping.value = false;
  };
  const setTypeStatus = () => {
    window.scrollTo({ top: 0 });
    if (!horizontalRef.value) return;
    // 取得物件高度（廣告）
    const elementHeight = Math.ceil(horizontalRef.value.$el.getBoundingClientRect().height);
    // 取得物件位置
    const positionFromTop = horizontalRef.value.$el.getBoundingClientRect().top || 0;
    // 算出畫面上廣告的位置 (top)
    const gap = Math.abs(positionFromTop) % elementHeight || 0;
    // 搜尋框要位移的 px
    mobileSearchBarFloatTop.value = elementHeight - gap;
    isTyping.value = true;
  };
  const openMenu = () => {
    showMobileMenu.value = true;
  };
  const closeMenu = () => {
    showMobileMenu.value = false;
  };
  const checkHeaderFixed = throttle(function () {
    if ($screen.isDesktopSize) return;
    if (!headerFixedWrapperRef.value) return;
    isHeaderFixed.value = !isElementTopBelowWindowTop(headerFixedWrapperRef.value);
  }, 100);

  const addListeners = () => {
    window.addEventListener('scroll', checkHeaderFixed);
    window.addEventListener('resize', checkHeaderFixed);
  };
  const removeListeners = () => {
    window.removeEventListener('scroll', checkHeaderFixed);
    window.removeEventListener('resize', checkHeaderFixed);
  };
  onUnmounted(() => {
    removeListeners();
  });
  const created = async function () {
    // 不是 app 內置瀏覽器？
    notInlineApp.value = !checkIsInlineApp();

    addListeners();
    // api 取得資訊
    const res = (await getGlobalPage()).data;
    // TODO 時間判斷帶調整
    // expired(newTime, new Date())
    if (Object.keys(littleBanner.value).length === 0) {
      setAdData(res);
      setMobileData(res);
    }
    isLoaded.value = true;

    // 若為 inline-app 時代替已經沒有的 menu 呼叫館別 api
    if (list.value.length <= 0 && notInlineApp.value === false) fetchCategories();
  };
  created();

  watch(
    hasCoverBanner,
    (val) => {
      if (!val) return;
      // 檢查時間
      const triggerTime = getTriggerTime('coverBannerTriggeredAt');
      if (!isEmptyValue(triggerTime)) {
        checkCoverBannerExpiredTime(triggerTime);
      } else activateCoverBanner();
    },
    { immediate: true },
  );
  watch(
    hasCoverBannerWaterMark,
    (val) => {
      if (!val) return;
      // 檢查時間
      const triggerTime = getTriggerTime('waterMarkTriggeredAt');
      if (!isEmptyValue(triggerTime)) {
        checkWaterMarkExpiredTime(triggerTime);
      } else activateWaterMark();
    },
    { immediate: true },
  );

  return {
    coverBannerWatermark,
    coverBanner,
    littleBanner,
    keyword,
    // template ref
    horizontalRef,
    headerFixedWrapperRef,
    // data
    showMobileMenu,
    isHeaderFixed,
    isLoaded,
    notInlineApp,
    mobileSearchBarFloatTop,
    coverBannerIsActive,
    waterMarkIsActive,
    // computed
    target,
    targetLittle,
    hasCoverBanner,
    hasCoverBannerWaterMark,
    isShowMaskSearch: isTyping,
    haslittleBanner,
    activeMenu,
    isShowMobileFullsiteKeyword,
    // methods
    setTimeClose,
    linkTarget,
    fullFixedBanner,
    closeFixedBanner,
    closeSearchBarModal,
    setTypeStatus,
    openMenu,
    closeMenu,
  };
};

const components = {
  FullsiteKeyword,
  HorizontalPaint,
  loginHeader,
  searchBar,
  menuBar,
  MaskSearchBarModal,
  StaticSearchBar,
  WebpImage,
  RouterLinkUsage,
  FraudPrevention,
};

export default {
  name: 'Header',
  components,
  setup,
};
</script>

<style scoped lang="scss">
@import '@/assets/mixins/layout/header.scss';

$header-height: 54;
$search-bar-height: 54;
$header-fixable-height: $header-height + $search-bar-height;

.eslite-header {
  padding: 0.7rem 0.5rem 0.4rem 0.1rem;
  span {
    align-items: center;
  }
  .icon {
    font-size: 1.5rem;
    color: var(--white);
  }
}
.logo {
  width: 172px;
  height: 45px;
  max-width: 100%;
  background-image: url('@/static/images/logo_r.webp');
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  margin: auto;
  left: -100%;
  right: -100%;
  top: 0;
}
.search-bar-min-height {
  @include pxToRem('min-height', $search-bar-height);
  background-color: var(--e-dark-red);
}
@include mediaMax($grid-breakpoints-lg) {
  .logo {
    width: 127px;
    height: 34px;
  }
}

.header-bg-color {
  background-color: var(--white);
}
.header-item-wrapper {
  .a {
    justify-content: left;
    align-items: start;
  }
  display: flex;
  justify-content: space-around;
}

.header-little-banner {
  a {
    max-width: 160px;
  }
  img {
    width: 100%;
  }
}

.logo-wrapper {
  position: relative;
}

@include mediaMin($grid-breakpoints-xl) {
  .header-little-banner {
    a {
      max-width: 200px;
    }
  }
}

@include mediaMax($grid-breakpoints-lg) {
  $box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  $header-zindex: 50;
  $header-main-zindex: $header-zindex + 1;

  .header-fixed-wrapper:not(.is-header-fixed) {
    position: relative;
    z-index: 5;
  }

  .header-fixed-wrapper.is-header-fixed {
    @include pxToRem(height, $header-fixable-height);
    overflow: hidden; // 避免蓋到網頁內容
    .header-fixable {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: $header-zindex;
      .header-main {
        position: relative;
        z-index: $header-main-zindex;
      }
      .search-bar-mobile {
        position: absolute;
        left: 0;
        width: 100%;
        box-shadow: $box-shadow;
      }
    }
  }

  .search-bar-mobile {
    @include pxToRem(height, $search-bar-height);
    overflow: hidden; // 避免蓋到網頁內容
    transform: translateY(0);
    transition: transform 0.3s ease;
    &.hide-search-bar {
      transform: translateY(-100%);
    }
  }

  .logo-wrapper {
    transform: translateY(4px);
  }

  .logo {
    background-image: url('@/static/images/logo_w.webp');
    background-position: center;
  }

  .eslite-header {
    background: linear-gradient(to bottom, var(--e-light-red), var(--e-dark-red));
    span {
      align-items: center;
    }
    .icon {
      font-size: 1.45rem;
      padding-top: 0;
    }
  }
}

//--- todo : 待menu製作的時候再確認可否拿掉
.left-menu {
  width: 200px;
}
.menu-link {
  color: #b3b3b3;
}
.navbar-light {
  .navbar-toggler-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
}

// 今日推薦
.fixed-banner {
  z-index: 70;
  position: fixed;
  right: 0;
  bottom: 150px;
  @include mediaMax($grid-breakpoints-lg) {
    bottom: 120px;
  }
  nav {
    top: 0;
    border: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: transparent;
    a {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 800px;
        object-fit: contain;
      }
    }
  }
  .close-btn {
    text-align: center;
    width: 47.8px;
    height: 47.8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: var(--eslite-red);
    color: var(--white);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    user-select: none;
    i {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.active {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    nav {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .bgc {
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .row {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 800px;
      }
    }
  }
}
.little-banner {
  margin-right: 5px;
  z-index: 60;
  width: 100px;
  height: 100px;
  position: fixed;
  right: 0;
  bottom: 150px;
  .icon-fa-times {
    color: var(--white);
  }
  .close-banner {
    position: absolute;
    right: 0;
    top: 0;
    width: 29px;
    height: 29px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  a {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
