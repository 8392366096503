import { inject, watch } from 'vue';

export function useWindowResizeEvent(resizeEventHandler) {
  const $screen = inject('$screen');
  watch(
    () => $screen.width,
    (newValue, oldValue) => {
      if (typeof resizeEventHandler !== 'function') return;
      resizeEventHandler(newValue, oldValue);
    },
    { immediate: true },
  );
}
