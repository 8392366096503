import { t } from '@/plugins/localization';

export const verifyComponent = {
  none: 'ChooseVerifyType',
  phone: 'ConfirmVerifyCode',
  email: 'ConfirmVerifyCode',
};

export const forgotPasswordStep = {
  verify: 'verify',
  sendCode: 'sendCode',
  confirm: 'confirm',
  reset: 'reset',
  finish: 'finish',
  none: '',
};

export const verifyTypeKey = {
  email: 'email',
  phone: 'phone',
  none: 'none',
  error: 'error',
};

export const verifyType = {
  [verifyTypeKey.email]: 'email',
  [verifyTypeKey.phone]: 'phone_number',
  [verifyTypeKey.none]: '',
};

export const forgotRoute = {
  verify: '/forgot',
  sendCode: '/forgot/verify',
  confirm: '/forgot/verify',
  reset: '/forgot/reset',
  finish: '/forgot/finished',
};

export const errorTypeEnum = {
  invalidMember: 'invalidMember',
  lockedMember: 'lockedMember',
  emptyMember: 'emptyMember',
  expiredVerify: 'expiredVerify',
  invalidCode: 'invalidCode',
  codeNotExist: 'codeNotExist',
  system: 'system',
  sendFail: 'sendFail',
  timesLocked: 'timesLocked',
  resetFail: 'resetFail',
  invalidPassword: 'invalidPassword',
  none: '',
};

/**
 * 錯誤類型對應的錯誤訊息
 *  todo: 之後整到 i18n ?
 * @property {String} invalidMember 錯誤的使用者
 * @property {String} lockedMember 匯款帳戶號碼
 * @property {String} expiredVerify 銀行代碼
 * @property {String} invalidCode 驗證碼錯誤
 * @property {String} timesLocked 發送次數達上限
 * @property {String} resetFail 重設密碼失敗
 * @property {String} invalidPassword 密碼錯誤
 */
export const errorMessageByKey = {
  [errorTypeEnum.invalidMember]: ['資料不正確或帳號未註冊，請確認後重新輸入或進行註冊'],
  [errorTypeEnum.lockedMember]: ['帳號已鎖定，請聯絡客服為您處理'],
  [errorTypeEnum.emptyMember]: ['您當初的資料未留 E-MAIL以及行動電話資訊', '無法遞送密碼，請洽客服查詢'],
  [errorTypeEnum.expiredVerify]: ['驗證碼已逾時，請按「重新發送」。'],
  [errorTypeEnum.invalidCode]: ['驗證碼錯誤。'],
  [errorTypeEnum.system]: [t(`error.system`)],
  [errorTypeEnum.sendFail]: [t(`member.verifyError.FailedSendVerificationCode`)],
  [errorTypeEnum.timesLocked]: [t(`member.verifyError.limited`)],
  [errorTypeEnum.resetFail]: [t(`member.verifyError.failedModify`)],
  [errorTypeEnum.invalidPassword]: [t(`member.verify.newPassword`)],
  [errorTypeEnum.none]: [],
};

/**
 * 錯誤類型（對應後端的 api response）
 * @property {String} invalidMember 錯誤的使用者
 * @property {String} lockedMember 匯款帳戶號碼
 * @property {String} expiredVerify 銀行代碼
 * @property {String} invalidCode 驗證碼錯誤
 * @property {String} timesLocked 發送次數達上限
 * @property {String} resetFail 重設密碼失敗
 * @property {String} invalidPassword 密碼錯誤
 */
export const errorTypeByKey = {
  [errorTypeEnum.invalidMember]: 'invalid member',
  [errorTypeEnum.lockedMember]: 'locked member',
  [errorTypeEnum.expiredVerify]: 'code expired',
  [errorTypeEnum.invalidCode]: 'verify failure',
  [errorTypeEnum.timesLocked]: 'already sent 5 times',
  [errorTypeEnum.resetFail]: 'reset password failure',
  [errorTypeEnum.invalidPassword]: 'invalid password',
};
