<template>
  <div class="full-wrapper">
    <cart-header></cart-header>
    <router-view :key="$route.path" />
    <Loading v-if="getLoadingStatus && !getWaitStatus"></Loading>
    <Wait v-else-if="getWaitStatus"></Wait>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import CartHeader from '@/components/layout/cart-header.vue';
import Loading from '@/components/utility/loading.vue';
import Wait from '@/components/utility/wait.vue';
import { getDefaultMeta } from '@/helper/meta';

const components = {
  CartHeader,
  Loading,
  Wait,
};

export default {
  name: 'LayoutCart',
  components,
  setup() {
    useMeta(getDefaultMeta());
    const loadingStore = useLoadingStore();
    const { getLoadingStatus, getWaitStatus } = storeToRefs(loadingStore);

    return {
      getLoadingStatus,
      getWaitStatus,
    };
  },
};
</script>

<style lang="scss" scoped>
.full-wrapper {
  min-height: 100vh;
  background-color: var(--l-gray-f4);
  padding-bottom: 1rem;
}
</style>
