import SecureLocalStorage from '@/services/SecureLocalStorage';

const storagePlugin = () => {
  const y = `*Y-03`;
  const c = `te-202`;
  const a = `esli`;
  const e = `3-K*E`;

  /** @const {string} secureLocalStorageKey SecureLocalStorage class 加解密使用的統一加密key */
  const secureLocalStorageKey = `${a}${c}${e}${y}`;

  const secureLocalStorage = new SecureLocalStorage(secureLocalStorageKey);

  /**
   * 將指定的 key-value pair 寫入安全的本地儲存 (localStorage)
   * 如果無法寫入會拋出 error
   * @param {string} key - 設定 key
   * @param {string} value - 儲存值
   * @return {boolean} 能夠寫入並成功為 true, 否則 false
   * @throws {Error} 寫入失敗。瀏覽器不支援或是沒有權限無法寫入
   */
  const setItem = (key, val) => secureLocalStorage.setItem(key, val);

  /**
   * 從 localStorage 取回 key 對應值
   * 如果此 key 沒有找到則回傳 null
   * @param {string} key key
   * @return {string | null} 對應值 or null
   */
  const getItem = (key) => secureLocalStorage.getItem(key);

  /**
   * 移除 localStorage 中指定的 key-value pair
   * @param {string} key key
   */
  const removeItem = (key) => secureLocalStorage.removeItem(key);

  return {
    setItem,
    getItem,
    removeItem,
  };
};

/**
 * @const {object} SecureLocalStorage localStorage 加解密工具
 */
export default storagePlugin();
