<template>
  <SingleButtonModal class="wait-modal" name="wait" :custom-body-class="bodyClass" :button-text="btnName" :theme="buttonClass" :custom-button="customButton" @close="close">
    <template #body>
      <div ref="lottieLoadingRef" class="lottie-loading col-auto warning-icon"></div>
      <div class="font20 mb16 text-center">{{ message1 }}<br />{{ message2 }}</div>
      <div class="font16 text-center">{{ message3 }}</div>
    </template>
  </SingleButtonModal>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import lottie from 'lottie-web';
import SingleButtonModal from '@/components/utility/modal/single-button-modal.vue';
import esliteLoadingJsonData from '@/static/lottie/eslite_loading.json';

export default {
  name: 'WaitModal',
  components: { SingleButtonModal },
  setup() {
    const route = useRoute();
    const bodyClass = ref('row');
    const buttonClass = ref('btn-e-light-gold');
    const customButton = ref({ 'font-family': 'Noto Serif TC', background: '#8a6927' });
    const btnName = ref('返回首頁');

    const message1 = ref('目前訪問人數過多，請耐心等待進入結帳畫面中...');
    const message2 = ref('造成不便，敬請見諒！');
    const message3 = ref('請勿離開畫面，如不願等待，可點擊返回首頁。');

    const lottieLoadingRef = ref(null);
    const anim = ref(null);
    const lottieInit = () => {
      anim.value = lottie.loadAnimation({
        container: lottieLoadingRef.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'esliteLoading',
        animationData: esliteLoadingJsonData,
      });
    };

    const close = () => {
      window.location.href = '/';
    };

    const switchPath = (path) => {
      switch (path) {
        case '/cart/step3':
          message1.value = '目前訪問人數眾多，請耐心等待';
          break;
        default:
          break;
      }
    };

    onMounted(() => {
      lottieInit();
      switchPath(route.path);
    });

    return {
      bodyClass,
      buttonClass,
      customButton,
      btnName,
      message1,
      message2,
      message3,
      close,

      lottieLoadingRef,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixins/zindex.scss';

.warning-icon {
  width: 60px;
  padding: 0;
  margin: 0 auto 2rem;
}

.font20 {
  @include font20;
  line-height: 24px;
  font-weight: bold;
  font-family: 'Noto Sans TC';
}

.font16 {
  @include font16;
  line-height: 24px;
  font-weight: normal;
  font-family: 'Noto Sans TC';
}

.mb16 {
  margin-bottom: 16px;
}

.wait-modal :deep(.container.modal-container) {
  @include mediaMax($grid-breakpoints-sm) {
    width: 80% !important;
  }
}
</style>
