import { virtualShelfRouteNames } from '@/constant/virtual-shelf';

const directionEnum = {
  back: 'back',
  forward: 'forward',
};

let currentFrom = '';
let count = 0;
let popstateListener = null;

function addPopstateListener(listener) {
  popstateListener = listener;
  window.addEventListener('popstate', popstateListener);
}

function clearPopstateListener() {
  if (popstateListener) window.removeEventListener('popstate', popstateListener);
  popstateListener = null;
  count = 0;
  currentFrom = '';
}

// 非vue-router開啟的頁面，返回上頁，需back多次
async function backOrForwardPage({ route, router, from, direction }) {
  // 目前 back/forward 尚未完成，不處理新的 back/forward
  if (currentFrom && from) {
    console.log('ignore back/forward', from);
    return;
  }
  if (!currentFrom && !from) return;

  if (!currentFrom) {
    currentFrom = from;
    // 限制 重複 back/forward 時間
    setTimeout(clearPopstateListener, 3000);
  }

  // 限制 重複 back/forward 次數
  if (count > 5) {
    clearPopstateListener();
    return;
  }
  /**
   * 目前路徑 和 from路徑 不同，則不用再呼叫 back
   * note: 因為品牌L3、搜尋頁 query string 會被改，防止誤判 back 成功，增加判斷是否是虛擬貨架允許頁面
   */
  if (window.location.href !== currentFrom && virtualShelfRouteNames[route.name]) {
    clearPopstateListener();
    return;
  }
  console.log('back/forward count', count, window.location.href);
  // 加 popstate listener，重複 back，直到離開 currentFrom
  if (!popstateListener) {
    // 沿用currentFrom，不需傳入from，
    addPopstateListener(() => backOrForwardPage({ route, router, from: null, direction }));
  }
  if (direction === directionEnum.back) router.back();
  else if (direction === directionEnum.forward) router.forward();
  count += 1;
}

export function backToPreviousPage({ route, router }) {
  backOrForwardPage({ route, router, from: window.location.href, direction: directionEnum.back });
}

export function forwardToNextPage({ route, router }) {
  backOrForwardPage({ route, router, from: window.location.href, direction: directionEnum.forward });
}
