<template>
  <div>
    <h1>Test Only Layout</h1>
    <router-view :key="$route.path"></router-view>
    <loading v-if="getLoadingStatus"></loading>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import Loading from '@/components/utility/loading.vue';

export default {
  name: 'TestOnly',
  components: {
    Loading,
  },
  setup() {
    const loadingStore = useLoadingStore();
    const { getLoadingStatus } = storeToRefs(loadingStore);

    return {
      getLoadingStatus,
    };
  },
};
</script>
