import { defineStore } from 'pinia';
import { fetchAndFormatBrandImage } from '@/services/ad/brand-ad';
import { isEmptyValue } from '@/helper/data-process';
import { useCategoriesStore } from '@/stores/categories';

/**
 * state
 */
const state = () => ({
  currentBrandId: 0,
  currentCategoryId: 0,
  brandImages: {},
});

/**
 * getters
 */
const getCurrentBrandId = (state) => state.currentBrandId;
const getCurrentCategoryId = (state) => state.currentCategoryId;
const getCurrentBrandImage = (state) => state.brandImages[state.currentBrandId] || {};
// getCurrentBrand.id 不能替代 getCurrentBrandId，因為currentBrand 須等待 categories api。
const getCurrentBrand = (state) => {
  const categoriesStore = useCategoriesStore();
  const { currentBrandId } = state;
  const currentBrand = categoriesStore.categoriesQueryGetter(currentBrandId);
  if (!currentBrand) return { name: '' };
  const { level2Description: name = '' } = currentBrand;
  return {
    ...currentBrand,
    name,
  };
};
const getCurrentBrandCategories = (state) => {
  const categoriesStore = useCategoriesStore();
  const { currentBrandId, currentCategoryId } = state;
  const brandCategory = categoriesStore.getBrandCategory;
  if (!brandCategory?.children?.length) return [];
  const brand = Object.values(brandCategory.children).find((brand) => brand.id === currentBrandId);
  if (!brand?.children?.length) return [];
  return brand.children.map((category) => {
    return {
      id: category.id,
      title: category.description,
      link: isEmptyValue(category.link) ? `/brand/${currentBrandId}/${category.id}` : category.link,
      isActive: category.id === currentCategoryId,
    };
  });
};

const getters = {
  getCurrentBrandId,
  getCurrentCategoryId,
  getCurrentBrandImage,
  getCurrentBrand,
  getCurrentBrandCategories,
};

/**
 * actions
 */
const fetchBrandImage = function (brandId) {
  if (this.brandImages && this.brandImages[brandId]) return;
  fetchAndFormatBrandImage(brandId).then(({ brandImage }) => {
    if (!brandImage?.image) return;
    this.brandImages = { ...this.brandImages, [brandId]: brandImage };
  });
};

const initCurrentBrandCategory = function ({ brandId, categoryId }) {
  this.currentBrandId = brandId;
  this.currentCategoryId = categoryId;
};

const actions = {
  initCurrentBrandCategory,
  fetchBrandImage,
};

export const useBrandStore = defineStore('brand', {
  state,
  getters,
  actions,
});
