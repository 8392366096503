export const globalAdEnum = {
  horizontalPaint: 'horizontal_paint',
  littleBanner: 'little_b',
  keywords: 'keywords',
  coverBanner: 'cover_banner',
  coverBannerWatermark: 'cover_banner_watermark',
  categoryBanner: 'L1_big_b_group',
  brands: 'brands',
  mobileHeadAd: 'mobile_head_ad',
};
