import { checkVirtualShelfLayout } from '@/helper/virtual-shelf';

// 預設 選擇性 顯示區塊
export const homeDefaultOptionalBlocks = {
  discountArea: {
    discountAreaTop: true,
  },
  themePlannigAndBrandWeekPosition: {
    themePlanning: true,
  },
  recommendAndHotTopicPosition: {
    hotTopic: true,
  },
  threadPosition: true,
};

// 虛擬貨架 顯示區塊
const virtualShelfBlocks = {
  discountArea: {
    discountAreaTop: false,
  },
  themePlannigAndBrandWeekPosition: {
    themePlanning: false,
  },
  recommendAndHotTopicPosition: {
    hotTopic: false,
  },
  threadPosition: false,
};

export const getHomeControl = () => {
  if (checkVirtualShelfLayout()) return virtualShelfBlocks;
  return homeDefaultOptionalBlocks;
};
