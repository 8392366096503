export const pageRootEnum = {
  category: 'category',
  product: 'product',
  brand: 'brand',
  bestSellers: 'best-sellers',
};

export const pageRegexRules = {
  category: {
    page: pageRootEnum.category,
    testRegex: /^\/(category)\/([1-3]{1})\/([0-9]{1,5})/,
    replaceRegex: 'level:$2,id:$3', // id 為館別 id
  },
  product: {
    page: pageRootEnum.product,
    testRegex: /^\/(product)\/([0-9]{10,16})/,
    replaceRegex: 'level:3,id:$2"', // id 為產品 id
  },
};

export const pageRegexRulesByRouteName = {
  'category-1-id': pageRegexRules.category,
  'category-2-id': pageRegexRules.category,
  'category-3-id': pageRegexRules.category,
  'best-sellers-online-l1-l2': {
    page: pageRootEnum.bestSellers,
    testRegex: /^\/(best-sellers\/online)\/([0-9]{1,5})\/([0-9]{1,5})/,
    replaceRegex: 'level:2,level1Id:$2,level2Id:$3,id:$3', // id 網路暢銷L2分類 id
  },
  'best-sellers-online-l1': {
    page: pageRootEnum.bestSellers,
    testRegex: /^\/(best-sellers\/online)\/([0-9]{1,5})/,
    replaceRegex: 'level:1,level1Id:$2,id:$2', // id 網路暢銷L1分類 id
  },
  'brand-id-category': {
    page: pageRootEnum.brand,
    testRegex: /^\/(brand)\/([0-9]{1,5})\/([0-9]{1,5})/,
    replaceRegex: 'level:3,brandId:$2,categoryId:$3,id:$3', // id 為品牌小分類 id
  },
  'brand-id': {
    page: pageRootEnum.brand,
    testRegex: /^\/(brand)\/([0-9]{1,5})/,
    replaceRegex: 'level:2,brandId:$2,id:$2', // id 為品牌中分類 id
  },
};

export const parsePath = ({ path, testRegex, replaceRegex }) => {
  return path
    .replace(testRegex, replaceRegex)
    .split(',')
    .map((x) => x.split(':'))
    .reduce((x, val) => ({ ...x, [val[0]]: parseInt(val[1], 10) }), {});
};

export const testAndParsePath = function ({ path: inputPath, routeName }) {
  const pageRule = pageRegexRulesByRouteName[routeName];
  if (!pageRule) return false;
  const { testRegex, replaceRegex } = pageRule;
  const path = inputPath.toLowerCase();
  if (!testRegex.test(path)) return false;
  return parsePath({ path, testRegex, replaceRegex });
};
