import { getCookie, removeCookie } from '@/helper/cookie';
import dayjs, { checkDayTimeBetween } from '@/helper/filter/date';

// cookie key
export const cookieKey = 'tracking';
// cookie 保存時間 (1小時)
export const cookieExpires = 60;

// cookie time key
export const cookieTimeKey = 'tracking-time';

// gtm tracking key
export const gtmECKey = `gtm-ec`;
// gtm ga4 tracking key
export const gtmGA4ECKey = `gtm-ga-ec`;
export const gtmGA4ECNewKey = `gtm-ga-ec-new`;
// gtm purchase data localStorage Key
export const gtmCartOrderKey = `gtm-cart-order`;

/**
 * 清除 tracking code
 */
export const clearTracking = () => {
  localStorage.removeItem(cookieKey);
  localStorage.removeItem(cookieTimeKey);
  removeCookie(cookieKey);
};

/**
 * 取得 tracking code 網址
 * @return {string} url
 */
export const getTracking = () => {
  // get tracking
  const cookieTracking = getCookie(cookieKey);
  const trackingTime = localStorage.getItem(cookieTimeKey);
  /** @const {boolean} timeIsValid 確認有時間且格式正確 */
  const timeIsValid = trackingTime !== null && dayjs(trackingTime).isValid() !== false;
  // 非空值, 非不正確格式, 時間要在一天內的（比一天小） cookie紀錄時間1號,期限為2號內,所以現在時間為1+1內
  /** @const {boolean} checkTime 判斷是否時間格式正確，且在時間區間內 */
  const checkTime = timeIsValid && checkDayTimeBetween(dayjs(trackingTime), dayjs(trackingTime).add(1, 'day'));

  // 判斷是否時間格式正確，但不在時間區間內
  if (timeIsValid && !checkDayTimeBetween(dayjs(trackingTime), dayjs(trackingTime).add(1, 'day'))) clearTracking();

  const storageTracking = timeIsValid && checkTime ? localStorage.getItem(cookieKey) : null;

  return cookieTracking || storageTracking || null;
};
