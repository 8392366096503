/**
 * location: en
 * @const {object} vee-validate error message
 */
export default {
  code: 'en',
  messages: {
    _default: 'The {field} is not valid',
    alpha: 'The {field} field may only contain alphabetic characters',
    alpha_num: 'The {field} field may only contain alpha-numeric characters',
    alpha_dash: 'The {field} field may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces: 'The {field} field may only contain alphabetic characters as well as spaces',
    between: 'The {field} field must be between 0:{min} and 1:{max}',
    confirmed: 'The {field} field confirmation does not match',
    digits: 'The {field} field must be numeric and exactly contain 0:{length} digits',
    dimensions: 'The {field} field must be 0:{width} pixels by 1:{height} pixels',
    email: 'The {field} field must be a valid email',
    excluded: 'The {field} field is not a valid value',
    ext: 'The {field} field is not a valid file',
    image: 'The {field} field must be an image',
    integer: 'The {field} field must be an integer',
    length: 'The {field} field must be 0:{length} long',
    max_value: 'The {field} field must be 0:{max} or less',
    max: 'The {field} field may not be greater than 0:{length} characters',
    mimes: 'The {field} field must have a valid file type',
    min_value: 'The {field} field must be 0:{min} or more',
    min: 'The {field} field must be at least 0:{length} characters',
    numeric: 'The {field} field may only contain numeric characters',
    one_of: 'The {field} field is not a valid value',
    regex: 'The {field} field format is invalid',
    required_if: 'The {field} field is required',
    size: 'The {field} field size must be less than 0:{size}KB',
    url: 'The {field} field is not a valid URL',

    /**
     * custom
     */
    required: 'The {field} field is required',
    required_en: 'The {field} field is required, and input english',
    address_en: 'The {field} field is required, and input english',
    address_tw: '{field} field is required, and input chinese',
    minmax: 'The {field} field must be between {min} and {max}',
    invoiceMobileDevice: '{field} wrong format',
    invoiceIDDevice: '{field} wrong format',
    phone: '{field} wrong format',
    phoneTW: '{field} wrong format',
    phoneHK: '{field} wrong format',
    telePhoneArea: '{field} wrong format',
    telePhoneNumber: '{field} wrong format',
    telePhoneExt: '{field} wrong format',
    is_not: '{field} field is required',
    password: '{field} must be 6 to 24 yards half-shaped alphanumeric, and cannot contain special symbols.',
    confirm: '{field} must be the same as the new password.',
  },
};
