import { join } from 'ramda';
import { fetchBrandLevel2Ad, fetchBrandLevel3Ad } from '@/api/ad/fetch-ad-service';
import { getBrandLevel2ADPosition, brandLevel3ADPosition } from '@/constant/ad/ad-constant';
import { brandLevel2ADMappingEnum, brandLevel2ADKeyEnum, brandLevel2ADTypeEnum } from '@/constant/ad/brand-level-2-ad-type';
import { brandLevel3ADMappingEnum, brandLevel3ADKeyEnum } from '@/constant/ad/brand-level-3-ad-type';
import brandLevel2ADFormatter from '@/helper/ad/brand-level-2-formatter';
import brandLevel3ADFormatter from '@/helper/ad/brand-level-3-formatter';
import { processAdCreator, adIndexByADKeyCreator } from '@/helper/ad/ad-helper';

const fetchAndFormatBrandLevel2AD = (brandId, position) => {
  const adTypes = join(',', position);
  return fetchBrandLevel2Ad(brandId, adTypes)
    .then((result) => result.data)
    .then(
      processAdCreator({
        mappingEnum: brandLevel2ADMappingEnum,
        formatterSet: brandLevel2ADFormatter,
        position,
      }),
    )
    .then(adIndexByADKeyCreator(brandLevel2ADKeyEnum));
};

export const fetchAndFormatBrandLevel2PageAD = (brandId) => {
  return fetchAndFormatBrandLevel2AD(brandId, getBrandLevel2ADPosition());
};

export const fetchAndFormatBrandImage = (brandId) => {
  return fetchAndFormatBrandLevel2AD(brandId, [brandLevel2ADTypeEnum.brandImage]);
};

export const fetchAndFormatBrandLevel3PageAD = (categoryId) => {
  const adTypes = join(',', brandLevel3ADPosition);
  return fetchBrandLevel3Ad(categoryId, adTypes)
    .then((result) => result.data)
    .then(
      processAdCreator({
        mappingEnum: brandLevel3ADMappingEnum,
        formatterSet: brandLevel3ADFormatter,
        position: brandLevel3ADPosition,
      }),
    )
    .then(adIndexByADKeyCreator(brandLevel3ADKeyEnum));
};
