import { includes } from 'ramda';
import { getStoreType } from '@/helper/check-store';
import { deliveryTypeEnum } from '@/constant/delivery-type';
import { stores, storeKey } from '@/constant/stores-type';
import { useCartStore } from '@/stores/cart';

const BASE_PATH = '/cart/step2';

const TYPE_STORAGE = 'storage';
const TYPE_ROUTE = 'route';

const isSafari = navigator.userAgent.search(`Safari`) > -1;

/**
 * [Seven]存入 store 購物車收件人門市
 * @param store
 * @param params
 */
function saveSeven(store, params) {
  store.setDelivery(deliveryTypeEnum.sevenEleven);
  store.setStore(params);
}

/**
 * [CVS]存入 store 購物車收件人門市
 * @param store
 * @param params
 */
function saveConvenienceStore(store, params) {
  store.setDelivery(deliveryTypeEnum.convenienceStore);
  store.setStore(params);
}

/**
 * [family]存入 store 購物車收件人門市
 * @param store
 * @param params
 */
function saveFamilyMartStore(store, params) {
  store.setDelivery(deliveryTypeEnum.familyMart);
  store.setStore(params);
}

/**
 * [香港ok]存入 store 購物車收件人門市
 * @param store
 * @param params
 */
function savehongKongStore(store, params) {
  store.setDelivery(deliveryTypeEnum.hongKongStore);
  store.setStore(params);
}

/**
 * redirect to step 2
 * @param redirect
 */
function redirectToStep2() {
  if (window.location.search === '') return;
  // redirect(302, BASE_PATH);
  // window.history.replaceState({}, '', BASE_PATH);
  window.location.href = BASE_PATH;
}

// 判斷門市資料來源
function checkStoreSource(route) {
  const getStorage = JSON.parse(sessionStorage.getItem(storeKey));
  const hasStorage = getStoreType(getStorage) !== '';
  const hasRoute = getStoreType(route.query) !== '';
  if (hasRoute) return TYPE_ROUTE;
  if (hasStorage) return TYPE_STORAGE;
  return '';
}

function setStoreData(store, type = '', queryData = {}) {
  return new Promise((resolve) => {
    switch (type) {
      // 7-11 超商
      case 'shopping7':
        saveSeven(store, {
          type: stores.find((x) => x.code === '' && x.delivery === deliveryTypeEnum.sevenEleven).type,
          id: queryData.storeid || '',
          name: decodeURI(queryData.storename || ''),
          address: decodeURI(queryData.address || ''),
          delivery: deliveryTypeEnum.sevenEleven,
        });
        return resolve(true);

      // 網路便利通 (OK, 萊爾富, 全家 超商門市)
      case 'cvs':
        saveConvenienceStore(store, {
          type: stores.find((x) => x.code === queryData.cvsspot.substring(0, 1)).type || '', // todo : OK, 萊爾富, 全家
          id: queryData.cvsspot || '',
          name: decodeURI(queryData.name || ''),
          address: decodeURI(queryData.addr || ''),
          delivery: deliveryTypeEnum.convenienceStore,
        });
        return resolve(true);

      // 日翊文化 (全家)
      case 'family':
        saveFamilyMartStore(store, {
          type: stores.find((x) => x.delivery === deliveryTypeEnum.familyMart).type || '', // 全家
          id: queryData.cvsspot || '',
          name: decodeURI(queryData.name || ''),
          address: decodeURI(queryData.addr || ''),
          delivery: deliveryTypeEnum.familyMart,
        });
        return resolve(true);

      // 提貨通 (香港OK便利店)
      // stno, st_name, st_addr, st_tel, country, csno, cs_name, keep_data
      case 'ewebs':
        savehongKongStore(store, {
          type: stores.find((x) => x.code === '' && x.delivery === deliveryTypeEnum.hongKongStore).type, // todo : 香港OK
          id: queryData.stno || '',
          name: decodeURI(queryData.st_name || ''),
          address: decodeURI(queryData.st_addr || ''),
          delivery: deliveryTypeEnum.hongKongStore,
        });
        return resolve(true);
      default:
        return resolve(false);
    }
  });
}

export default function ({ route }) {
  // 只檢查 /cart/step2
  if (!includes(route.path, [BASE_PATH])) {
    // 同步移除不必要的個資資訊
    Object.keys(localStorage)
      .filter((x) => /^cartUser([0-9]*)$/.test(x))
      .forEach((x) => localStorage.removeItem(x));
    return;
  }
  const check = checkStoreSource(route);

  // 不是門取進入
  if (check === '') return;

  const queryData = check === TYPE_ROUTE ? route.query : JSON.parse(sessionStorage.getItem(storeKey));
  const type = getStoreType(queryData);
  sessionStorage.setItem(storeKey, JSON.stringify(queryData));
  const cartStore = useCartStore();
  setStoreData(cartStore, type, queryData)
    .then((res) => {
      if (res === true && !isSafari) redirectToStep2();
    })
    .catch((e) => console.log(e));
}
