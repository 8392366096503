import { defineStore } from 'pinia';
import { virtualShelfAlertRedirectStatus } from '@/constant/virtual-shelf';

const state = () => ({
  isShowVirtualShelfPageNotSupport: false,
  redirectStatus: virtualShelfAlertRedirectStatus.default,
});

const getters = {
  getIsShowVirtualShelfPageNotSupport(state) {
    return state.isShowVirtualShelfPageNotSupport;
  },
  getRedirectStatus(state) {
    return state.redirectStatus;
  },
};

const actions = {
  setIsShowVirtualShelfPageNotSupport(data) {
    this.isShowVirtualShelfPageNotSupport = data;
  },
  setRedirectStatus(data) {
    this.redirectStatus = data;
  },
};

export const useVirtualShelfAlertStore = defineStore('virtualShelfAlert', {
  state,
  getters,
  actions,
});
