<template>
  <div v-if="notInlineApp" :class="['header', activeMenu]">
    <div class="header-bg-color">
      <div class="tw-container lg:tw-mt-1 lg:tw-p-0 tw-m-auto tw-my-0">
        <div class="tw-row eslite-header tw-flex lg:!tw-pr-0">
          <!--  //- Logo-->
          <div class="logo-wrapper tw-col-6 lg:tw-col-2">
            <router-link to="/" title="誠品線上" class="navbar-brand">
              <div class="logo"></div>
            </router-link>
          </div>
          <!--  //- share-->
          <div class="share tw-flex tw-items-center tw-ml-auto tw-col-auto tw-mr-2" @click="shareUrl">
            <img :src="shareBlack" alt="share" class="lg:tw-mr-1" />
            <span class="tw-hidden lg:tw-inline">分享活動</span>
          </div>
          <!--  //- 右邊 icon vs 會員專區 & 車車-->
          <loginHeader class="header-login tw-col-2 lg:tw-mx-0 tw-px-0"></loginHeader>
          <!--  //- mobile 漢堡-->
          <span class="hamburger tw-col-1 tw-flex lg:tw-hidden !tw-pr-2 sm:!tw-pr-0 tw-items-center icon icon-more-regular" @click="toggleMenu"></span>
        </div>
      </div>
    </div>
    <!--  //- 側邊欄-->
    <div class="menu-bar lg:tw-hidden">
      <div class="close-row">
        <div class="icon-fa-times" @click="toggleMenu"></div>
      </div>
      <div class="tabs-wrapper">
        <scroll-active v-if="sidebar.length" class="tabs" :is-ready="!getLoadingStatus">
          <p v-for="(tab, index) in sidebar" :key="`exhibit-mobile-${index}`" class="tw-row tw-justify-center" :data-hash="`#sidebar-${tab.key}`">
            <span class="tw-flex-none tw-py-4" @click="selectOption(`#sidebar-${tab.key}`)">{{ tab.title }}</span>
          </p>
        </scroll-active>
      </div>
    </div>
  </div>
  <!-- //- app-share-->
  <div v-else class="app-share" @click="shareUrl"></div>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import { computed, ref, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import { useBookExhibitStore } from '@/stores/book-exhibit';
import { useScrollTo } from '@/composables/utility/scroll-to';
import LoginHeader from '@/components/layout/login-header.vue';
import ScrollActive from '@/components/utility/scroll-active.vue';
import checkIsInlineApp from '@/helper/check-inline-app';
import { shareBlack } from '@/constant/images-path';

const setup = () => {
  const $screen = inject('$screen');
  const route = useRoute();
  const router = useRouter();
  const { scrollToRouteHash } = useScrollTo();
  const loadingStore = useLoadingStore();
  const bookExhibitStore = useBookExhibitStore();
  const { getLoadingStatus } = storeToRefs(loadingStore);
  const { getSidebar: sidebar } = storeToRefs(bookExhibitStore);

  // data
  const showMobileMenu = ref(false);
  const notInlineApp = ref(false);

  // computed
  const activeMenu = computed(() => ({ active: showMobileMenu.value }));

  // methods
  const shareUrl = async () => {
    const shareTitle = document.title;
    const shareText = document.title;
    let shareLink = window.location.href;
    // app utm
    if (checkIsInlineApp()) shareLink += `?utm_source=app&utm_medium=share&utm_campaign=exhibitions`;
    // mb utm
    else if ($screen.isMobileSize) shareLink += `?utm_source=web&utm_medium=share&utm_campaign=exhibitions`;
    // android native share
    if (window.EsliteAppInterface && window.EsliteAppInterface.nativeShare) {
      window.EsliteAppInterface.nativeShare(shareTitle, shareText, shareLink);
      return;
    }
    if (navigator.share) {
      await navigator
        .share({ url: shareLink })
        .then(() => {})
        .catch((error) => console.log(error));
    } else {
      navigator.clipboard
        .writeText(shareLink)
        .then(() => alert('已複製連結'))
        .catch((error) => console.log(error));
    }
  };

  const toggleMenu = () => {
    showMobileMenu.value = !showMobileMenu.value;
  };

  const selectOption = (targetHash) => {
    if (route.hash === targetHash) {
      scrollToRouteHash();
    }
    router.push({ hash: targetHash });
  };

  watch(
    () => route.fullPath,
    () => {
      showMobileMenu.value = false;
    },
  );

  const created = function () {
    // 不是 app 內置瀏覽器？
    notInlineApp.value = !checkIsInlineApp();
  };
  created();

  return {
    // data
    notInlineApp,
    shareBlack,
    // computed
    getLoadingStatus,
    sidebar,
    activeMenu,
    // methods
    shareUrl,
    toggleMenu,
    selectOption,
  };
};

const components = {
  LoginHeader,
  ScrollActive,
};

const props = {
  exhibitionPage: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'Exhibition',
  components,
  props,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/layout/header.scss';

.eslite-header {
  justify-content: space-between;
}
.header {
  position: relative;
}
.header-bg-color {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
.share {
  cursor: pointer;
  @include font14;
  line-height: formatSize(20);
  &:hover {
    color: var(--sepia);
  }
  img {
    width: formatSize(24);
    height: formatSize(24);
  }
}
.menu-bar {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 60%;
  right: 0;
  top: 0;
}
.icon-fa-times {
  position: absolute;
  @include font30;
  color: var(--white);
  right: 13%;
  top: 7px;
}

.tabs-wrapper {
  height: 100%;
}

.tabs {
  height: 100%;
  display: flex;
  overflow: scroll;
  padding-top: 3rem;
  // chrome
  &::-webkit-scrollbar {
    display: none;
  }
  // IE edge
  -ms-overflow-style: none;
  // Firefox
  scrollbar-width: none;
  flex-direction: column;
  a,
  p {
    cursor: pointer;
    padding: 0 1.6rem;
    margin: 0;
    text-align: center;
    @include font17;
    color: rgba(255, 255, 255, 0.5);
    &:hover,
    &.active {
      text-decoration: none;
      color: var(--white);
    }
    &:last-child {
      padding-bottom: 3rem;
    }
  }
  span {
    width: 100%;
    border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
  }
}

@include mediaMin($grid-breakpoints-lg) {
  .header {
    height: 58px; // for fixed header space
  }
  .header-bg-color {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
}

@include mediaMin($grid-breakpoints-xl) {
  .header {
    height: 70px; // for fixed header space
  }
}

@include mediaMax($grid-breakpoints-lg) {
  .app-share {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    opacity: 0.4;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: fixed;
    bottom: calc(2vw + 50px);
    right: 0px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    z-index: 1000;
    margin: 2vw;
    &::after {
      content: '　';
      font-weight: normal;
      font-style: normal;
      font-size: 2em;
      color: #fff;
      opacity: 0.5;
      background: url(@/static/images/share-white.png) center no-repeat;
      background-size: 20px;
      background-position: 50% 50%;
    }
    &:hover {
      cursor: pointer;
      background-color: #666;
    }
  }
  .share {
    order: 2;
  }
  .header {
    height: 57px; // for mobile fixed header space
  }
  .logo {
    width: 127px;
    left: 0.5rem;
    right: auto;
  }
  .logo-wrapper {
    order: 1;
    margin-right: auto;
  }
  .header-login {
    order: 2;
  }
  .hamburger {
    order: 3;
    flex: 0 0 2rem;
    max-width: 2rem;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    cursor: pointer;
  }
  .header-login {
    justify-content: space-around;
    flex: 0 0 5rem;
    max-width: 5rem;
  }
  .eslite-header {
    justify-content: flex-start;
  }

  .header-bg-color {
    background-color: var(--white);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .header.active .menu-bar {
    flex-direction: column;
  }

  .logo {
    background-image: url('@/static/images/logo_r.webp');
    background-position: center;
  }

  .eslite-header {
    span {
      align-items: center;
    }
    .icon {
      font-size: 1.6rem;
      color: var(--black);
      padding-top: 3px;
    }
  }
}
</style>
