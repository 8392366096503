<template>
  <div class="d-flex flex-column" style="min-height: 100vh">
    <eslite-header></eslite-header>
    <!-- 加側邊欄（category) -->
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer v-if="isShowFooter"></eslite-footer>
    <back-to-top v-if="!isShowFooter"></back-to-top>
    <loading v-if="getLoadingStatus"></loading>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import EsliteHeader from '@/components/layout/header.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import BackToTop from '@/components/layout/elements/back-to-top.vue';
import Loading from '@/components/utility/loading.vue';

const setup = () => {
  const route = useRoute();
  const loadingStore = useLoadingStore();
  const { getLoadingStatus } = storeToRefs(loadingStore);

  const isShowFooter = computed(() => route.path.match(/^\/product/));

  return {
    getLoadingStatus,
    isShowFooter,
  };
};

export default {
  name: 'Eslite',
  components: {
    EsliteHeader,
    EsliteFooter,
    BackToTop,
    Loading,
  },
  setup,
};
</script>

<style scoped lang="scss"></style>
