<template>
  <div id="virtual-shelf-layout">
    <virtual-shelf-header />
    <router-view v-if="isSupportedPage" :key="$route.path" />
    <loading v-if="getLoadingStatus"></loading>
    <virtual-shelf-page-control />
    <virtual-shelf-alert />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import { virtualShelfRouteNames, virtualShelfNotSupportHashAtHome } from '@/constant/virtual-shelf';
import Loading from '@/components/utility/loading.vue';
import VirtualShelfHeader from '@/components/layout/virtual-shelf/virtual-shelf-header.vue';
import VirtualShelfAlert from '@/components/layout/virtual-shelf/virtual-shelf-alert.vue';
import VirtualShelfPageControl from '@/components/layout/virtual-shelf/virtual-shelf-page-control.vue';

export default {
  name: 'VirtualShelf',
  components: {
    Loading,
    VirtualShelfHeader,
    VirtualShelfAlert,
    VirtualShelfPageControl,
  },
  setup() {
    const route = useRoute();
    const loadingStore = useLoadingStore();
    const { getLoadingStatus } = storeToRefs(loadingStore);

    const isSupportedPage = computed(() => {
      if (route.hash === virtualShelfNotSupportHashAtHome) return false;
      return virtualShelfRouteNames[route.name];
    });

    return {
      getLoadingStatus,
      isSupportedPage,
    };
  },
};
</script>

<style lang="scss">
#virtual-shelf-layout .eslite-home {
  .container,
  .container-md,
  .container-fluid {
    max-width: 100%;
  }
}
</style>
