<template>
  <div class="d-flex flex-column tw-overflow-x-hidden" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus"></customer-service>
    <eslite-header :class="{ 'bg-white': isMemberProfileUrl }"></eslite-header>
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer></eslite-footer>
    <loading v-if="getLoadingStatus"></loading>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useGetCustomerServiceStore } from '@/composables/store/customer-service';
import EsliteHeader from '@/components/layout/header.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import Loading from '@/components/utility/loading.vue';
import { getDefaultMeta } from '@/helper/meta';
import { filterUrl } from '@/constant/customer-service';
import { memberProfileStyle } from '@/constant/member-profile';

const CustomerService = defineAsyncComponent(() => import('@/components/customer-service/customer-service.vue'));

const setup = () => {
  const route = useRoute();
  const loadingStore = useLoadingStore();
  const { getLoadingStatus } = storeToRefs(loadingStore);
  const customerServiceStore = useCustomerServiceStore();
  const { getCustomerStatus } = useGetCustomerServiceStore();

  const turnOnCustomerStatus = () => customerServiceStore.setCustomerStatus(true);
  const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
  const isCustomerServiceUrl = computed(() => filterUrl.includes(route.fullPath));
  const isShowCustomerService = computed(() => {
    return getCustomerStatus.value || isCustomerServiceUrl.value;
  });

  watch(
    isCustomerServiceUrl,
    (newValue) => {
      if (newValue) {
        turnOnCustomerStatus();
      }
    },
    { immediate: true },
  );

  return {
    getLoadingStatus,
    getCustomerStatus,
    isMemberProfileUrl,
    isCustomerServiceUrl,
    isShowCustomerService,
  };
};

export default {
  name: 'Home',
  components: {
    EsliteHeader,
    EsliteFooter,
    CustomerService,
    Loading,
  },
  metaInfo() {
    return getDefaultMeta();
  },
  setup,
};
</script>

<style scoped lang="scss">
.bgc {
  background-color: var(--l-gray-f4);
  min-height: 100vh;
}
</style>
