/**
 * location: ja
 * @const {object} vee-validate error message
 */
export default {
  code: 'ja',
  messages: {
    alpha: '{field} はアルファベットのみ使用できます。',
    alpha_num: '{field} は英数字のみ使用できます。',
    alpha_dash: '{field} は英数字とハイフン、アンダースコアのみ使用できます。',
    alpha_spaces: '{field} はアルファベットと空白のみ使用できます。',
    between: '{field} は 0:{min} から 1:{max} の間でなければなりません。',
    confirmed: '{field} が一致しません。',
    digits: '{field} は{length}桁の数字でなければなりません。',
    dimensions: '{field} は幅 0:{width}px、高さ 1:{height}px 以内でなければなりません。',
    email: '{field} は有効なメールアドレスではありません。',
    excluded: '{field} は不正な値です。',
    ext: '{field} は有効なファイル形式ではありません。',
    image: '{field} は有効な画像形式ではありません。',
    integer: '{field} は整数のみ使用できます。',
    is: '{field} が一致しません。',
    length: '{field} は 0:{length} 文字でなければなりません。',
    max_value: '{field} は 0:{max} 以下でなければなりません。',
    max: '{field} は 0:{length} 文字以内にしてください。',
    mimes: '{field} は有効なファイル形式ではありません。',
    min_value: '{field} は 0:{min} 以上でなければなりません。',
    min: '{field} は 0:{length} 文字以上でなければなりません。',
    numeric: '{field} は数字のみ使用できます。',
    one_of: '{field} は有効な値ではありません。',
    regex: '{field} のフォーマットが正しくありません。',
    required_if: '{field} は必須項目です。',
    size: '{field} は 0:{size}KB 以内でなければなりません。',

    /**
     * custom
     */
    required: '{field} は必須項目です。',
    required_en: '{field} 請輸入英文。',
    address_en: '{field} 請輸入英文。',
    address_tw: '{field} 輸入不完整。',
    minmax: '{field} は 0:{min}から 1:{max}の間でなければなりません。',
    invoiceMobileDevice: '{field} 格式有誤。',
    invoiceIDDevice: '{field} 格式有誤。',
    phone: '{field} 格式有誤。',
    phoneTW: '{field} 格式有誤。',
    phoneHK: '{field} 格式有誤。',
    telePhoneArea: '{field} 格式有誤。',
    telePhoneNumber: '{field} 格式有誤。',
    telePhoneExt: '{field} 格式有誤。',
    is_not: '{field} 欄位必填。',
    // todo: 翻譯 check (translate by google)
    password: '{field} 6〜24ヤード半の英数字である必要があり、特別な記号を含めることはできません。',
    confirm: '{field} パスワードと同じである必要があります。',
  },
};
