import { defineStore } from 'pinia';

const state = () => ({
  step: '',
  previous: '',
  phone: '',
  countryCode: '',
});

const actions = {
  setStep(step) {
    this.step = step;
  },
  setPreviousUri(uri) {
    this.previous = uri;
  },
  setPhone(phone) {
    this.phone = phone;
  },
  setCountryCode(countryCode) {
    this.countryCode = countryCode;
  },
};

export const useMemberVerifyStore = defineStore('memberVerify', {
  state,
  actions,
});
