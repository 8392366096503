<template>
  <div v-if="isShowAlert" class="virtual-shelf-page-not-support text-white serif-font-bold">
    <div class="content mx-auto">
      <img v-lazy="page404Img" class="w-100 mx-auto my-5" alt="" src="" />
      <div class="text mb-3">此頁面不支援瀏覽</div>
      <div class="sub-text">5秒鐘後</div>
      <div class="sub-text">將自動跳轉回原頁面</div>
      <div class="mt-4"></div>
      <button class="btn w-100 text-white serif-font-bold" @click="manualCloseAlert">好</button>
      <div class="mt-5"></div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLockBodyScrollStore } from '@/stores/lock-body-scroll';
import { useVirtualShelfAlertStore } from '@/stores/virtual-shelf-alert';
import { virtualShelfAlertRedirectStatus } from '@/constant/virtual-shelf';
import { backToPreviousPage } from '@/helper/url/page-control';
import { page404Img } from '@/constant/images-path';

const setup = () => {
  const route = useRoute();
  const router = useRouter();
  const lockBodyScrollStore = useLockBodyScrollStore();
  const { lockBodyScroll, unlockBodyScroll } = lockBodyScrollStore;
  const virtualShelfAlertStore = useVirtualShelfAlertStore();
  const { setIsShowVirtualShelfPageNotSupport, setRedirectStatus } = virtualShelfAlertStore;
  const { getIsShowVirtualShelfPageNotSupport: isShowAlert, getRedirectStatus: redirectStatus } = storeToRefs(virtualShelfAlertStore);

  const timeoutId = ref(null);

  const clearAlertTimeout = () => {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
    }
  };
  const closeAlert = () => {
    unlockBodyScroll();
    setIsShowVirtualShelfPageNotSupport(false);
  };
  const closeAlertAndRedirect = () => {
    if (!isShowAlert.value) return;
    closeAlert();

    switch (redirectStatus.value) {
      // 站內 不支援虛擬貨架 之頁面，顯示alert後，需返回前頁
      case virtualShelfAlertRedirectStatus.default:
        backToPreviousPage({ route, router });
        break;
      // 不支援虛擬貨架 之 hash tag，顯示alert後，需返回未加hash之原頁
      case virtualShelfAlertRedirectStatus.isFromNotSupportHash:
        router.back();
        break;
      // @click 檢查為 不支援虛擬貨架 之外部網站，不需導過去，原地顯示alert後，不需返回前頁
      case virtualShelfAlertRedirectStatus.isEarlyRedirectBack:
        break;
      default:
        break;
    }
    // redirectStatus 設回預設
    setRedirectStatus(virtualShelfAlertRedirectStatus.default);
  };
  const manualCloseAlert = () => {
    closeAlertAndRedirect();
    clearAlertTimeout();
  };
  const showAlert = () => {
    clearAlertTimeout();
    lockBodyScroll();
    timeoutId.value = setTimeout(() => {
      closeAlertAndRedirect();
      timeoutId.value = null;
    }, 5000);
  };

  watch(
    isShowAlert,
    (newValue) => {
      if (newValue) showAlert();
    },
    { immediate: true },
  );

  onBeforeUnmount(() => {
    closeAlert();
  });

  return {
    page404Img,
    isShowAlert,
    manualCloseAlert,
  };
};

export default {
  name: 'VirtualShelfAlert',
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/zindex.scss';

.virtual-shelf-page-not-support {
  position: fixed;
  @include zindex(modal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  @include pxToRem(padding, 18);
  overflow-y: auto;
}
.content {
  height: fit-content;
  width: 70%;
}
.text {
  @include font40;
}
.sub-text {
  @include font20;
}
.btn {
  @include font20;
  @include pxToRem(padding, 12);
  background-image: linear-gradient(to top, var(--e-light-red), var(--e-red));
}
</style>
