import axios from 'axios';
import { includes } from 'ramda';

/** @const {function} getPath 測試用來控制 member api 回傳值使用 */
const getPath = () => {
  const isDev = import.meta.env.VITE_TYPE !== 'prd';
  if (!isDev) return '';

  let usePath = `default`;
  if (includes(window.location.pathname, ['/cart/step1'])) usePath = `step1`;
  if (includes(window.location.pathname, ['/cart/step2'])) usePath = `step2`;
  if (includes(window.location.pathname, ['/cart/step3'])) usePath = `step3`;

  return `&path=${usePath}`;
};

const MEMBER_DEFAULT_API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_MEMBER_V2}`;
const MEMBER_DETAIL_API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_MEMBER}`;

/**
 * 取得會員 id 等精簡資訊
 * @param {string} token token
 * @returns {axios}
 */
export const getMember = (token, config = {}) =>
  axios({
    method: 'get',
    headers: { Authorization: token, 'Cache-Control': 'no-store' },
    url: `${MEMBER_DEFAULT_API_PATH}?t=${Date.now()}${getPath()}`,
    config,
  });

/**
 * 取得會員資訊
 * @param {string} token token
 * @returns {axios}
 */
export const getMemberDetail = (token, config = {}) =>
  axios({
    method: 'get',
    headers: { Authorization: token, 'Cache-Control': 'no-store' },
    url: `${MEMBER_DETAIL_API_PATH}?t=${Date.now()}`,
    config,
  });
