/* eslint-disable class-methods-use-this */
import { def, defKey } from '@/constant/auth';
import { getCookie, setCookie } from '@/helper/cookie';

export default class AuthStorageService {
  constructor() {
    this.isLogin = 'isLogin';
  }

  getStorage(key = '') {
    return getCookie(key) || localStorage.getItem(key);
  }

  setStorage(key = '', value = '', hour = 720) {
    setCookie(key, value, hour);
    localStorage.setItem(key, value);
  }

  setToken(token) {
    const tokenStr = token ? token.replace(/(Bearer )/g, '') : defKey.false;
    const useToken = tokenStr === defKey.false ? tokenStr : `${defKey.bearer}${tokenStr}`;
    this.setStorage(defKey.strategy, def.strategy); // 30天
    this.setStorage(`${defKey.token}`, useToken);
    this.setStorage(`${defKey.refreshToken}`, defKey.false);
  }

  setIsLogin(login = false) {
    this.setStorage(this.isLogin, login);
  }

  setLogout() {
    this.setStorage(`${defKey.token}`, defKey.false);
    this.setStorage(this.isLogin, false);
  }

  getToken(type = defKey.token) {
    return this.getStorage(type) || '';
  }

  getIsLogin() {
    return JSON.parse(this.getStorage(this.isLogin));
  }
}
