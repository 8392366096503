<template>
  <div class="mx-0 bgtransition" :style="background">
    <div class="container px-0">
      <div class="d-block d-lg-flex wrap-reverse mx-0">
        <!-- 輪播 big_b_new-->
        <div class="rightbox bgtransition" :style="background">
          <div v-if="!hasBigBanner" class="e-skeleton-image swiper-container"></div>
          <big-banner-swiper
            v-else
            :key="$screen.isMobileSize"
            class="swiper-container"
            :items="bigBanner.items"
            :target="target"
            :source-i-d="getGtmId().bigBanner"
            name="index-big-banner"
            :is-full-size="$screen.isMobileSize"
            @change="handleSlideChange"
          ></big-banner-swiper>
        </div>
        <!-- 發燒必買(限時搶購、注目焦點) focus_points-->
        <div class="leftbox pb-3 pb-lg-0 px-0">
          <img v-if="fetchedFocusPoint.image" class="hot-buy" :src="fetchedFocusPoint.image" alt="focus_points" data-test-id="focus-point-img" />
          <div class="title section-title serif-font-bold px-0">發燒必買<span class="ps-1 icon-hotdeal"></span></div>
          <div class="items">
            <div class="inlinebox">
              <!-- pc-->
              <little-banner-swiper v-if="!$screen.isMobileSize" :key="focusPoint.products.length" @change="disableOnlyActive">
                <template #swiper-slide="{ realIndex }">
                  <swiper-slide v-for="(items, indexs) in focusPoint.products" :key="`index-little-banner-${indexs}`">
                    <template v-if="checkSlideVisible({ realIndex, index: indexs })">
                      <hot-sell-horizontal-product
                        v-for="(item, index) in items"
                        :key="`xindex-little-banner-${index}`"
                        :product="item"
                        :blank="focusPoint.blank"
                        :product-gtm-id="getGtmId(index + 1 + indexs * 2).focus"
                      ></hot-sell-horizontal-product>
                    </template>
                    <div v-else></div>
                  </swiper-slide>
                </template>
              </little-banner-swiper>
              <!-- mb-->
              <little-banner-swiper v-else :key="`${focusPoint.products.length}-mb`">
                <template #swiper-slide>
                  <swiper-slide v-for="(items, indexs) in focusPoint.products" :key="`index-little-banner-${indexs}`">
                    <hot-sell-horizontal-product :product="items" :blank="focusPoint.blank" :product-gtm-id="`home-focus-${indexs + 1}`"></hot-sell-horizontal-product>
                  </swiper-slide>
                </template>
              </little-banner-swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useMeta } from 'vue-meta';
import { useGlobalAdStore } from '@/stores/global-ad';
import { useGetHomeAdStore } from '@/composables/store/home-ad';
import { isEmptyValue, linkTarget } from '@/helper/data-process';
import BigBannerSwiper from '@/components/utility/swiper/big-banner-swiper.vue';
import LittleBannerSwiper from '@/components/utility/swiper/little-banner-swiper-desktop.vue';
import HotSellHorizontalProduct from '@/components/product/item/hot-sell-horizontal-product.vue';
import { preloadImage } from '@/helper/meta/preload-image';
import WebpPathConvertService from '@/services/webp-path-convert-service';

const baseColorCode = 'var(--e-skeleton-bg)';
const getGtmId = (index = 1) => {
  return {
    focus: `home-focus-${index}`,
    bigBanner: 'home-bbanner-',
  };
};

const setup = () => {
  const $screen = inject('$screen');
  const globalAdStore = useGlobalAdStore();
  const { bigBanner, focusPoint: fetchedFocusPoint } = useGetHomeAdStore();

  // data
  const swiperRealIndex = ref(0);
  const isOnlyActive = ref(true);

  // computed
  const hasFocusPoint = computed(() => fetchedFocusPoint.value.products?.length > 2);
  const focusPoint = computed(() => {
    if (hasFocusPoint.value) {
      if ($screen.isMobileSize) return fetchedFocusPoint.value;
      const copytData = [...fetchedFocusPoint.value.products];
      const products = [];
      while (copytData.length > 0) {
        products.push(copytData.splice(0, 2));
      }
      return { products, blank: fetchedFocusPoint.value.blank };
    }
    if ($screen.isMobileSize) return { products: [{}] };
    return {
      products: [[{}, {}]],
    };
  });
  const hasBigBanner = computed(() => bigBanner.value?.items?.length > 0);
  const target = computed(() => linkTarget(bigBanner.value?.blank));
  const background = computed(() => {
    if (hasBigBanner.value) {
      return {
        backgroundColor: bigBanner.value?.items[swiperRealIndex.value]?.color || baseColorCode,
      };
    }
    return { backgroundColor: baseColorCode };
  });
  const metaInfo = computed(() => {
    if ((!hasBigBanner.value && !hasFocusPoint.value) || (!isEmptyValue(globalAdStore.getCoverBanner.value?.image) && isEmptyValue(localStorage.getItem('coverBannerTriggeredAt')))) return {};
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      ? preloadImage(fetchedFocusPoint.value.image)
      : preloadImage(WebpPathConvertService.convertPath(hasBigBanner.value && bigBanner.value.items[0].image));
    return {};
  });
  useMeta(metaInfo);

  // methods
  const handleSlideChange = (index) => (swiperRealIndex.value = index);
  const checkSlideVisible = ({ realIndex, index }) => {
    if (!isOnlyActive.value) return true;
    return realIndex === index;
  };
  const disableOnlyActive = () => {
    isOnlyActive.value = false;
  };

  return {
    fetchedFocusPoint,
    bigBanner,
    focusPoint,
    hasBigBanner,
    target,
    background,
    // methods
    handleSlideChange,
    getGtmId,
    checkSlideVisible,
    disableOnlyActive,
  };
};

const components = {
  BigBannerSwiper,
  LittleBannerSwiper,
  HotSellHorizontalProduct,
};

export default {
  name: 'TopSlidePosition',
  components,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/variable.scss';

.leftbox {
  background: var(--white);
  position: relative;
  .hot-buy {
    vertical-align: top;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    object-fit: cover;
    object-position: top;
  }
}

.title {
  width: 100%;
  padding: 10px 0;
  line-height: 1.25rem;
  font-size: 1.25rem;
  text-align: center;
  color: var(--e-light-red);
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 0px var(--white);
}

.bgtransition {
  transition: background-color 0.2s linear;
}

@include mediaIs($grid-breakpoints-md) {
  .title {
    padding: 1rem 0;
  }
}

// 992-1279px (desktop 窄版)
@include mediaIs($grid-breakpoints-lg) {
  .title {
    font-size: 1.125rem;
    height: auto;
    line-height: initial;
    padding: 2% 0 0;
  }
}
@include mediaMin($grid-breakpoints-lg) {
  .wrap-reverse {
    flex-direction: row-reverse;
    justify-content: center;
  }
  .leftbox {
    width: 24.528301886792505%; //calc(100% - calc(960 / 1272 * 100%));
    min-width: 0;
    max-height: 450px;
    border-bottom: 1px solid var(--l-gray-e);
    box-shadow: inset 0 -14px 4px -14px var(--m-gray-c);
  }

  .title {
    text-align: center;
  }

  .items,
  .inlinebox {
    height: 89%;
  }
  .items .item {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .inlinebox {
    width: 100%;
    height: 100%;
    display: block;
    border-left: none;
    border-right: none;
  }

  .rightbox {
    width: 75.4716981132075%; //calc(960 / 1272 * 100%)
    max-width: 960px;
    max-height: 450px;
  }
}

@include mediaIs($grid-breakpoints-lg) {
  .leftbox,
  .rightbox {
    max-height: 350px;
  }
}

.swiperDefault {
  width: 100%;
  height: 100%;
}

@include mediaMin($grid-breakpoints-xl) {
  .leftbox > .title {
    padding: 12px 0 0 0;
    line-height: initial;
    font-size: 1.25rem;
  }
  .leftbox {
    height: 450px;
    max-height: 450px;
  }
  .swiper-container {
    max-width: 960px;
  }
}

// mobile

@include mediaMax($grid-breakpoints-lg) {
  $slides-per-view: 1.25;

  .wrap-reverse {
    display: block;
  }

  .leftbox {
    width: 100%;
  }
  .items {
    position: relative;
    width: 100%;
    padding-top: calc(calc(50% / #{$slides-per-view}) - 1px);
  }

  .inlinebox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .items .item {
    padding: 8px;
  }
  .rightbox {
    position: relative;
    width: 100%;
    padding-top: 46.8%;
  }
  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@include mediaMax($grid-breakpoints-lg) {
  :deep(.hot-sell-horizontal-item) {
    height: 100%;
    background-color: var(--white);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  }
}
</style>
