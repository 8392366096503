import { configure } from 'vee-validate';
import * as veeValidateI18n from '@vee-validate/i18n';
import * as yup from 'yup';
// vee-validate
import enLang from '@/validator/vee-language/en';
import jaLang from '@/validator/vee-language/ja';
import zhCNLang from '@/validator/vee-language/cn';
import zhTWLang from '@/validator/vee-language/tw';
// yup
import yupZhTW from '@/validator/yup-language/tw';

const veeLangs = {
  en: enLang,
  ja: jaLang,
  zh_CN: zhCNLang,
  zh_TW: zhTWLang,
};

const yupLangs = {
  zh_TW: yupZhTW,
};

export const initVeeValidateI18n = (lang = 'zh_TW') => {
  configure({
    generateMessage: veeValidateI18n.localize(veeLangs),
  });

  veeValidateI18n.setLocale(lang);
  yup.setLocale(yupLangs[lang]);
};
