import { defineStore } from 'pinia';

const state = () => ({
  errorStatus: null,
  errorMessage: null,
});

const getters = {
  getErrorStatus(state) {
    return state.errorStatus;
  },
  getErrorMessage(state) {
    return state.errorMessage;
  },
};

const actions = {
  setErrorStatus(value) {
    this.errorStatus = value;
  },
  setErrorMessage(value) {
    this.errorMessage = value;
  },
  clearError() {
    this.errorStatus = null;
    this.errorMessage = null;
  },
};

export const useLayoutErrorStore = defineStore('layoutError', { state, getters, actions });
