import { logEvent } from '@/helper/gtm-webview';
import { getGtmDataLayer } from '@/helper/gtm';

/**
 * 登入成功時設定 gtm user id
 */
export default function (context) {
  /**
   * nuxt2 middleware 及 nuxt.config auth.plugin 的 context 似乎拿不到 plugin inject 的 $gtm
   * 參考：https://stackoverflow.com/questions/73241241/how-to-run-nuxt-plugins-before-global-middleware
   * */
  const { pluginContext } = context;
  const { $gtm, $auths } = pluginContext;
  const gtmSend = getGtmDataLayer($gtm);

  const uuid = $auths?.user()?.uuid || '';
  gtmSend.push({ userId: uuid });
  logEvent(`set`, { userId: uuid });
  // GA4
  gtmSend.push({ user_id: uuid });
  if (typeof ga !== 'undefined') {
    // eslint-disable-next-line no-undef
    ga('set', 'userId', uuid);
  }
}
