/**
 * location: zh_TW
 * @const {object} vee-validate error message
 */
export default {
  code: 'zh_TW',
  messages: {
    alpha: '{field} 須以英文組成。',
    alpha_dash: '{field} 須以英數或連字號及底線組成。',
    alpha_num: '{field} 須以英或數組成。',
    alpha_spaces: '{field} 須以英文及空格組成。',
    between: '{field} 須介於 0:{min} 至 1:{max}之間。',
    confirmed: ' {field} 不一致。',
    digits: '{field} 須為 0:{length} 位數字。',
    dimensions: '{field} 圖片尺寸不正確。須為 0:{width} x 1:{height} 像素。',
    email: '{field} 須為有效的電子信箱。',
    excluded: '{field} 的選項無效。',
    ext: '{field} 須為有效的檔案。',
    image: '{field} 須為圖片。',
    oneOf: '{field} 的選項無效。',
    integer: '{field} 須為整數。',
    length: '{field} 的長度須為 0:{length}。',
    max: '{field} 不能大於 0:{length} 個字。',
    max_value: '{field} 不得大於 {max}。',
    mimes: '{field} 須為有效的檔案類型。',
    min: '{field} 不能小於 0:{length} 個字。',
    min_value: '{field} 不得小於 {min}。',
    numeric: '{field} 須為數字。',
    regex: '{field} 的格式錯誤。',
    required_if: '{field} 為必填。',
    size: '{field} 的檔案須小於 {size}KB。',

    /**
     * custom
     */
    required: '{field} 為必填。',
    required_en: '{field} 請輸入英文。',
    address_en: '{field} 請輸入英文。',
    address_tw: '{field} 輸入不完整。',
    minmax: '{field} 須介於 0:{min} 至 1:{max}之間。',
    invoiceMobileDevice: '{field} 格式有誤。',
    invoiceIDDevice: '{field} 格式有誤。',
    phone: '{field} 格式有誤。',
    phoneTW: '{field} 格式有誤。',
    phoneHK: '{field} 格式有誤。',
    telephoneArea: '{field} 格式有誤。',
    telephoneNumber: '{field} 格式有誤。',
    telephoneExt: '{field} 格式有誤。',
    is_not: '{field} 欄位必填。',
    password: '請設定6~24碼英數混合{field} ',
    confirm: '{field} 需與新密碼相同。',
    sizeMb: '{field} 大於{sizeMb}MB，無法上傳。',
    extMb: '{field} 類型非JPG、PNG、HEIC、HEIF或PDF，無法上傳。',
    hasLink: '{field} 的文字連接有誤，請重新輸入。',
    requiredButIgnoreMark: '{field} 格式有誤。',
    requiredName: '{field} 格式有誤。',
    requiredNameWithStore: '姓名請輸入2-5個中文字或4-10個英文字母。',
    isUnifiedNumber: '{field} 格式有誤。',
  },
};
