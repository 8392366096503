import FloatingVue from 'floating-vue';

export default ({ app }) => {
  const config = {
    themes: {
      'info-tooltip': {
        $extend: 'tooltip',
        triggers: ['click', 'hover'],
        popperTriggers: ['hover', 'focus'],
        autoHide: true,
        placement: 'bottom',
      },
    },
  };

  app.use(FloatingVue, config);
};
