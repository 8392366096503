import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_GLOBAL_AD}`;

export default (item) =>
  axios({
    method: 'get',
    url: API_PATH,
    params: { main_type: item },
  });
