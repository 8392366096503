import { defineStore } from 'pinia';
import { filter } from 'ramda';
import { isEmptyValue } from '@/helper/data-process';

/**
 * @const {object} defaultField
 * @property {string} label 欄位名稱.
 * @property {string} name 欄位名稱
 * @property {string} error The error message for the form field.
 * @property {*} value The error message for the form field.
 */
const defaultField = {
  label: '',
  name: '',
  error: '',
  value: '',
};

const state = () => ({
  data: {},
});

const getAll = (state) => state.data;
const getErrors = (state) => filter((x) => !isEmptyValue(x.error))(state.data);
const getValues = (state) => filter((x) => isEmptyValue(x.error))(state.data);

const getters = {
  getAll,
  getErrors,
  getValues,
};

const actions = {
  /**
   * @param {{name: string, label: string, error: string, value: string}} data 塞入值或是清除已無error值
   * @returns {false|object}
   */
  setData(data = defaultField) {
    if (isEmptyValue(data.name)) return false;

    if (isEmptyValue(data.error)) delete this.data[data.name];
    else this.data[data.name] = data;

    return this.data[data.name];
  },
  /**
   * 設定預設或清除
   * @param {object} def default object or empty object data
   * @returns Object
   */
  setDefault(def = {}) {
    this.data = def;
    return this.data;
  },
};

export const useValidateStore = defineStore('validate', {
  state,
  getters,
  actions,
});
