import { map, pipe, prop, sortBy } from 'ramda';
import { emptyReplace } from '@/helper/data-process';

/**
 * step1 主打活動版位
 * 購物車活動下方，兩行紅色的字
 * @type {(function(...[*]=))|*}
 */
const step1PromoEventItems = pipe(
  map((x) => ({
    id: x.id,
    title: x.title,
    link: x.link,
    sort: x.sort,
  })),
  sortBy(prop('sort')),
);
const step1PromoEvent = (ad) => ({
  type: ad?.banner_type,
  blank: emptyReplace(ad?.blank, false),
  items: step1PromoEventItems(emptyReplace(ad?.items, [])),
});

/**
 * Step3 橫幅
 * @param ad
 * @return {{image, blank, alt, link, type: *}}
 */
const step3Horizontal = (ad) => ({
  type: ad?.banner_type,
  blank: emptyReplace(ad?.blank, false),
  alt: emptyReplace(ad?.alt, ''),
  image: emptyReplace(ad?.image, ''),
  link: emptyReplace(ad?.link, ''),
});

export default {
  step1PromoEvent,
  step3Horizontal,
};
