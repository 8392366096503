import generator from '@/helper/enum-generator';
/**
 * @const {array} invoicesEnum invoice enum
 */
export const invoicesEnum = [
  {
    name: '二聯式電子發票(存入會員帳號)',
    code: 'invoice_member_account',
    key: 'member',
    component: '',
    oversea: false,
    vehicleType: 'ER0032',
  },
  {
    name: '二聯式電子發票(手機條碼載具)',
    code: 'invoice_mobile',
    key: 'cellphoneBarcode',
    component: 'InvoiceMobile',
    oversea: false,
    vehicleType: '3J0002',
  },
  {
    name: '二聯式電子發票(自然人憑證載具)',
    code: 'invoice_CDC',
    key: 'citizenDigitalCertificateBarcode',
    component: 'InvoiceIDNumber',
    oversea: false,
    vehicleType: 'CQ0001',
  },
  {
    name: '二聯式電子發票(紙本證明聯)',
    code: 'invoice_paper',
    key: 'twoPieceElectronic',
    component: '',
    oversea: false,
    vehicleType: '',
  },
  {
    name: '三聯式電子發票(紙本證明聯)',
    code: 'invoice_triplicate',
    key: 'triplePiecePaper',
    component: 'InvoiceEPaper',
    oversea: false,
    vehicleType: '',
  },
  {
    name: '發票捐贈',
    code: 'invoice_donate',
    key: 'donate',
    component: 'InvoiceDonation',
    oversea: false,
    vehicleType: '',
  },
  {
    name: '海外零稅',
    code: 'invoice_oversea',
    key: 'zero',
    component: '',
    oversea: true,
    vehicleType: '',
  },
];

/**
 * 發票類型
 * {object} invoiceTypeEnum { key: name } 組合
 * @sample member: 'double_e_invoice_of_member_account'
 * @type {{ member: 'invoice_member_account' },{ cellphoneBarcode: 'invoice_mobile' },{ citizenDigitalCertificateBarcode: 'invoice_CDC' },{ twoPieceElectronic: 'invoice_paper' },{ triplePiecePaper: 'invoice_triplicate' },{ donate: 'invoice_donate' },{ zero: 'invoice_oversea' }}
 * */
export const invoiceTypeEnum = generator(invoicesEnum, 'key', 'code');

/**
 * 發票類型`名稱`
 * invoiceTypeName { key: name } 組合
 * @sample member: '二聯式電子發票（存入會員帳號）
 * @type {{ member: '二聯式電子發票(存入會員帳號)' }, { cellphoneBarcode: '二聯式電子發票(手機條碼載具)' }, { citizenDigitalCertificateBarcode: '二聯式電子發票(自然人憑證載具)' }, { twoPieceElectronic: '二聯式電子發票(紙本證明聯)' }, { triplePiecePer: '三聯式電子發票' }, { donate: '發票捐贈' }, { zero: '海外零稅' }}
 * */
export const invoiceTypeName = generator(invoicesEnum, 'key', 'name');

/**
 * @const {object} invoiceTypeNameEnum { code: name } 組合
 * double_e_invoice_of_member_account: '二聯式電子發票（存入會員帳號）',
 * @type {{ invoice_member_account: '二聯式電子發票(存入會員帳號)' }, { invoice_mobile: '二聯式電子發票(手機條碼載具)' }, { invoice_CDC: '二聯式電子發票(自然人憑證載具)' }, { invoice_paper: '二聯式電子發票(紙本證明聯)' }, { invoice_triplicate: '三聯式電子發票' }, { invoice_donate: '發票捐贈' }, { invoice_oversea: '海外零稅' }}
 * */
export const invoiceTypeNameEnum = generator(invoicesEnum, 'code', 'name');

/**
 * @const {object} invoiceVehicleTypeEnum { code: vehicleType } 組合
 * @type {{ invoice_member_account: 'ER0032' }, { invoice_mobile: '3J0002' }, { invoice_CDC: 'CQ0001' }, { invoice_paper: '' }, { invoice_triplicate: '' }, { invoice_donate: '' }, { invoice_oversea: '' }}
 * */
export const invoiceVehicleTypeEnum = generator(invoicesEnum, 'code', 'vehicleType');

/**
 * 發票類型`元件`
 * @const {object} invoiceTypeComponent { code: component } 組合
 * @type {{ invoice_member_account: '' }, { invoice_mobile: 'InvoiceMobile' }, { invoice_CDC: 'InvoiceIDNumber' }, { invoice_paper: 'InvoicePaper' }, { invoice_triplicate: 'InvoiceEPaper' }, { invoice_donate: 'InvoiceDonation' }, { invoice_oversea: '' }}
 */
export const invoiceTypeComponent = generator(invoicesEnum, 'code', 'component');

/** @const {object} invoiceOutput invoice 子 component 統一輸出格式 */
export const invoiceOutput = { type: '', company: '', number: '', cellphone_barcode: '', citizen_digital_certificate_barcode: '' };
