import VueLazyLoad from 'vue-lazyload';
import { productDefaultx3Img, loadingLargeImg } from '@/constant/images-path';
import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';

export default ({ app }) => {
  function getlazyConfigByEnv() {
    if (checkIsImageSnapshotTest()) {
      return { preLoad: 10 };
    }
    return {};
  }

  app.use(VueLazyLoad, {
    preLoad: 1.3,
    error: productDefaultx3Img,
    loading: loadingLargeImg,
    // attempt: 1,
    ...getlazyConfigByEnv(),
  });
};
