import { isSessionStorageAvailable } from '@/helper/window-property';
import { useVisitStore } from '@/stores/visit';

/**
 * 紀錄 route 軌跡
 * reload 、瀏覽器輸入網址或直接 a link 換頁時不覆蓋上一頁紀錄
 * @param from
 * @param route
 */

export default function ({ from, route }) {
  if (!isSessionStorageAvailable) return;
  const previous = sessionStorage.getItem('visit/CurrentUri') || '/';
  const previousParams = sessionStorage.getItem('visit/CurrentParams') || '{}';
  const newQuery = route.query || {};
  const newQueryString = JSON.stringify(newQuery);
  const allowPath = previous.substr(0, 1) === '/';
  const visitStore = useVisitStore();
  const { setCurrentUri, setCurrentParams, setPreviousUri, setPreviousParams } = visitStore;

  // set current page
  sessionStorage.setItem('visit/CurrentUri', route.path || '/');
  sessionStorage.setItem('visit/CurrentParams', newQueryString);
  setCurrentUri(route.path || '/');
  setCurrentParams(newQuery);

  // 若紀錄的上一頁與這一頁不同時
  if ((from.fullPath !== route.fullPath || previousParams !== newQueryString) && allowPath) {
    // history 為路徑格式時將 CurrentUri 記錄寫入上一頁記錄 PreviousUri
    sessionStorage.setItem('visit/PreviousUri', previous);
    sessionStorage.setItem('visit/PreviousParams', previousParams);
    setPreviousUri(previous);
    setPreviousParams(JSON.parse(previousParams));
    return;
  }

  setPreviousUri(sessionStorage.getItem('visit/PreviousUri'));
  setPreviousParams(sessionStorage.getItem('visit/PreviousParams'));
}
