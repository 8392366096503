import { isTagtooAllowed } from '@/helper/tagtoo';

export default function ({ route }) {
  const isTagtooEnable = isTagtooAllowed(route.path);
  if (isTagtooEnable) {
    if (window.esliteAddTagtooEvent) window.esliteAddTagtooEvent();
    return;
  }
  if (window.esliteRemoveTagtooEvent) window.esliteRemoveTagtooEvent();
}
