<template>
  <div class="layout-position row mx-0">
    <div class="container-lg px-0 mx-0">
      <slot name="header"></slot>
    </div>
    <div class="container-lg row wrap-reverse mx-lg-0 bg-white px-0 mx-0">
      <div class="left-box col-12 col-lg-8 px-0 mx-0 pe-3 pe-xl-2 ps-3 ps-lg-0">
        <slot name="left-content"></slot>
      </div>
      <div class="right-box col-12 col-lg-4 px-lg-0 mx-lg-0 p-0">
        <slot name="right-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexLayout',
};
</script>

<style lang="scss" scoped>
.right-box {
  background: none;
}

@include mediaMax($grid-breakpoints-lg) {
  .right-box {
    background-color: var(--l-gray-e);
    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
