/**
 * 預設 meta
 * @type {{title: string}}
 */
const defaultMeta = {
  title: '誠品線上 - 閱讀與生活的無盡想像',
};

/**
 * 預設 meta
 * @return {{title: string}}
 */
export const getDefaultMeta = () => ({
  ...defaultMeta,
});

export const categoryL1Metas = {
  chinese: {
    name: `中文書`,
    keywords: `新書推薦, 中文書, 新書列表, 誠品網路書店, 誠品線上`,
    description: `文青是一種思想、一種生活態度。享受一個人獨處，用一本書陪伴整個下午。誠品提供最新出版書訊及新書推薦，齊全的華文出版品：含文學、藝術、生活、圖文漫畫、科普、人文社科、商業財經、語言考用、電腦書…等。`,
  },
  foreign: {
    name: `外文書`,
    keywords: `外文書, 誠品網路書店, 誠品線上`,
    description: `誠品線上外文書館與英美日最新閱讀趨勢同步，以空運現貨提供各類原文書。推薦熱門書籍包括文學、青少年文學、建築、藝術、設計、生活、人文、社科、商業行銷、語言學習、雜誌特刊等。`,
  },
  child: {
    name: `兒童親子`,
    keywords: `兒童,兒童書, 玩教具, 誠品網路書店, 誠品線上`,
    description: `誠品線上兒童親子館提供最新國內外0-12歲童書及優惠，包含中英文嬰幼兒圖書、學齡前幼兒圖書、圖畫書、繪本、兒童文學、知識類、語言學習、玩具、教具、兒童外文圖書館專營英文童書。`,
  },
  magazine: {
    name: `雜誌`,
    keywords: `雜誌, MOOK, 誠品網路書店, 誠品線上`,
    description: `誠品線上雜誌館提供流行、知識、生活、美感的最新情報，以及種類豐富的超值附錄贈品雜誌。包含中文雜誌、日文雜誌、MOOK、英文雜誌及其他語言雜誌。`,
  },
  video: {
    name: `影音`,
    keywords: `影音, 誠品網路書店, 誠品線上`,
    description: `誠品線上影音館提供多元的音樂，在數位時代下，購買專輯聆聽與收藏別具意義，無論是流行、電子、搖滾、爵士、古典、新世紀、世界、原聲配樂、有聲CD、SACD等專輯，在主流與非主流間，更能深度探尋誠品音樂所帶來的美好。`,
  },
  stationery: {
    name: `文具`,
    keywords: `格文具, 設計文具, 誠品網路書店, 誠品線上`,
    description: `快來看看有什麼必買的風格文具。從筆記本到mt紙膠帶，每項都讓你失心瘋的優質選物推薦。獨家！限量！設計文創品牌，連結超過一百個台灣文創品牌和四十位台灣設計師等。`,
  },
  fashion: {
    name: `流行`,
    keywords: `流行, 誠品網路書店, 誠品線上`,
    description: `誠品線上流行館提供包袋皮夾、手錶、飾品、配件、香氛洗沐、美妝/保養、流行服裝、鞋履/襪、潮流收藏與流行數位商品。`,
  },
  life: {
    name: `生活`,
    keywords: `生活, 誠品網路書店, 誠品線上`,
    description: `生活大小事都在誠品書店，雜貨禮品&選物專門店。家電視聽、3C周邊、居家生活雜貨、佈置擺設，每月精選活動，誠品網路書店會員再享折扣！`,
  },
  food: {
    name: `食品`,
    keywords: `食品, 誠品知味, 誠品網路書店, 誠品線上`,
    description: `誠品線上食品館提供小農專區、水/飲料、沖泡飲品、休閒零食、油品調味、烹飪食材、南北貨/罐頭、米/麵、節慶禮盒與保健商品。`,
  },
};

export const metaTitleSeperator = ' │ ';

export const composePageTitle = (titlePrefix) => {
  if (!titlePrefix) return defaultMeta.title;
  if (Array.isArray(titlePrefix)) return [...titlePrefix, defaultMeta.title].join(metaTitleSeperator);
  return `${titlePrefix}${metaTitleSeperator}${defaultMeta.title}`;
};

export const composeMetaInfoForTitleOnly = (title) => ({
  title,
  meta: [{ property: 'og:title', content: title, vmid: 'og:title' }],
});

export const composeMetaInfo = ({ metaTitle, titlePrefix, keywords, description }) => {
  const title = metaTitle || composePageTitle(titlePrefix);
  const meta = [
    // window.location.href 只能在 page metaInfo 是 function 時使用
    { property: 'og:url', content: window.location.href, vmid: 'og:url' },
    // { property: 'og:type', content: 'Article', vmid: 'og:type' },
    // { property: 'og:site_name', content: '誠品線上 - 閱讀與生活的無盡想像', vmid: 'og:site_name' },
    { property: 'og:title', content: title, vmid: 'og:title' },
    { property: 'og:image', content: 'https://www.eslite.com/eslite-og-logo.png', vmid: 'og:image' },
  ];
  if (keywords) meta.push({ property: 'og:keywords', content: keywords, vmid: 'og:keywords' });
  if (description) meta.push({ property: 'og:description', content: description, vmid: 'og:description' });

  return { title, meta };
};
