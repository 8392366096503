/**
 * 購物車預設帶入資訊
 * 優先順位： localStorage > 最新一筆訂單 > 會員資料 > 無
 *
 */
import { cloneDeep } from 'lodash-es';
import addressBookApi from '@/api/member/address-book';
import { isEmptyValue, getJSON } from '@/helper/data-process';
// import { getWindowProperty } from '@/helper/window-property';
import { enumCountry } from '@/constant/member/country';
import { emptyRecipient } from '@/constant/checkout-result';
import { deliveryTypeEnum } from '@/constant/delivery-type';
import storage from '@/helper/secure-local-storage';
import { isJSON } from '@/helper/string';
import cryptoUtility from '@/helper/crypto.js';
import { isVitest } from '@/helper/test/test-env';

const ignoreMembers = ['8883068794', '8884128748'];

const canNotUseCrypto = () => typeof crypto === 'undefined';

/**
 * 預設值
 * @type {{delivery: string, subscriber: {zip: string, country: string, address: string, city: string, district: string, mobile: {country: string, number: string}, name: string, telephone: {country: string, number: string, extension: string}, store: {address: string, name: string, id: string, type: string}}, recipient: {country: string, address: string, city: string, district: string, mobile: {country: string, number: string}, name: string, telephone: {country: string, number: string, extension: string}, store: {address: string, name: string, id: string, type: string}}, payment: string, invoice: {number: string, citizen_digital_certificate_barcode: string, mobile: string, name: string, company: string, type: string, cellphone_barcode: string}}}
 */
const defaultOrder = {
  delivery: '',
  invoice: { cellphone_barcode: '', citizen_digital_certificate_barcode: '', company: '', mobile: '', name: '', number: '', type: 'invoice_member_account' },
  payment: '',
  recipient: {
    address: '',
    city: '',
    country: '',
    district: '',
    mobile: { country: '886', number: '' },
    name: '',
    store: { address: '', id: '', name: '', type: '' },
    telephone: { country: '886', extension: '', number: '' },
  },
  subscriber: {
    address: '',
    city: '',
    country: '',
    district: '',
    mobile: { country: '886', number: '' },
    name: '',
    store: { address: '', id: '', name: '', type: '' },
    telephone: { country: '886', extension: '', number: '' },
    zip: '',
  },
  encode: false,
  type: null,
  order_number: '',
};

/** @const {string} cartKey 儲存 key */
export const cartUserKey = `cartUser`;

// /** @const {string} DBType 存取資源名稱 (localStorage, sessionStorage) */
// const DBType = `localStorage`;

// /** @const {Storage} storage storage */
// const storage = getWindowProperty(DBType);

function formatProfile(profile) {
  const clonedProfile = cloneDeep(profile);
  if (clonedProfile.mobile?.country) clonedProfile.mobile.country = Number(clonedProfile.mobile.country);
  if (clonedProfile.telephone?.country) clonedProfile.telephone.country = Number(clonedProfile.telephone.country);
  return clonedProfile;
}

function formatCartInfo(data) {
  const clonedData = cloneDeep(data);

  clonedData.subscriber = formatProfile(clonedData.subscriber);
  clonedData.recipient = formatProfile(clonedData.recipient);

  // 訂購人地址不帶入預設為空值
  clonedData.subscriber.address = '';
  clonedData.subscriber.city = '';
  clonedData.subscriber.country = '';
  clonedData.subscriber.district = '';
  clonedData.subscriber.isIsland = false;
  clonedData.subscriber.zip = '';

  return clonedData;
}

/**
 *
 * @param {object} userObj
 * @param {string} token
 * @return {Promise<null|*>}
 */
export const getCartInfo = async function (userObj, token) {
  const user = userObj.value?.id !== undefined ? userObj.value : userObj;
  const cartUser = `${cartUserKey}${user.id || ''}`;

  // 未加密舊資料防呆改寫
  const checkIsOldData = localStorage.getItem(cartUser) || null;
  if (isJSON(checkIsOldData)) await storage.setItem(cartUser, checkIsOldData);

  // 由 storage 內取得資訊
  let formData = null;
  if (!canNotUseCrypto()) {
    try {
      const res = await storage.getItem(cartUser);
      formData = getJSON(res) || null;
    } catch (e) {
      console.log(e);
      storage.removeItem(cartUser);
    }
  }

  // 若 storage 內有物件則拋出
  if (formData !== null) {
    // console.log(`[Step2 Default form data] localStorage data`);
    // console.log(formData);
    return formatCartInfo(formData);
  }

  let firstOrderData = null;

  // 取得地址簿內容
  await addressBookApi(token)
    .then((res) => {
      if (res.data.length <= 0) return false;

      const addressBookHomeDatas = res.data.filter((x) => [deliveryTypeEnum.aboard, deliveryTypeEnum.donate].includes(x.delivery_type) === false);
      if (addressBookHomeDatas.length <= 0) return false;
      const addressBookHomeData = addressBookHomeDatas[0];

      firstOrderData = {
        delivery: '',
        subscriber: {
          address: '',
          city: '',
          country: user.country || '',
          district: '',
          mobile: {
            country: user.phone_country_code || '886',
            number: user.phone_number || '',
          },
          name: user.name || '',
          store: { address: '', id: '', name: '', type: '' },
          telephone: {
            country: '886', // 無此欄位 (寫死)
            extension: user.day_tel_ext || '',
            number: `${user.day_tel_area || ''}${user.day_tel || ''}`,
          },
          zip: user.zipcode,
        },
        recipient: {
          ...emptyRecipient,
          name: addressBookHomeData.name || '',
          mobile: {
            country: addressBookHomeData.mobile_country_code || '',
            number: addressBookHomeData.mobile_phone || '',
          },
          telephone: {
            country: addressBookHomeData.telephone_country_code || '',
            number: addressBookHomeData.telephone || '',
            extension: addressBookHomeData.extension || '',
          },
          store: { address: '', id: '', name: '', type: '' },
          country: '',
          city: '',
          district: '',
          address: '',
        },
      };

      // 個案處理部分
      if (ignoreMembers.includes(user.lms_member_id)) {
        firstOrderData.subscriber.mobile.number = '';
        firstOrderData.subscriber.telephone.number = '';
      }
      // 判斷資料來源是否為加密內容
      firstOrderData.encode = firstOrderData.subscriber.name.indexOf('*') > -1;
      // console.log(firstOrderData);

      if (!isVitest) {
        console.group(`[Step2 Default form data] address-book api`);
        console.log(`origin`);
        console.log(res.data);
        console.log(`filter ignore aboard`);
        console.log(addressBookHomeDatas);
        console.log(`use data`);
        console.log(addressBookHomeData);
        console.log(`cart data`);
        console.log(firstOrderData);
        console.groupEnd();
      }
      storage.setItem(cartUser, JSON.stringify(firstOrderData));
      return true;
    })
    .catch((e) => console.log(e));

  if (firstOrderData !== null) {
    // 個案處理部分
    if (ignoreMembers.includes(user.lms_member_id)) {
      firstOrderData.subscriber.mobile.number = '';
      firstOrderData.subscriber.telephone.number = '';
    }

    return formatCartInfo(firstOrderData);
  }

  // 取得會員資料
  if (typeof user !== 'undefined') {
    const cityDistrict = (user.city_district || '').replace(/^(.*[縣|市])(.*)$/, (a, b, c) => `${b}|${c}`).split('|');
    const city = cityDistrict[0] || '';
    const district = cityDistrict[1] || '';
    const filterCountry = enumCountry.find((e) => {
      return e.value === user.country;
    })?.country;

    /** @const {string} addr 若來源 user address 是未解密內容時解密 */
    const addr = isEmptyValue(cryptoUtility.decrypt(user.address)) ? user.address : cryptoUtility.decrypt(user.address);

    firstOrderData = {
      ...defaultOrder,
      subscriber: {
        address: addr,
        city,
        country: filterCountry || user.country || '',
        district,
        mobile: {
          country: user.phone_country_code || '886',
          number: user.phone_number || '',
        },
        name: user.name || '',
        store: { address: '', id: '', name: '', type: '' },
        telephone: {
          country: '886', // 無此欄位 (寫死)
          extension: user.day_tel_ext || '',
          number: `${user.day_tel_area || ''}${user.day_tel || ''}`,
        },
        zip: user.zipcode,
      },
      recipient: {
        address: addr,
        city,
        country: filterCountry || user.country || '',
        district,
        mobile: {
          country: user.phone_country_code || '886',
          number: user.phone_number || '',
        },
        name: user.name || '',
        store: { address: '', id: '', name: '', type: '' },
        telephone: {
          country: '886', // 無此欄位 (寫死)
          extension: user.day_tel_ext || '',
          number: `${user.day_tel_area || ''}${user.day_tel || ''}`,
        },
        zip: user.zipcode,
      },
    };

    // 個案處理部分
    if (ignoreMembers.includes(user.lms_member_id)) {
      firstOrderData.subscriber.mobile.number = '';
      firstOrderData.subscriber.telephone.number = '';
    }

    // 判斷資料來源是否為加密內容
    firstOrderData.encode = firstOrderData.subscriber.name.indexOf('*') > -1;
    // 資料來源：訂單
    firstOrderData.type = 'member';
    firstOrderData.order_number = '';

    if (!isVitest) {
      console.group(`[Step2 Default form data] member`);
      console.log(firstOrderData);
      console.groupEnd();
    }
    storage.setItem(cartUser, JSON.stringify(firstOrderData));
  }

  return formatCartInfo(firstOrderData);
};

/**
 * 移除購物車內預設填入內容
 * @param {object} user
 */
export const removeCartInfo = async (user) => {
  const cartUser = `${cartUserKey}${user?.id || ''}`;
  await storage.removeItem(cartUser);
};
/**
 * 合併滿額滿件每台車裡的商品
 * @returns {*}
 * @param {array} items
 */
export const singleCartsItemMerge = (items) => {
  return items.reduce((res, item) => {
    return res.concat(item.items);
  }, []);
};
