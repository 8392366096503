import { manualSendGtmPageviewPathRegex } from '@/constant/gtm/gtm-pageview';
import { gtmPageview } from '@/helper/gtm';
import { getDefaultMeta } from '@/helper/meta';

let previousTitle = '';

const defaultTitle = getDefaultMeta().title;

const gtmSendPageview = (gtmInstance) => (mutations) => {
  const pageTitle = mutations[0].target.innerText;
  // title 沒變，不需 send
  if (pageTitle === previousTitle) return;

  const { pathname, search } = window.location;
  // 防止refresh，default title，但不是 home page，不需 send
  if (pageTitle === defaultTitle && pathname !== '/') return;

  // 手動傳 pageview 的 page，不需 send
  const found = Object.values(manualSendGtmPageviewPathRegex).find((regex) => pathname.match(regex));
  if (found) return;

  // send gtm
  previousTitle = pageTitle;
  const pagePath = search ? `${pathname}${search}` : pathname;
  gtmPageview({ pageTitle, pagePath }, gtmInstance);
};

export function observeTitleForPageview(gtmInstance) {
  const observer = new MutationObserver(gtmSendPageview(gtmInstance));
  const titleNode = document.querySelector('title');
  observer.observe(titleNode, { childList: true });
}
