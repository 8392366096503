import { checkDayTimeBetween } from '@/helper/filter/date';

// 系統維護控制

/** @const {string} redirectPage 轉跳路徑 */
export const redirectPage = '/docs/maintenance';

/** @const {boolean} isAllRedirect 是否全站轉跳維修頁 */
export const isAllRedirect = false;

/** @const {string} startDateTime 起始時間 */
export const startDateTime = '2022/3/2 23:00:00';

/** @const {string} endDateTime 結束時間 */
export const endDateTime = '2022/3/3 00:09:00';

/** @const {boolean} isBetween 判斷現在時間是否在維護時間內 */
export const isBetween = () => checkDayTimeBetween(startDateTime, endDateTime);
