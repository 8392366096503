import { defineStore } from 'pinia';

const Timing = 15 * 60 * 1000;
export const useMemberProfileStore = defineStore('memberProfile', {
  state: () => ({
    doubleCheckPassword: false,
  }),
  actions: {
    checkPassword(data) {
      this.doubleCheckPassword = data;
      data = null;
    },
    setDoubleCheckPassword(data) {
      this.checkPassword(data);
      const timer = setTimeout(() => {
        this.checkPassword(false);
        clearTimeout(timer);
      }, Timing);
    },
  },
});
