import { invoiceTypeEnum } from '@/constant/invoice';

export const orderStatus = {
  success: 'Success',
  eslitePointsFail: 'EslitePointsFail',
  globalFail: 'GlobalFail',
  error: 'Error',
  loading: 'Loading',
};

const emptyMobile = {
  country: 886,
  number: '',
};

const emptyATM = {
  bank: '',
  account: '',
  expired: '',
};

export const emptyTelephone = {
  country: 886,
  number: '',
  extension: '',
};

export const emptyStore = {
  id: '',
  type: '', // 配送方式 type (ex: shopping_seven)
  delivery: '', // 配送方式 delivery (ex: 5)
  name: '',
  address: '',
  city: '',
};

const emptyInvoice = {
  type: '',
  name: '',
  company: '',
  number: '',
  cellphone_barcode: '',
  citizen_digital_certificate_barcode: '',
};

export const emptySubscriber = {
  name: '',
  mobile: { ...emptyMobile },
  telephone: { ...emptyTelephone },
  store: { ...emptyStore },
  country: '',
  city: '',
  district: '',
  address: '',
};

const emptyProfile = {
  name: '',
  country: '',
  zip: '',
  city: '',
  district: '',
  address: '',
  isIsland: false,
};

export const emptyRecipient = {
  id: '',
  ...emptyProfile,
  mobile: { ...emptyMobile },
  telephone: { ...emptyTelephone },
  store: { ...emptyStore },
  country: '',
  city: '',
  district: '',
  address: '',
};

export const emptyData = {
  mobile: { ...emptyMobile },
  atm: { ...emptyATM },
  telephone: { ...emptyTelephone },
  store: { ...emptyStore },
  subscriber: { ...emptySubscriber },
  recipient: { ...emptyRecipient },
  invoice: { ...emptyInvoice },
  profile: { ...emptyProfile },
};

/**
 * 空訂單結構
 * @type {{delivery: string, subscriber: {address: string, phone: string, name: string, email: string}, recipient: {country: string, address: string, city: string, district: string, name: string, mobile: {country: string, number: string}, telephone: {country: string, number: string, extension: string}, store: {address: string, name: string, id: string, type: string}}, payment: string, invoice: {number: string, cellphoneBarcode: string, name: string, company: string, type: string, citizenDigitalCertificateBarcode: string}}}
 */
export const emptyOrderData = {
  delivery: '',
  payment: '',
  subscriber: { ...emptySubscriber },
  recipient: { ...emptyRecipient },
  invoice: { ...emptyInvoice },
};

/**
 * 空發票
 * @type {{number: string, citizen_digital_certificate_barcode: string, company: string, type: "invoice_member_account", cellphone_barcode: string, invoice_city: string, invoice_district: string, invoice_zip: string, invoice_address: string }}
 */
export const emptyInvoiceData = {
  type: invoiceTypeEnum.member,
  company: '', // 公司抬頭
  number: '', // 統一編號
  cellphone_barcode: '', // 手機載具
  citizen_digital_certificate_barcode: '', // 自然人憑證
  invoice_city: '',
  invoice_district: '',
  invoice_zip: '',
  invoice_address: '',
};

/**
 * 空門市 （包含：同訂購人、近期寄送、修改收件人資料），不區分配送方式不然太大包，只對應看 type 對應 delivery type 給值或是覆蓋
 * other 等同 default, subscriber 共用
 * @type { address_books: emptyData.store, default: emptyData.store, }
 */
export const emptyStores = {
  address_books: { ...emptyData.store },
  default: { ...emptyData.store },
};

export const defInvoice = { type: invoiceTypeEnum.member, company: '', number: '', cellphone_barcode: '', citizen_digital_certificate_barcode: '' };
