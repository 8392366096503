<template>
  <div class="eslite-home" :class="isVirtualShelfLayout ? 'virtual-shelf' : ''">
    <div id="scrollHash-0" ref="scrollHash0" class="scrollHash" data-num="0"></div>
    <div class="container-fluid px-0 section-focus-point">
      <!-- 注目焦點, 輪播大B-->
      <top-slide-position></top-slide-position>
    </div>
    <div class="row py-lg-2 py-1 mx-0"></div>
    <div class="container-fluid container-md px-0">
      <!--  今日最推, 流行熱搜, 小B-->
      <recommend-and-hot-topic-position :control="homeControl.recommendAndHotTopicPosition"></recommend-and-hot-topic-position>
      <div id="scrollHash-1" ref="scrollHash1" class="scrollHash" data-num="1"></div>
      <!--  好康優惠 (四塊)-->
      <discount-area-position v-if="showScrollHash2" :control="homeControl.discountArea"></discount-area-position>
      <div class="mt-2"></div>
      <div v-show="showScrollHash2" id="scrollHash-2" ref="scrollHash2" class="scrollHash" data-num="2"></div>
      <!--  首頁橫幅-->
      <horizontal-paint-sliders v-if="showScrollHash2" class="d-none d-lg-block"></horizontal-paint-sliders>
    </div>
    <div v-if="notVirtualShelfLayout" class="row py-lg-2 py-0 py-md-3 mx-0"></div>
    <div v-show="showScrollHash3" class="container-fluid container px-0">
      <div id="scrollHash-3" ref="scrollHash3" class="scrollHash" data-num="3"></div>
      <!--  網路暢銷榜, 誠品獨家, 誠品選書, 誠品選樂-->
      <eslite-charts-and-exclusive-position v-if="showScrollHash3"></eslite-charts-and-exclusive-position>
    </div>
    <div v-if="notVirtualShelfLayout" class="row py-2 py-md-2 mx-0"></div>
    <div v-show="showScrollHash4" class="container-fluid container px-0">
      <div id="scrollHash-4" ref="scrollHash4" class="scrollHash" data-num="4"></div>
      <!--  主題企劃, 品牌週-->
      <theme-planning-and-brand-week-position v-if="showScrollHash4" :control="homeControl.themePlannigAndBrandWeekPosition"></theme-planning-and-brand-week-position>
    </div>
    <div class="container-fluid container px-0" :class="isVirtualShelfLayout ? '' : 'my-4'">
      <!--  品牌列表-->
      <brand-list v-if="showScrollHash4"></brand-list>
    </div>
    <div v-show="homeControl.threadPosition && showScrollHash4" class="container-fluid container px-0">
      <!-- 線別-->
      <thread-position v-if="homeControl.threadPosition && showScrollHash4"></thread-position>
    </div>
  </div>
</template>

<!-- layout -->
<route>
  { meta: { layout: "home" } }
</route>

<script>
import { includes } from 'ramda';
import { ref, computed, defineAsyncComponent, onMounted } from 'vue'; // 引入defineAsyncComponent
import { useHomeAdStore } from '@/stores/home-ad';
import virtualShelf from '@/composables/utility/virtual-shelf';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import TopSlidePosition from '@/components/index/top-slide-position.vue';
import RecommendAndHotTopicPosition from '@/components/index/recommend-and-hot-topic-position/recommend-and-hot-topic-position.vue';
import { getHomeControl } from '@/constant/page-control/home-control';

const BrandList = defineAsyncComponent(() => import('@/components/index/brand-list.vue'));
const ThreadPosition = defineAsyncComponent(() => import('@/components/index/thread-position.vue'));
const DiscountAreaPosition = defineAsyncComponent(() => import('@/components/index/discount-area-position/discount-area-position.vue'));
const HorizontalPaintSliders = defineAsyncComponent(() => import('@/components/index/horizontal-paint-sliders.vue'));
const EsliteChartsAndExclusivePosition = defineAsyncComponent(() => import('@/components/index/eslite-charts-and-exclusive-position.vue'));
const ThemePlanningAndBrandWeekPosition = defineAsyncComponent(() => import('@/components/index/theme-planning-and-brand-week-position.vue'));

const defaultFaceTree = [
  // #0: 注目焦點, 大Banner, 今日最推, 流行熱搜, 會員小B
  [homePageADTypeEnum.focusPoint, homePageADTypeEnum.bigBanner, homePageADTypeEnum.groupRecommend, homePageADTypeEnum.hotSearch, homePageADTypeEnum.memberBanner],
  // #1: 好康優惠大b, 好康優惠小b, 好康優惠專區, 首頁橫幅
  [homePageADTypeEnum.discountBannerLarge, homePageADTypeEnum.discountBannerSmall, homePageADTypeEnum.discountArea, homePageADTypeEnum.horizontalPaint],
  // #2: 網路暢銷榜, 誠品獨家, 誠品選書, 誠品選樂
  [homePageADTypeEnum.onlineLeaderboard, homePageADTypeEnum.esliteExclusive, homePageADTypeEnum.esliteBook, homePageADTypeEnum.esliteMusic],
  // #3: 主題企劃, 品牌週, 品牌列表
  [homePageADTypeEnum.mainTopicProject, homePageADTypeEnum.weeklyBrands, homePageADTypeEnum.brandListing],
  // #4: 線別
  [homePageADTypeEnum.threadGroups],
];

const virtualShelfFaceTree = [
  // #0: 注目焦點, 大Banner, 今日最推
  [homePageADTypeEnum.focusPoint, homePageADTypeEnum.bigBanner, homePageADTypeEnum.groupRecommend],
  // #1: 好康優惠專區, 首頁橫幅
  [homePageADTypeEnum.discountArea, homePageADTypeEnum.horizontalPaint],
  // #2: 網路暢銷榜, 誠品獨家, 誠品選書, 誠品選樂
  [homePageADTypeEnum.onlineLeaderboard, homePageADTypeEnum.esliteExclusive, homePageADTypeEnum.esliteBook, homePageADTypeEnum.esliteMusic],
  // #3: 品牌週, 品牌列表
  [homePageADTypeEnum.weeklyBrands, homePageADTypeEnum.brandListing],
  // #4
  [],
];

const components = {
  BrandList,
  ThreadPosition,
  TopSlidePosition,
  DiscountAreaPosition,
  HorizontalPaintSliders,
  RecommendAndHotTopicPosition,
  EsliteChartsAndExclusivePosition,
  ThemePlanningAndBrandWeekPosition,
};

const setup = () => {
  // composables
  const { isVirtualShelfLayout, notVirtualShelfLayout } = virtualShelf();
  // template ref
  const scrollHash0 = ref(null);
  const scrollHash1 = ref(null);
  const scrollHash2 = ref(null);
  const scrollHash3 = ref(null);
  const scrollHash4 = ref(null);
  const scrollHashRefs = [scrollHash0, scrollHash1, scrollHash2, scrollHash3, scrollHash4];
  // data
  const symbolKeys = ref([]);
  const sentinels = ref({});
  const intersectionObservers = ref({});
  const clear = ref([]);
  // store
  const homeAdStore = useHomeAdStore();
  const { fetchHomeAd } = homeAdStore;

  // computed
  const faceTree = computed(() => {
    if (isVirtualShelfLayout.value) return virtualShelfFaceTree;
    return defaultFaceTree;
  });
  const showScrollHash2 = computed(() => {
    return includes(1, clear.value);
  });
  const showScrollHash3 = computed(() => {
    return includes(2, clear.value);
  });
  const showScrollHash4 = computed(() => {
    return includes(3, clear.value);
  });

  // 前三段 banner 採用 scroll intersectionObserver 方式載入
  const getHomeAd = async (num) => {
    if (faceTree.value[num] === undefined || includes(num, clear.value)) return;
    const symbolKey = symbolKeys.value[num];
    intersectionObservers.value[symbolKey].unobserve(sentinels.value[symbolKey]);
    if (faceTree.value[num].length) {
      await fetchHomeAd(faceTree.value[num]);
    }
    await clear.value.push(num);
  };

  onMounted(() => {
    faceTree.value.forEach((item, index) => {
      const symbolKey = Symbol(index);
      // set sentinel elem
      sentinels.value[symbolKey] = scrollHashRefs[index];

      // set IntersectionObserver
      const rootMargin = index === 3 ? '0px' : `0px 0px 150px 0px`;
      intersectionObservers.value[symbolKey] = new IntersectionObserver(
        async (entries) => {
          if (entries.some((entry) => entry.intersectionRatio > 0)) {
            await getHomeAd(index);
          }
        },
        { rootMargin },
      );

      intersectionObservers.value[symbolKey].observe(sentinels.value[symbolKey]);
      symbolKeys.value.push(symbolKey);
    });
  });

  return {
    homeControl: getHomeControl(),
    // template ref
    scrollHash0,
    scrollHash1,
    scrollHash2,
    scrollHash3,
    scrollHash4,
    // composables
    isVirtualShelfLayout,
    notVirtualShelfLayout,
    // computed
    showScrollHash2,
    showScrollHash3,
    showScrollHash4,
  };
};

export default {
  name: 'Index',
  components,
  setup,
};
</script>

<style lang="scss" scoped>
.eslite-home.virtual-shelf {
  $title-size: 36;
  $title-y-padding: pxToRemValue(30);
  @mixin virtualShelfTitle {
    display: inline-block;
    width: 100%;
    text-align: center;
    @include fontSize($title-size);
  }
  :deep(.section-title) {
    @include virtualShelfTitle;
    padding: $title-y-padding 0 !important; // 覆蓋 py-x
  }
  :deep(.section-title-gray) {
    @include virtualShelfTitle;
    padding: $title-y-padding 0 pxToRemValue(12) 0 !important; // 覆蓋 py-x
    background-color: #f2f2f2;
  }
  :deep(.section-title-size) {
    @include fontSize($title-size);
  }
  :deep(.section-focus-point .section-title) {
    padding-top: pxToRemValue(48) !important;
  }
}
</style>
