import { isEmpty } from 'ramda';
import { storeToRefs } from 'pinia';
import { logEvent } from '@/helper/gtm-webview';
import { isSessionStorageAvailable } from '@/helper/window-property';
import { bridgewellTrack } from '@/helper/tracking/bridgewell';
import { getGtmDataLayer } from '@/helper/gtm';
import { useLoginStore } from '@/stores/login';

export default function (context) {
  const { pluginContext } = context;
  const { $gtm } = pluginContext;
  const gtmSend = getGtmDataLayer($gtm);

  // store
  const loginStore = useLoginStore();
  const { user, loggedIn } = storeToRefs(loginStore);

  // 首次進站
  const firstInPage = window.firstInPage === true;
  // 上一頁來源
  const previous = isSessionStorageAvailable ? sessionStorage.getItem('visit/PreviousUri') || '/' : '/';
  const PreviousUriIsLogin = previous.indexOf('/login') !== -1;

  // const isRefresh = window.performance.navigation.type === 1;
  const isLogin = loggedIn.value && !isEmpty(user.value);
  const uuid = isLogin ? user.value?.uuid : '';

  if (firstInPage || PreviousUriIsLogin) {
    // 已登入且有 user uuid
    if (isLogin && !isEmpty(uuid)) {
      gtmSend.push({ userId: uuid });
      logEvent(`set`, { userId: uuid });
      // GA4
      gtmSend.push({ user_id: uuid });
      // bridgewell
      bridgewellTrack.sendInitUserInfo({ userId: uuid });

      if (typeof ga !== 'undefined') {
        // eslint-disable-next-line no-undef
        ga('set', 'userId', uuid);
      }

      delete window.firstInPage;
    } else {
      // bridgewell
      bridgewellTrack.sendInitUserInfo({ isLogin: false });
    }
  }
}
