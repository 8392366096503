import { map, sortBy, prop } from 'ramda';
import { emptyReplace } from '@/helper/data-process';
import { productFormatterBase } from '@/helper/product-formatter';
import { formatStrLimit } from '@/helper/filter/string';
import { formatBrandL2BasicField } from '@/helper/ad/part/brand-level-2-inner-formatter';

const formatSectionTitle = (title) => formatStrLimit(title, 48);

const formatBigBanner = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        image: item.image,
        alt: item.alt,
        link: item.link,
        sort: item.sort,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatBrandImage = (ad) => ({
  ...formatBrandL2BasicField(ad),
  image: emptyReplace(ad.image, ''),
  alt: emptyReplace(ad.alt, ''),
});

const formatBrandIntro = (ad) => ({
  ...formatBrandL2BasicField(ad),
  intro: emptyReplace(ad.intro, ''),
});

const formatBrandMainEvent = (ad) => ({
  ...formatBrandL2BasicField(ad),
  id: ad.id,
  title: emptyReplace(ad.title_1, ''),
  subTitle: emptyReplace(ad.title_2, ''),
  alt: emptyReplace(ad.alt, ''),
  image: emptyReplace(ad.image, ''),
  link: emptyReplace(ad.link, ''),
});

const formatBrandSmallEvent = (ad) => ({
  ...formatBrandL2BasicField(ad),
  id: ad.id,
  title: emptyReplace(ad.title, ''),
  alt: emptyReplace(ad.alt, ''),
  image: emptyReplace(ad.image, ''),
  link: emptyReplace(ad.link, ''),
});

const formatFeatureKind = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        title: item.title,
        alt: item.alt,
        image: item.image,
        link: item.link,
        sort: item.sort,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatStraightBanner = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        image: item.image,
        alt: item.alt,
        link: item.link,
        sort: item.sort,
        products: map(productFormatterBase, emptyReplace(item.products, [])),
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatRecommendProducts = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        title: formatSectionTitle(item.title || ''),
        link: item.link,
        sort: item.sort,
        products: map(productFormatterBase, emptyReplace(item.products, [])),
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatHorizontalPaint = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: map(
    (item) => ({
      id: item.id,
      alt: emptyReplace(item.alt, ''),
      image: emptyReplace(item.image, ''),
      link: emptyReplace(item.link, '#'),
    }),
    emptyReplace(ad.items, []),
  ),
});

const formatEmphasizeRecommend = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        title: formatSectionTitle(item.title || ''),
        image: item.image,
        alt: item.alt,
        link: item.link,
        sort: item.sort,
        products: map(productFormatterBase, emptyReplace(item.products, [])),
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatBrandList = (ad) => ({
  ...formatBrandL2BasicField(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        title: item.title_1,
        subTitle: item.title_2,
        image: item.image,
        alt: item.alt,
        name: item.alt,
        link: item.link,
        sort: item.sort,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

export default {
  formatBigBanner,
  formatBrandImage,
  formatBrandIntro,
  formatBrandEvent1: formatBrandMainEvent,
  formatBrandEvent2: formatBrandSmallEvent,
  formatBrandEvent3: formatBrandSmallEvent,
  formatBrandEvent4: formatBrandSmallEvent,
  formatBrandEvent5: formatBrandSmallEvent,
  formatFeatureKind,
  formatStraightBanner,
  formatRecommendProducts,
  formatHorizontalPaint,
  formatEmphasizeRecommend,
  formatBrandList,
};
