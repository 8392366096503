import { includes } from 'ramda';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCategoriesStore } from '@/stores/categories';

const brandRouteNames = ['brand', 'brand-id', 'brand-id-category'];
const categoryRouteNames = ['category-1-id', 'category-2-id', 'category-3-id'];

export function useActiveCategory() {
  const route = useRoute();
  const categoriesStore = useCategoriesStore();
  const { categoriesQueryGetter } = storeToRefs(categoriesStore);

  const isBrand = computed(() => includes(route.name, brandRouteNames));
  const isCategory = computed(() => includes(route.name, categoryRouteNames));
  const currentCategoryLevel1Id = computed(() => {
    if (!isCategory.value) return null;
    const categoryId = parseInt(route.params.id, 10);
    const category = categoriesQueryGetter.value(categoryId);
    if (!category) return null;
    return category.level1;
  });

  return {
    isBrand,
    currentCategoryLevel1Id,
  };
}
