<template>
  <div class="mobile-header-section header-item-wrapper tw-row tw-mx-0 tw-px-0 lg:tw-pr-0">
    <div class="tw-col-6 lg:tw-col-7 tw-row tw-px-0">
      <div class="tw-col-3 tw-mx-0 tw-px-0 tw-content-center tw-flex member-icon-wrapper">
        <router-link-usage
          class="span tw-items-center icon-fa-user-circle icon face-icon"
          link="/member"
          :need-check-protected-page="true"
          aria-label="會員中心"
          :class="humanIcon"
        ></router-link-usage>
      </div>
      <div class="tw-col-9 tw-mx-0 tw-px-0 tw-content-center tw-hidden lg:tw-flex tw-items-center">
        <div class="tw-row tw-mx-0 tw-px-0">
          <router-link-usage v-if="needsLogin" class="col-4-and-5 tw-ml-0 tw-px-0 tw-items-center tw-flex" link="/login" :need-check-protected-page="true">登入</router-link-usage>
          <span v-else class="local-lg-ml-0 col-4-and-5 tw-ml-0 tw-px-0">您好</span>
          <span class="local-lg-ml-0 tw-col-1 tw-ml-1 tw-px-0">|</span>
          <a v-if="needsLogin" class="local-lg-ml-0 col-4-and-5 tw-ml-1 tw-px-0 tw-items-center tw-flex" href="javascript:void(0);" data-test-id="registry-button" @click="toRegistryLink">註冊</a>
          <a v-else class="local-lg-ml-0 col-4-and-5 tw-ml-1 tw-px-0 tw-items-center tw-flex" href="javascript:void(0);" data-test-id="logout-button" @click="logout">登出</a>
          <div class="tw-col-12 tw-ml-0 tw-px-0 member-menu tw-pb-2">
            <router-link-usage link="/member" :need-check-protected-page="true" class="tw-inline">會員中心<span class="tw-ml-1 tw-inline icon-fa-angle-down"></span></router-link-usage>
            <div class="member-option sans-font-semi-bold">
              <ul>
                <router-link-usage link="/member/orders" :need-check-protected-page="true" class="!tw-flex tw-justify-center tw-items-center">
                  <div class="icon-order-solid tw-w-1/4"></div>
                  <li class="tw-w-1/2 tw-text-left">我的訂單</li>
                </router-link-usage>
                <router-link-usage link="/member/coupons" :need-check-protected-page="true" class="!tw-flex tw-justify-center tw-items-center">
                  <div class="icon-coupon tw-w-1/4"></div>
                  <li class="tw-w-1/2 tw-text-left">優惠券</li>
                </router-link-usage>
                <router-link-usage link="/member/wish" :need-check-protected-page="true" class="!tw-flex tw-justify-center tw-items-center">
                  <div class="icon-heart tw-w-1/4"></div>
                  <li class="tw-w-1/2 tw-text-left">收藏商品</li>
                </router-link-usage>
                <router-link-usage link="/member/points" :need-check-protected-page="true" class="!tw-flex tw-justify-center tw-items-center">
                  <div class="icon-eslite tw-w-1/4"></div>
                  <li class="tw-w-1/2 tw-text-left">誠品點</li>
                </router-link-usage>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-col-6 lg:tw-col-5 tw-row tw-px-0 tw-flex tw-mr-1 tw-items-center">
      <router-link-usage class="tw-px-0" link="/cart/step1" :need-check-protected-page="true" aria-label="購物車">
        <span class="shopping-cart-link tw-flex tw-items-center">
          <shopping-cart class="shopping-cart-lottie" :count="cartQuantity"></shopping-cart>
          <span class="shopping-cart-wording lg:tw-ml-1 tw-hidden lg:tw-inline-block">購物車</span>
        </span>
      </router-link-usage>
    </div>
    <single-button-modal v-if="!$isEmpty(logoutSuccessMessage)" @close="clearLogoutMessage">
      <template #body>
        <span class="sans-font">{{ logoutSuccessMessage }}</span>
      </template>
    </single-button-modal>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import SingleButtonModal from '@/components/utility/modal/single-button-modal.vue';
import ShoppingCart from '@/components/layout/elements/shopping-cart.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { useLogout } from '@/composables/member/logout';
import { isBetween, redirectPage } from '@/helper/system-maintenance';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';

// 非白色human-icon
const filterShopIcon = ['exhibition', 'market'];

function setup() {
  const router = useRouter();
  const route = useRoute();
  const auths = inject('$auths');
  const { logout, logoutSuccessMessage, clearLogoutMessage } = useLogout({ auths, route, router });

  // store
  const cartItemsStore = useCartItemsStore();
  const { initCartQuantity, clearQuantity } = cartItemsStore;
  const { getTotalOfCartItems, getQuantity } = storeToRefs(cartItemsStore);
  const loginStore = useLoginStore();

  const totalOfCartItems = computed(() => getTotalOfCartItems.value);
  const storeCartQuantity = computed(() => getQuantity.value);
  const isLogin = computed(() => loginStore.loggedIn);

  const needsLogin = computed(() => !isLogin.value);

  // computed
  const humanIcon = computed(() => {
    const filterLayout = filterShopIcon.includes(route.meta?.layout);
    if (filterLayout) return 'face-icon-black';
    return 'face-icon-white';
  });
  const cartQuantity = computed(() => {
    // 未登入一律拿 local cart
    if (!isLogin.value) return totalOfCartItems.value;
    return storeCartQuantity.value;
  });

  const toRegistryLink = () => {
    // 註冊頁網址
    window.location.href = isBetween() ? redirectPage : `${import.meta.env.VITE_API}${import.meta.env.VITE_API_REGISTRY}`;
  };

  onMounted(() => {
    initCartQuantity();
  });

  onUnmounted(() => {
    clearQuantity();
  });

  return {
    // composables
    logout,
    logoutSuccessMessage,
    clearLogoutMessage,

    // computed
    humanIcon,
    needsLogin,
    cartQuantity,

    // methods
    toRegistryLink,
  };
}

export default {
  name: 'LoginHeader',
  components: { SingleButtonModal, ShoppingCart, RouterLinkUsage },
  setup,
};
</script>

<style lang="scss" scoped>
.header-item-wrapper {
  font-size: 0.85rem;
  display: flex;
  justify-content: space-around;

  a {
    @include hoverLink(var(--d-gray-3), var(--sepia));
  }

  .face-icon {
    @include center(v);
    color: var(--e-dark-gold);
  }
}

.member-icon-wrapper {
  position: relative;
}

.member-menu {
  position: relative;
  .member-option {
    display: none;
    @include font16;
  }
  &:hover {
    a {
      color: var(--e-light-gold);
    }
    .member-option {
      display: block;
      position: absolute;
      left: calc(25% + 5px);
      transform: translateX(-50%);
      top: 100%;
      z-index: 2;
      background-color: var(--white);
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      ul {
        margin: 0;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px var(--l-gray-e);
        border-radius: 5px;
        &::before {
          content: '';
          position: absolute;
          z-index: 10;
          top: -5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 7px 10px;
          border-color: transparent transparent var(--white) transparent;
        }
        &::after {
          content: '';
          position: absolute;
          z-index: 9;
          top: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 8px 10px;
          border-color: transparent transparent var(--l-gray-e) transparent;
        }
        a {
          display: block;
          width: 100%;
          padding: 10px 0;
          color: var(--black);
          border-bottom: solid 1px var(--l-gray-e);
          text-align: center;
          &:last-child {
            border: none;
            border-radius: 0 0 5px 5px;
          }
          &:hover {
            color: var(--eslite-red);
          }
        }
      }
      li {
        @include font14;
      }
    }
  }
}

.shopping-cart-link {
  width: fit-content;
  &:hover {
    .shopping-cart-wording {
      text-decoration: none;
      color: var(--sepia);
    }
  }

  .shopping-cart-wording {
    font-size: 0.85rem;
    line-height: 1;
    font-weight: normal;
    color: var(--d-gray-3);
    white-space: nowrap;
  }
}

.icon {
  font-size: 1.5rem;
  color: var(--e-dark-gold);
}

.col-4-and-5 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.shopping-cart-lottie {
  transform: translateY(-22%);
}

.shopping-cart-icon {
  position: relative;
}

.badge-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  @include mediaRange($grid-breakpoints-lg, $grid-breakpoints-xl) {
    transform: translate(50%, -60%) scale(0.9);
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--rouge);
  border: solid 1px var(--white);
  @include pxToRem(font-size, 12);
}

@include mediaIs($grid-breakpoints-lg) {
  .col-4-and-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .header-item-wrapper {
    height: 3rem;
    font-size: 0.7rem;
    .local-lg-ml-0 {
      margin-left: 0 !important;
    }
  }
  .icon {
    font-size: 1.25rem;
  }
  .shopping-cart-lottie {
    width: 40px;
  }
}

@include mediaMin($grid-breakpoints-xl) {
  .shopping-cart-lottie {
    width: 45px;
  }
}

@include mediaMax($grid-breakpoints-lg) {
  .shopping-cart-lottie {
    transform: translateY(-20%);
    width: 48px;
    padding-top: 0;
  }
  .header-item-wrapper {
    .icon {
      font-size: 1.5rem;
      padding-top: 2px;
    }

    .face-icon.face-icon-white {
      color: var(--white);
    }
    .face-icon.face-icon-black {
      color: var(--e-dark-gold);
    }
  }
}
</style>
