import { join, pipe, reduce, splitAt, toUpper } from 'ramda';

export const firstWordToUpper = pipe(
  splitAt(1),
  reduce((a, b) => join('', [toUpper(a), b]), ''),
);

/**
 * json object 轉 query string
 * @param {Object} initialObj json object
 * @returns {string} url query string
 */
export const objectToQueryString = (initialObj) => {
  const reducer =
    (obj, parentPrefix = null) =>
    (prev, key) => {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (['number', 'boolean', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
};

/**
 * 判斷字串是否為 json string
 * @param {string} str json string
 * @returns {booleean}
 */
export const isJSON = (str = '') => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};
