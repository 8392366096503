import { useCartStore } from '@/stores/cart';

export default function (step) {
  const cartStore = useCartStore();
  if (step !== undefined) {
    cartStore.setStep(step);
  }

  return cartStore.step;
}
