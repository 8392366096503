/**
 * 由帶入參數來判斷超商類型
 */

/**
 * isShoppingSevenStore 判斷是否為 7-11 超商門市的回傳值
 * @param {object} params 7-11 超商門市串接回傳值
 */
export function isShoppingSevenStore(params) {
  try {
    const { Outside, storeid, storename, tempvar } = params;
    return !!(Outside && storeid && storename && tempvar);
  } catch (error) {
    return false;
  }
}

/**
 * isCvsStore 判斷是否為 網路便利通 (OK, 萊爾富, 全家 超商門市) 的回傳值
 * @param {object} params 網路便利通 (OK, 萊爾富, 全家 超商門市) 串接回傳值
 */
export function isCvsStore(params) {
  try {
    const { cvsspot, name, addr, tel, cvstemp } = params;
    return !!(cvsspot && name && addr && tel && cvstemp);
  } catch (error) {
    return false;
  }
}

/**
 * isCvsStore 判斷是否為 日翊文化 (全家超商門市) 的回傳值
 * @param {object} params 日翊文化 (全家超商門市) 串接回傳值
 * 與達康參數一樣所以 cvstemp=family 做區分
 */
export function isFamilyMart(params) {
  try {
    const { cvsspot, name, addr, tel, cvsname, cvsnum, cvstemp } = params;
    return !!(cvsspot && name && addr && tel && cvsname && cvsnum && cvstemp === 'family');
  } catch (error) {
    return false;
  }
}

/**
 * isEwebsStore 判斷是否為 提貨通 (香港OK超商)
 * @param {object} params 提貨通 (香港OK超商) 串接回傳值
 */
export function isEwebsStore(params) {
  try {
    const { stno, st_name, st_addr, st_tel, country, csno, cs_name, keep_data } = params;
    return !!(stno && st_name && st_addr && st_tel && country && csno && cs_name && keep_data);
  } catch (error) {
    return false;
  }
}

/**
 * 判斷超商串接類型
 * @param {object} params 電子地圖串接回傳值
 * @return {string} 串接超商類型
 * shopping7: 7-11 超商
 * family: 日翊文化 (全家超商門市)
 * cvs: 網路便利通 (OK, 萊爾富, 全家 超商門市)
 * 空值: 查無或參數有誤
 */
export function getStoreType(params) {
  if (isShoppingSevenStore(params)) return 'shopping7';
  if (isFamilyMart(params)) return 'family';
  if (isCvsStore(params)) return 'cvs';
  if (isEwebsStore(params)) return 'ewebs';

  return '';
}
