/* eslint-disable class-methods-use-this */
import CryptoUtility from './CryptoUtility.js';

// crypto: ie 11 部分支援, 其他常用的都支援 https://caniuse.com/?search=crypto
const canNotUseCrypto = () => typeof crypto === 'undefined';

/**
 * @class {class} SecureLocalStorage localStorage 加解密工具
 *
 * @example:
 * const secureLocalStorage = new SecureLocalStorage('你的加密金鑰');
 * secureLocalStorage.setItem('testKey', 'testValue').then((res) => console.log(`加密並存儲: ${res ? '成功' : '不支援'}`)).cache((e) => console.log(e));
 * secureLocalStorage.getItem('testKey').then((res) => console.log(`解密並讀取成功: ${res}`));
 * secureLocalStorage.removeItem('testKey');
 */
export default class SecureLocalStorage {
  constructor(secret) {
    this.cryptoUtility = new CryptoUtility(secret);
  }

  /**
   * 將指定的 key-value pair 寫入安全的本地儲存 (localStorage)
   * 如果無法寫入會拋出 error
   * @param {string} key - 設定 key
   * @param {string} value - 儲存值
   * @return {boolean} 能夠寫入並成功為 true, 否則 false
   * @throws {Error} 寫入失敗。瀏覽器不支援或是沒有權限無法寫入
   */
  setItem(key, value) {
    const encryptedValue = this.cryptoUtility.encrypt(value);

    // 如果瀏覽器支援 window.crypto 物件才能寫入 localStorage
    if (!canNotUseCrypto()) {
      try {
        localStorage.setItem(key, encryptedValue);
        return true;
      } catch (e) {
        throw new Error(`寫入失敗。瀏覽器不支援或是沒有權限無法寫入。`);
      }
    }

    // 瀏覽器不支援
    return false;
  }

  /**
   * 從 localStorage 取回 key 對應值
   * 如果此 key 沒有找到則回傳 null
   * @param {string} key key
   * @return {string | null} 對應值 or null
   */
  getItem(key) {
    // 不支援 crypto 的使用者回傳空值
    if (canNotUseCrypto()) return '';

    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;
    const decryptedValue = this.cryptoUtility.decrypt(encryptedValue);
    return decryptedValue;
  }

  /**
   * 移除 localStorage 中指定的 key-value pair
   * @param {string} key key
   */
  removeItem(key) {
    localStorage.removeItem(key);
  }
}
