import { useRoute } from 'vue-router';
import { scrollToHash } from '@/helper/scroll';
import { scrollBehaviorOffsetY } from '@/constant/scroll';
import { isEmptyValue } from '@/helper/data-process';

export function useScrollTo() {
  const route = useRoute();
  const scrollToRouteHash = () => scrollToHash(route.hash);
  // retry scroll，避免hash前一個區塊較慢loading，往下展開，產生位置誤差
  const scrollToRouteHashWithRetry = (maxCount = 1) => {
    if (maxCount < 1) return;
    if (isEmptyValue(route.hash)) return;
    const anchorElement = document.querySelector(route.hash);
    if (!anchorElement) return;
    const position = anchorElement.getBoundingClientRect();
    // 已經在定位，不須 retry scroll to hash
    if (position.top > -10 && position.top < scrollBehaviorOffsetY + 10) {
      // final
      setTimeout(scrollToRouteHash, 500);
      return;
    }
    scrollToRouteHash();
    // timeout 勿小於 300ms，有時誤差元件在 300ms 之後才 loaded
    setTimeout(() => scrollToRouteHashWithRetry(maxCount - 1), 500);
  };

  return {
    scrollToHash,
    scrollToRouteHash,
    scrollToRouteHashWithRetry,
  };
}
