/**
 * location: zh_CN
 * @const {object} vee-validate error message
 */
export default {
  code: 'zh_CN',
  messages: {
    alpha: '{field} 只能包含字母字符。',
    alpha_dash: '{field} 能够包含字母数字字符、破折号和下划线。',
    alpha_num: '{field} 只能包含字母数字字符。',
    alpha_spaces: '{field} 只能包含字母字符和空格。',
    between: '{field} 必须在 0:{min} 与 1:{max} 之间。',
    confirmed: '{field} 不能和0:{target}匹配。',
    digits: '{field} 必须是数字，且精确到0:{length}位数。',
    dimensions: '{field} 必须在 0:{width} 像素与 1:{height} 像素之间。',
    email: '{field} 不是一个有效的邮箱。',
    excluded: '{field} 不是一个有效值。',
    ext: '{field} 不是一个有效的文件。',
    image: '{field} 不是一张有效的图片。',
    one_of: '{field} 不是一个有效值。',
    integer: '{field} 必须是整数。',
    length: '{field} 长度必须为 0:{length}。',
    max: '{field} 不能超过 0:{length} 个字符。',
    max_value: '{field} 必须小于或等于 0:{max}。',
    mimes: '{field} 不是一个有效的文件类型。',
    min: '{field} 必须至少有0:{length}个字符。',
    min_value: '{field} 必须大于或等于 0:{min}。',
    numeric: '{field} 只能包含数字字符。',
    regex: '{field} 格式无效。',
    required_if: '{field} 是必须的。',
    size: '{field} 必须小于 0:{size}KB。',

    /**
     * custom
     */
    required: '{field} 是必须的。',
    required_en: '{field} 請輸入英文。',
    address_en: '{field} 請輸入英文。',
    address_tw: '{field} 輸入不完整。',
    minmax: '{field} 必须在 0:{min} 与 1:{max} 之间。',
    invoiceMobileDevice: '{field} 格式有誤。',
    invoiceIDDevice: '{field} 格式有誤。',
    phone: '{field} 格式有誤。',
    phoneTW: '{field} 格式有誤。',
    phoneHK: '{field} 格式有誤。',
    telePhoneArea: '{field} 格式有誤。',
    telePhoneNumber: '{field} 格式有誤。',
    telePhoneExt: '{field} 格式有誤。',
    is_not: '{field} 欄位必填。',
    password: '{field} 需为 6 至 24 码半形英数字，且不可含特殊符号。',
    confirm: '{field} 需与新密码相同。',
  },
};
