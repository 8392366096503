import { defineStore } from 'pinia';

export const useCustomerServiceStore = defineStore('customerService', {
  state: () => ({
    // 是否載入過
    customerStatus: false,
    customerSwitch: false,
  }),
  getters: {
    getCustomerStatus(context) {
      return context.customerStatus;
    },
    getCustomerSwitch(context) {
      return context.customerSwitch;
    },
  },
  actions: {
    setCustomerStatus(isStatusOn) {
      this.$patch({
        customerStatus: isStatusOn,
      });
      this.customerStatus = isStatusOn;
    },
    setCustomerSwitch(isSwitchOn) {
      this.$patch({
        customerSwitch: isSwitchOn,
      });
      if (!this.customerStatus) {
        this.$patch({
          customerStatus: isSwitchOn,
        });
      }
    },
    toggleCustomerSwitch() {
      this.setCustomerSwitch(!this.customerSwitch);
    },
  },
});
