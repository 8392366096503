const forbidPages = ['/member', '/member/account', '/member/newsletter'];
const forbidPageRegex = [/^\/member\/orders/, /^\/member\/profile/, /^\/member\/customer/];

export function isTagtooAllowed(path) {
  const found = forbidPages.find((page) => page === path);
  if (found) return false;
  const foundRegex = forbidPageRegex.find((pageRegex) => pageRegex.test(path));
  if (foundRegex) return false;
  return true;
}
