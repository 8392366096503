import { includes } from 'ramda';
import { redirectPage, isAllRedirect, isBetween } from '@/helper/system-maintenance';

export default function ({ redirect, route }) {
  // 是否全站轉跳
  if (isAllRedirect) redirect(redirectPage);

  // 部分頁面轉跳時需要轉跳的畫面（使用 route name 是因為商品頁訂單退貨頁之類的頁面會有編號）
  const pages = ['member-profile', 'member-profile-modify-profile', 'member-profile-modify-password', 'member-orders-page', 'member-order-return-id', 'member-coupons', 'member-points'];

  if (includes(route.name, pages) && isBetween()) redirect(redirectPage);
}
