import CryptoUtility from '@/services/CryptoUtility.js';

export default async ({ pluginContext, app }) => {
  const y = `*Y-03`;
  const c = `te-202`;
  const a = `esli`;
  const e = `3-K*E`;
  const cryptoKey = `${a}${c}${e}${y}`;
  app.config.globalProperties.$cryptoUtility = new CryptoUtility(cryptoKey);
  pluginContext.$cryptoUtility = app.config.globalProperties.$cryptoUtility;
};
