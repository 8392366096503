import { includes } from 'ramda';
import { pageRegexRules, parsePath } from '@/helper/category-page-regex';
import { useCategoriesStore } from '@/stores/categories';

// 可進入的 page name
const roleList = ['category-1-id', 'category-2-id', 'category-3-id'];

export default function ({ route }) {
  const categoriesStore = useCategoriesStore();
  const { setHistory } = categoriesStore;
  // 只在館別頁做紀錄
  if (!includes(route.name, roleList)) return setHistory({ level: 0, id: '' });
  const { testRegex, replaceRegex } = pageRegexRules.category;

  const path = parsePath({ path: route.path, testRegex, replaceRegex });
  return setHistory(path);
}
