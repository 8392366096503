/**
 * check image exist
 * @param url
 */
import { imageIsExist } from '@/helper/image';

// const imageIsExist = function (url) {
//   return new Promise((resolve) => {
//     const image = new Image();
//     image.onload = function () {
//       resolve(true);
//     };
//     image.onerror = function () {
//       resolve(false);
//     };
//     image.src = url;
//   });
// };

export default ({ app }) => {
  // registry Directive
  app.directive('default-image', async function (element, binding) {
    const imageUrl = element.src;
    if (!imageUrl) return;
    const exist = await imageIsExist(imageUrl);
    if (!exist) element.setAttribute('src', binding.value);
  });
};
