import { defineStore } from 'pinia';

const state = () => ({
  // loading 遮罩
  loadingStatus: false,
  // wait paths 正在等待的路徑
  waitPaths: [],
});

const getters = {
  getLoadingStatus(state) {
    return state.loadingStatus;
  },
  /**
   * @function getWaitStatus
   * @description 判斷 wait 狀態: waitPaths 空時不等待 (false)
   * @return {boolean} waitPaths array 大於 0 ?
   * */
  getWaitStatus(state) {
    return state.waitPaths.length > 0;
  },
  /**
   * @function getWaitPaths
   * @description 取得目前的 wait 路徑 array
   * @return {array} waitPaths
   */
  getWaitPaths(state) {
    return state.waitPaths;
  },
};

const actions = {
  turnOnLoading(data) {
    this.loadingStatus = data;
  },
  /**
   * @function addWaitPath
   * @description 新增需要等待的 api 路徑
   * */
  addWaitPath(data) {
    if (!this.waitPaths.includes(data)) this.waitPaths.push(data);
  },
  /**
   * @function subWaitPath
   * @description 移除需要等待的 api 路徑
   * */
  subWaitPath(data) {
    this.waitPaths = this.waitPaths.filter((item) => item !== data);
  },
  clearPaths() {
    this.waitPaths = [];
  },
};

export const useLoadingStore = defineStore('loading', {
  state,
  getters,
  actions,
});
