import { join, invertObj, map, forEach, filter, includes, prop } from 'ramda';
import { defineStore } from 'pinia';
import fetchAdService from '@/api/ad/fetch-ad-service';
import CartAdFormatter from '@/services/ad/cart-ad-formatter';
import { cartAdPositionsEnums } from '@/constant/ad/cart-ad-type';
import { firstWordToUpper } from '@/helper/string';

const state = () => ({
  displayPositions: [cartAdPositionsEnums.step1PromoEvent, cartAdPositionsEnums.step3Horizontal],
  step1PromoEvent: {
    type: '',
    blank: false,
    items: [],
  },
  step3Horizontal: {
    type: '',
    blank: '',
    alt: '',
    image: '',
    link: '',
  },
});

const invertCartAdPositions = invertObj(cartAdPositionsEnums);
const mutationName = (type) => `set${firstWordToUpper(invertCartAdPositions[type])}`;
const filterExisted =
  (state) =>
  ({ data }) =>
    filter((ad) => includes(prop('banner_type', ad), state.displayPositions), data);

const groupAd = map((ad) => ({
  name: mutationName(prop('banner_type', ad)),
  data: ad,
}));
const commitAd = (commit) => (data) => forEach((ad) => commit[ad.name](ad.data))(data);

const actions = {
  fetchCartAd() {
    fetchAdService.fetchCartAd(join(',', this.displayPositions)).then(filterExisted(this)).then(groupAd).then(commitAd(this));
  },
  setStep1PromoEvent(ad) {
    this.step1PromoEvent = CartAdFormatter.step1PromoEvent(ad);
  },
  setStep3Horizontal(ad) {
    this.step3Horizontal = CartAdFormatter.step3Horizontal(ad);
  },
};

export const useCartAdStore = defineStore('cartAd', {
  state,
  actions,
});
