import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_MEMBER_ADDRESS_BOOK}`;

export default (token) =>
  axios({
    method: 'get',
    headers: { Authorization: token },
    url: API_PATH,
  });
