<template>
  <component :is="errorPage" :error="error" />
</template>

<script>
import { toRefs, computed } from 'vue';
import error404 from '@/components/error/404.vue';
import error500 from '@/components/error/500.vue';
import { layoutErrorStatusEnum } from '@/router/layout';

export default {
  name: 'ErrorLayout',
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { error } = toRefs(props);
    const statusCode = computed(() => error.value?.status || layoutErrorStatusEnum.error500);
    const errorPage = computed(() => {
      console.group(`StatusCode: ${statusCode.value}`);
      console.log(error.value.message);
      console.groupEnd();
      if (statusCode.value === layoutErrorStatusEnum.error404) {
        return error404;
      }

      return error500;
    });

    return {
      errorPage,
    };
  },
};
</script>
