const data = {
  dev: {
    header: {
      // 誠品LOGO
      logo: 'eslite_logo',
      // 購物車進度條
      step1: 'cart-stp1',
      step2: 'cart-stp2',
      step3: 'cart-stp3',
    },
    // 登入頁
    qaLogin: {
      // 登入筐
      login: 'qa-login',
      // 廣告
      banner: 'qa-banner',
    },
    // 首頁
    qaIndex: {
      // 好康優惠專區
      discountAreaBannerLeft1: 'qa-index-discount-area-banner-1',
      discountAreaBannerLeft2: 'qa-index-discount-area-banner-3',
      discountAreaBannerRight1: 'qa-index-discount-area-banner-2',
      discountAreaBannerRight2: 'qa-index-discount-area-banner-4',
    },
    // 結帳
    qaCheckout: {
      subtotalPrice: 'subtotal_price',
      promotionDiscount: 'promotion_discount',
      couponTicketPrice: 'coupon_ticket_price',
      totalPrice: 'total_price',
      checkoutBtn: 'checkout_btn',
    },
    // 使用優惠券
    qaCoupon: {
      // 使用優惠券按鈕
      couponBtn: 'coupon_btn',
      // 您有多少張優惠券符合本次訂單可使用條件 張數
      couponTickets: 'coupon_tickets',
      // 優惠券名稱
      couponName: 'coupon_name',
      // 優惠券使用說明
      couponDis: 'coupon_dis',
      // 優惠券使用期限
      couponExp: 'coupon_exp',
      // 優惠券現抵金額
      couponDiscountPrice: 'coupon_discount_price',
      // 已使用優惠券Tag
      couponUsedTag: 'coupon_used_tag',
    },
    // 產品頁
    qaProduct: {
      // 加價購滿多少金額價格
      fullAmountPriceRule: 'full_amount_price_rule',
      // 商品標題(熱門商品 省下多少)
      hotProductSave: 'hot_product_save',
      // 商品圖片
      fullAmountImage: 'full_amount_image',
      // 商品名稱
      fullAmountName: 'full_amount_name',
      // 商品價加價購金額
      fullAmountPrice: 'full_amount_price',
      // 數量
      fullAmountItems: 'full_amount_items',
      // 加購按鈕
      fullAmountAddBtn: 'full_amount_add_btn',
      // 已加入購物車按鈕
      addedCart: 'added_cart',

      // 購物車Step1廣告
      step1Ad: 'step1_ad',
      // 優惠活動總折抵金額
      totalDiscountPrice: 'total_discount_price',
      // 購物車促案折抵金額
      discountPrice: 'discount_price',
      // 購物車促案名稱
      promotionName: 'promotion_name',
      // 優惠活動
      promotionEvent: 'promotion_event',

      // 購物車促案贈品(活動贈品Tag 活動贈品名稱 活動贈品數量 已贈畢)
      itemsPromotionDone: 'items_promotion_done',
      // 訂製商品(訂製Tag 訂製說明文字)
      customizedProduct: 'customized_product',
      // 預購商品(預購Tag 預購說明文字)
      preOrderItems: 'pre-order_items',
      // 商品贈品(加贈Tag 商品贈品名稱)
      commodityGiveaway: 'commodity_giveaway',
      // 商品符合的購物車促案
      cartPromotion: 'cart_promotion',
      // 加入收藏
      addToFavorites: 'add_to_favorites',
      // 刪除商品
      deleteProduct: 'delete_product',
      // 商品總計
      totalItems: 'total_items',
      // 商品數量
      itemsAmount: 'items_amount',
      // 商品優惠價
      specialPrice: 'special_price',
      // 商品名稱
      productName: 'product_name',
      // 商品圖片
      productImage: 'product_image',
      // 商品總計金額
      productTotalPrice: 'product_total_price',

      // breadcrumb
      breadcrumb: 'qa-breadcrumb',
      // 側邊 sidebar
      sidebar: 'qa-sidebar',
      secondTitle: 'qa-second-title',
      title: 'qa-title',
      subTitle: 'qa-sub-title',
      originalName: 'qa-original-name',
      // 商品資訊
      info: 'qa-info',
      // 數量
      qty: 'qa-qty',
      // price
      price: 'qa-price',
      // 付款與配送方式
      types: 'qa-payment-and-delivery-types-box',
      // gifts
      gifts: 'qa-gifts',
      // 圖片組
      images: 'qa-images',
      // 策展
      exhibition: 'qa-exhibition',
      // 橫幅 banner
      banner: 'qa-banner',
      // 內容 (example: data-type="內容簡介")

      // 作者相關書籍
      author: 'qa-author-books',
      // history
      history: 'qa-history',
    },
    qaCartStep1: {
      // 商品列表
      list: 'qa-list',
      // 優惠活動
      cartDiscount: 'qa-cart-discount',
      // 優惠券
      otherDiscount: 'qa-other-discount',
      // 加價購
      additionalList: 'qa-additional-list',
      // 各個優惠內 input (example: data-name="優惠名稱")
      // summaryBar
      summaryBar: 'qa-summary-bar',
    },
    qaCartStep2: {
      // eslite pay modal
      eslitePayModal: 'qa-eslitepay-modal',
    },
    qaCartStep3: {
      success: 'qa-success',
      fail: 'qa-fail',
      atm: 'qa-atm',
      // banner: 'qa-banner',
      // payments: 'qa-payments',
    },
    orderList: {
      // 各個訂單狀態
      orderInit: 'order-status-init',
      orderFailed: 'order-status-failed',
      orderPaid: 'order-status-paid',
      orderPacking: 'order-status-packing',
      orderShipped: 'order-status-shipped',
      orderArrived: 'order-status-arrived',
      orderFinished: 'order-status-finished',
      orderCanceled: 'order-status-canceled',
      orderSplitCanceled: 'order-status-split-canceled',
    },
  },
  prd: {
    header: {
      // 誠品LOGO
      logo: '',
      // 購物車進度條
      step1: '',
      step2: '',
      step3: '',
    },
    // 結帳
    qaCheckout: {
      subtotalPrice: '',
      promotionDiscount: '',
      couponTicketPrice: '',
      totalPrice: '',
      checkoutBtn: '',
    },
    // 使用優惠券
    qaCoupon: {
      // 使用優惠券按鈕
      couponBtn: '',
      // 您有多少張優惠券符合本次訂單可使用條件 張數
      couponTickets: '',
      // 優惠券名稱
      couponName: '',
      // 優惠券使用說明
      couponDis: '',
      // 優惠券使用期限
      couponExp: '',
      // 優惠券現抵金額
      couponDiscountPrice: '',
      // 已使用優惠券Tag
      couponUsedTag: '',
    },
    qaLogin: {
      login: '',
      banner: '',
    },
    qaIndex: {
      discountAreaBannerLeft1: '',
      discountAreaBannerLeft2: '',
      discountAreaBannerRight1: '',
      discountAreaBannerRight2: '',
    },
    qaProduct: {
      // 加價購滿多少金額價格
      fullAmountPriceRule: '',
      // 商品標題(熱門商品 省下多少)
      hotProductSave: '',
      // 商品圖片
      fullAmountImage: '',
      // 商品名稱
      fullAmountName: '',
      // 商品價加價購金額
      fullAmountPrice: '',
      // 數量
      fullAmountItems: '',
      // 加購按鈕
      fullAmountAddBtn: '',
      // 已加入購物車按鈕
      addedCart: '',
      // 購物車Step1廣告
      step1Ad: '',
      // 優惠活動總折抵金額
      totalDiscountPrice: '',
      // 購物車促案折抵金額
      discountPrice: '',
      // 購物車促案名稱
      promotionName: '',
      // 優惠活動
      promotionEvent: '',
      // 購物車促案贈品(活動贈品Tag 活動贈品名稱 活動贈品數量 已贈畢)
      itemsPromotionDone: '',
      // 訂製商品(訂製Tag 訂製說明文字)
      customizedProduct: '',
      // 預購商品(預購Tag 預購說明文字)
      preOrderItems: '',
      // 商品贈品(加贈Tag 商品贈品名稱)
      commodityGiveaway: '',
      // 商品符合的購物車促案
      cartPromotion: '',
      // 加入收藏
      addToFavorites: '',
      // 刪除商品
      deleteProduct: '',
      // 商品總計
      totalItems: '',
      // 商品數量
      itemsAmount: '',
      // 商品優惠價
      specialPrice: '',
      // 商品名稱
      productName: '',
      // 商品圖片
      productImage: '',
      // 商品總計金額
      productTotalPrice: '',
      breadcrumb: '',
      sidebar: '',
      secondTitle: '',
      title: '',
      subTitle: '',
      originalName: '',
      info: '',
      qty: '',
      price: '',
      types: '',
      gifts: '',
      images: '',
      exhibition: '',
      banner: '',
      author: '',
      history: '',
    },
    qaCartStep1: {
      list: '',
      cartDiscount: '',
      otherDiscount: '',
      additionalList: '',
      summaryBar: '',
    },
    qaCartStep2: {
      eslitePayModal: '',
    },
    qaCartStep3: {
      success: '',
      fail: '',
      atm: '',
      banner: '',
    },
    orderList: {
      // 各個訂單狀態
      orderInit: '',
      orderFailed: '',
      orderPaid: '',
      orderPacking: '',
      orderShipped: '',
      orderArrived: '',
      orderFinished: '',
      orderCanceled: '',
      orderSplitCanceled: '',
    },
  },
};

const pages = { ...data[import.meta.env.VITE_TYPE !== 'prd' ? 'dev' : 'prd'] };

export const { qaLogin, qaIndex, qaProduct, qaCartStep1, qaCartStep2, qaCartStep3, orderList, header, qaCoupon, qaCheckout } = pages;
