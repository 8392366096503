<template>
  <div v-if="$screen.isDesktopSize" class="tw-flex fullsite-keyword-desktop">
    <div v-for="(row, index) in visibleKeywords" :key="`keyword-${index}`" class="tw-flex keyword-block">
      <div class="keyword">
        <a :id="getGtmId(index)" :href="row.link">{{ row.text }}</a>
      </div>
    </div>
  </div>
  <div v-else class="fullsite-keyword-mobile">
    <div class="right-blur"></div>
    <div class="keyword-list">
      <div v-for="(row, index) in visibleKeywords" :key="`keyword-${index}`" class="keyword-block">
        <a :id="getGtmId(index)" :href="row.link">{{ row.text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { take } from 'ramda';
import { storeToRefs } from 'pinia';
import { useGlobalAdStore } from '@/stores/global-ad';
import { incrementItem } from '@/helper/tracking/gtm-id';

export default {
  name: 'FullSiteKeyword',
  setup() {
    const globalAdStore = useGlobalAdStore();
    const { getKeywords: keywords } = storeToRefs(globalAdStore);
    // computed
    const visibleKeywords = computed(() => take(5, keywords.value));
    // methods
    function getGtmId(index) {
      return `header-keyword-${incrementItem(index)}`;
    }
    return {
      keywords,
      visibleKeywords,
      getGtmId,
    };
  },
};
</script>

<style lang="scss" scoped>
// 畫面寬 991-1279px 時最後一個會掉到第二行破壞高度 (隱藏)
.fullsite-keyword-desktop {
  overflow: hidden;
  line-height: 1.25rem;
  height: 1.25rem;
  flex-wrap: wrap;
}

.keyword-block + .keyword-block .keyword:before {
  content: '|';
  padding: 0 4px;
}

.keyword-block {
  width: fit-content;
}

.keyword-block a {
  @include hoverLink(var(--d-gray-3), var(--e-light-gold));
  @include fontSize(13);
  @include pxToRem(letter-spacing, 0.6);
  white-space: nowrap;
}

@include mediaMax($grid-breakpoints-lg) {
  .fullsite-keyword-mobile {
    position: relative;
  }
  .right-blur {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.25rem;
    background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0) 100%);
  }
  .keyword-list {
    height: 2em;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .keyword-block {
      flex: 0 0 auto;
    }
  }
  .keyword-block a {
    display: block; // for text vertical center
    color: var(--e-mid-gold);
  }
  .keyword-block + .keyword-block a:before {
    content: '';
    display: inline-block;
    @include pxToRem(width, 1);
    height: 0.75rem;
    padding: 1px; // padding 0 + width 1px => 在 firefox 第一個會消失
    margin: 0 0.75rem;
    transform: translateY(10%);
    background-color: var(--very-light-pink-two);
  }
  .keyword-block:last-of-type {
    margin-right: 0.75rem;
  }
}
</style>
