export const memberStatus = {
  unverified: 'unverified', // 新會員資料，未驗證 (401)
  incomplete: 'incomplete', // 舊會員資料，資料不完全 (401)
  unverifiedPhone: 'phone_unverified', // 舊會員資料，台灣手機未驗證 (200)
  normal: 'normal', // 各項皆合格的會員 (200)
  unverifiedPhoneToSignIn: 'unverified_phone_to_sign_in', // (401) 會員電話存在，提示會員驗證，驗證後可使用手機登入
};
export const redirectBackendUrl = {
  [memberStatus.unverified]: `${import.meta.env.VITE_API}${import.meta.env.VITE_API_UNVERIFIED}`,
  [memberStatus.incomplete]: `${import.meta.env.VITE_API}${import.meta.env.VITE_API_INCOMPLETE}`,
};

export const loginType = {
  passwordInvalid: 'invalid', // 密碼錯誤 (401)
  noAccount: 'not_found_in_database', // 無此帳號 (401)
  locked: 'locked', // 被鎖住 (401)
  system: 'system', // 系統忙碌中，請稍候再試 (any)
};

// 會員畫面狀態
export const memberStatusEnum = {
  Loading: 'Loading', // loading
  Empty: 'Empty', // 空資料
  Unlogged: 'Unlogged', // 未登入
  Error: 'Error', // api 錯誤
};
