export default ({ app }) => {
  app.directive('click-outside', {
    mounted(el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  });
};
// 不使用 (@focusout tabindex="0")，因為點選時 safari 會加框
// Vue.directive('click-outside', {
//   bind(el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // is click outside
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent);
//   },
//   unbind(el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent);
//   },
// });
