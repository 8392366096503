/**
 * 下底線轉小駝峰
 * @param {string} name
 * @return {string} newstring 小駝峰字串
 */
export const toHump = (name) => name.replace(/_(\w)/g, (match, $1) => `${$1.toUpperCase()}`);

/**
 * desh 轉小駝峰
 * @param {string} name
 * @return {string} newstring 小駝峰字串
 */
export const cebabToHump = (name) => name.replace(/-(\w)/g, (match, $1) => `${$1.toUpperCase()}`);

/**
 * 下底線轉大駝峰
 * @param {string} name
 * @return {string} newstring 大駝峰字串
 */
export const toCamel = (name) => toHump(name).replace(/[a-z]{1}/, (s) => s.toUpperCase());

/**
 * 駝峰轉下底線
 * @param {string} name 駝峰字串
 * @return {string} newstring 下底線字串
 */
export const toLine = (name) => name.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

/**
 * 字串擷取 (中英文混用)
 * @param {string} str 字串
 * @param {number} len 權重長度 (中文權重 2, 英數 1)
 * @return {string}
 */
export const formatStrLimit = function (str = '', len = 10) {
  let newstr = '';
  let num = 0;
  if (str === '') return str;

  str.split('').some((v, i) => {
    num += /^[\u4e00-\u9fa5\u3400-\u4DBF]+$/i.test(v) ? 2 : 1;
    const lmt = num === len ? i + 1 : i;
    if (num === len || num > len) {
      newstr = str.substring(0, lmt);
      return true;
    }
    newstr = str;

    return false;
  });
  return newstr;
};

/**
 * 左側補0
 * @param {number} length 補0長度
 * @param {string} str 補0字串
 * @return {string}
 */
export const padLeftZero = (length = 3, str = '') => (String(str).length >= length ? str : padLeftZero(length, `0${str}`));

/**
 * Storage value decoder.
 * @param {string} value - The encode value.
 * @returns {?(string|Object|number)}
 */
export const decode = (value) => {
  if (!value) return null;
  value = decodeURIComponent(value);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

/**
 * Storage value encoder.
 * @param {*} value
 * @returns {string}
 */
export const encode = (value) => {
  if (value === undefined || value === null) return 'undefined';

  switch (value.constructor) {
    case Number:
      return value.toString();
    case String:
      return encodeURIComponent(value);
    case Array:
    case Object:
      return encodeURIComponent(JSON.stringify(value));
    default:
      return encodeURIComponent(value.toString());
  }
};
