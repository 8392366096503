// 活動頁 iframe cross-origin，在 chrome 無痕視窗開啟，無法用localStorage、sessionStorage...
export function getWindowProperty(windowProperty) {
  try {
    return window[windowProperty];
  } catch {
    return null;
  }
}

export const isLocalStorageAvailable = getWindowProperty('localStorage');
export const isSessionStorageAvailable = getWindowProperty('sessionStorage');
