import { ref } from 'vue';
import { isProtectedPage } from '@/helper/protected-page';
import { createRouteMiddleware } from '@/plugins/route/middleware';
import { getPosition } from '@/helper/scroll';
import { setHistory } from '@/helper/url/history';
import { delPreloadImage } from '@/helper/meta/preload-image';
import { useHistoryStore } from '@/stores/history';
import { useLayoutErrorStore } from '@/stores/layout-error';

export default ({ pluginContext, router, isHistoryPopRef, pinia }) => {
  const isFirstLoad = ref(true);

  const routeMiddleware = createRouteMiddleware({ pluginContext, isFirstLoad, pinia });
  router.beforeEach((to, from, next) => {
    const historyStore = useHistoryStore();
    const layoutErrorStore = useLayoutErrorStore();
    historyStore.setIsHistoryPop(isHistoryPopRef.value);
    isHistoryPopRef.value = false;
    layoutErrorStore.clearError();
    const position = getPosition();
    setHistory({ ...from, ...position });
    routeMiddleware(to, from, next);
    // 檢查是否有 link preload image tag
    delPreloadImage();
  });

  router.afterEach((to, from) => {
    const fromPath = from.path;
    const toPath = to.path;
    if (isFirstLoad.value) {
      isFirstLoad.value = false;
      return;
    }
    const needRefresh = isProtectedPage(toPath) !== isProtectedPage(fromPath);
    // 限制頁與非限制頁切換，refresh頁面，限制第三方script載入
    if (needRefresh) window.location.reload();
  });
};
