<template>
  <div class="container">
    <img v-lazy="page404Img" class="mx-auto my-4" alt="" src="" />
    <div class="title serif-font-bold mb-2 text-center">頁面不存在404</div>
    <div class="des text-center e-col-space">
      <div>徐志摩再別了康橋，這個頁面再別了誠品</div>
      <div>所以，請跟著我們重新開始</div>
    </div>
    <div class="btn-item mt-4 text-center">
      <router-link to="/" class="btn btn-eslite-red mb-3">回首頁</router-link>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref } from 'vue';
import { page404Img } from '@/constant/images-path';
import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';

const metaInfo = () => ({
  title: `404 頁面不存在 │ 誠品線上 - 閱讀與生活的無盡想像`,
  meta: [
    { property: 'og:url', content: `${import.meta.env.VITE_BASE_URL}/error/not-found`, vmid: 'og:url' },
    { property: 'og:title', content: `404 頁面不存在 │ 誠品線上 - 閱讀與生活的無盡想像`, vmid: 'og:title' },
    { property: 'og:image', content: 'https://www.eslite.com/eslite-og-logo.png', vmid: 'og:image' },
  ],
});

export default {
  name: 'Error404',
  metaInfo,
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const timeoutId = ref(null);

    const created = () => {
      // snapshot 測試，不離開此頁
      if (checkIsImageSnapshotTest()) return;
      // 5 秒後回首頁
      timeoutId.value = setTimeout(() => {
        timeoutId.value = null;
        window.location.href = '/';
      }, 5000);
    };

    onBeforeUnmount(() => {
      if (timeoutId.value) clearTimeout(timeoutId.value);
    });

    created();

    return {
      page404Img,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 75%;
  display: block;
  @include mediaIs($grid-breakpoints-md) {
    width: 50%;
  }
}
.red {
  color: var(--eslite-red);
}
.content {
  text-align: center;
}
.title {
  @include font24;
}
.des {
  @include font16;
}
.btn {
  width: 180px;
  border-radius: 4px;
  padding: 10px;
  color: var(--white);
  background-color: var(--eslite-red);
}
@include mediaMin($grid-breakpoints-lg) {
  img {
    width: 370px;
  }
  .title {
    @include font30;
  }
  .des {
    @include font19;
  }
}
</style>
