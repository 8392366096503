export default ({ app }) => {
  const isSupportWebp = function () {
    try {
      return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
    } catch (err) {
      return false;
    }
  };

  app.config.globalProperties.$isSupportWebp = isSupportWebp();
  app.provide('$isSupportWebp', app.config.globalProperties.$isSupportWebp);
};
