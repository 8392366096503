// localization.js
/**
 * 取代 i18n 的字典檔功能
 * use:
 * const t = inject(`$t`);
 * or: $t
 * or: import { t } from '@/plugins/localization';
 */
import zhTW from '@/lang/zh-tw.js';

function t(key) {
  const keys = key.split('.');
  const result = keys.reduce((acc, cur) => (acc && acc[cur] ? acc[cur] : null), zhTW);
  // console.group(`localization`); console.log({ key, result }); console.groupEnd();

  // 查不到則 console key
  if (result === null) {
    console.warn(`Localization key "${key}" not found.`);
    return key;
  }

  return result;
}

export default ({ app }) => {
  app.config.globalProperties.$t = t;
  app.provide('$t', t);
};

export { t };
