import { defineStore } from 'pinia';
import getMember from '@/api/member/member-center';
import { memberStatusEnum } from '@/constant/member/login';

const getDefaultMemberData = () => ({
  customer_no: '',
  card_no: '',
  card_kind: '',
  card_start_date: '',
  card_end_date: '',
  cumulative_money: '',
  cumulative_count: '',
  card_points: '',
  expire_point_this_year: '',
  expire_point_next_year: '',
  master_card_name: '',
  additional_card_create_date: '',
  is_life_member: '',
  remain_next_level_amount: 0,
  remain_next_level_count: '',
  is_card_renewed: '',
  next_level_card_kind: '',
  badge_read: {
    badge_level: '',
    cumulative_books_count: 0,
    next_level_count: 0,
    reward_points: '',
    start_date: '',
    end_date: '',
  },
  badge_wine: {
    badge_level_max: '',
    end_date: '',
    badge_level_now: '',
    sum_amount: 0,
  },
  additional_cards: [],
  ecoupon_count: 0,
  collected_products_count: 0,
  product_notification_count: 0,
});

const state = () => ({
  loadingStatus: true,
  status: memberStatusEnum.Unlogged,
  memberData: getDefaultMemberData(),
});
const actions = {
  callback(data) {
    this.memberData = data;
    this.loadingStatus = false;
    data = null;
  },
  clearMemberData() {
    this.memberData = getDefaultMemberData();
    this.loadingStatus = false;
    this.status = memberStatusEnum.Error;
  },
  getMemberData(token) {
    getMember(token)
      .then(({ data }) => {
        // 沒有會員卡、或api拿不到資料時，給預設值
        const requiredFields = ['badge_read', 'badge_wine'];
        const defaultMemberData = getDefaultMemberData();
        requiredFields.forEach((field) => {
          if (!data[field]) data[field] = defaultMemberData[field];
        });
        this.callback(data);
        data = null;
      })
      .catch(() => this.clearMemberData());
  },
};

export const useMemberDataStore = defineStore('memberData', {
  state,
  actions,
});
