<template>
  <div class="member-banner mt-lg-3">
    <a v-if="oneBanner" :id="`home-membersbanner`" class="justify-content-center" :href="bannerAd.link">
      <lazy-webp-image v-if="bannerAd.image" class="horizontal-img" :src="bannerAd.image" :alt="bannerAd.alt"></lazy-webp-image>
    </a>
    <swiper v-else :key="itemsLength" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in banners" :key="item.id" class="swiper-slide" :title="item.alt || item.name">
        <a :id="`home-membersbanner-${index + 1}`" :href="item.link" :target="target" :title="item.alt || item.name">
          <swiper-webp-image class="swiper-lazy" :src="item.image" :alt="item.alt || item.name"></swiper-webp-image>
        </a>
      </swiper-slide>
      <template v-if="switchLoop" #button-prev>
        <div class="swiper-button-prev"></div>
      </template>
      <template v-if="switchLoop" #button-next>
        <div class="swiper-button-next"></div>
      </template>
    </swiper>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useGetHomeAdStore } from '@/composables/store/home-ad';
import { bigBannerSwiperOption } from '@/constant/ad/ad-constant';
import { linkTarget } from '@/helper/data-process';
import SwiperWebpImage from '@/components/utility/image/swiper-webp-image.vue';
import LazyWebpImage from '@/components/utility/image/lazy-webp-image.vue';

const components = {
  SwiperWebpImage,
  LazyWebpImage,
};

const setup = () => {
  const { memberBanner: bannerAd } = useGetHomeAdStore();
  const banners = computed(() => bannerAd.value.items || []);
  const itemsLength = computed(() => banners.value.length);
  const switchLoop = computed(() => itemsLength.value > 1);
  const target = computed(() => linkTarget(bannerAd.value.blank));
  const oneBanner = computed(() => bannerAd.value.items === undefined);
  const swiperOption = computed(() => {
    const option = {
      ...bigBannerSwiperOption,
      slidesPerView: 1,
      loop: switchLoop.value,
    };
    if (switchLoop.value) return option;
    option.navigation = {};
    return option;
  });

  return {
    bannerAd,
    banners,
    itemsLength,
    switchLoop,
    target,
    swiperOption,
    oneBanner,
  };
};

export default {
  name: 'MemberBanner',
  components,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/index/banner-horizontal-swiper.scss';

@include mediaMax($grid-breakpoints-lg) {
  .member-banner {
    padding: 1rem;
    background: var(--l-gray-e);
  }
}
a {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}
.swiper,
.swiper-wrapper {
  height: 100%;
}

@mixin mobileStyle($size) {
  .member-banner {
    $padding: 16; // 1rem
    @include pxToRem(height, (math.div(450, mobileRate($size))) + $padding * 2);
    @include pxToRem(min-height, (math.div(450, mobileRate($size))) + $padding * 2);
    @include pxToRem(max-height, (math.div(450, mobileRate($size))) + $padding * 2);
  }
}

@include mobileStyle($mobile-container-xs);

@include mediaIs($grid-breakpoints-sm) {
  @include mobileStyle($mobile-container-sm);
}
@include mediaIs($grid-breakpoints-md) {
  @include mobileStyle($mobile-container-md);
}

@include mediaMin($grid-breakpoints-lg) {
  .member-banner {
    @include pxToRem(height, 200);
    @include pxToRem(max-height, 200);
    @include pxToRem(min-height, 200);
  }
}
</style>
