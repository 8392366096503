import axios from 'axios';
import { adTypeEnum } from '@/constant/ad/ad-type';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_AD}`;

/** 分類頁 */
export const fetchTopLevelAd = (category, position = '') => axios.get(`${API_PATH}/${adTypeEnum.categoryLevel1}/${category}/${position}`);
export const fetchLevel2Ad = (category, position = '') => axios.get(`${API_PATH}/${adTypeEnum.categoryLevel2}/${category}/${position}`);
export const fetchLevel3Ad = (category, position = '') => axios.get(`${API_PATH}/${adTypeEnum.categoryLevel3}/${category}/${position}`);

/** 商品頁 */
export const fetchProductAd = (params) => axios.get(`${API_PATH}/${adTypeEnum.product}/${params.position}/${params.product}`);

/** 購物車 */
export const fetchCartAd = (positions) => axios.get(`${API_PATH}/${adTypeEnum.cart}/${positions}`);

/** 品牌頁 */
export const fetchBrandLevel2Ad = (brand, position = '') => axios.get(`${API_PATH}/${adTypeEnum.brandLevel2}/${brand}/${position}`);
export const fetchBrandLevel3Ad = (category, position = '') => axios.get(`${API_PATH}/${adTypeEnum.brandLevel3}/${category}/${position}`);

export default {
  /** 分類頁 */
  fetchTopLevelAd,
  fetchLevel2Ad,
  fetchLevel3Ad,

  /** 商品頁 */
  fetchProductAd,

  /** 購物車 */
  fetchCartAd,

  /** 品牌頁 */
  fetchBrandLevel2Ad,
};
