import { map, sortBy, prop } from 'ramda';
import { emptyReplace } from '@/helper/data-process';
import { adTypeEnum } from '@/constant/ad/ad-type';

const formatLoginPageHorizontalPaint = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.memberCenter),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
  image: emptyReplace(ad.image, ''),
  link: emptyReplace(ad.link, '#'),
  alt: emptyReplace(ad.alt, ''),
});

const formatHorizontalPaintWeb = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.memberCenter),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        alt: item.alt,
        image: item.image,
        link: item.link,
        sort: item.sort,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

const formatTextAD = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.memberCenter),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        text: item.title,
        link: item.link,
        sort: item.sort,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

export default {
  formatTextAD,
  formatHorizontalPaintWeb,
  formatLoginPageHorizontalPaint,
};
