import Cookies from 'js-cookie';
import { storeToRefs } from 'pinia';
import md5 from 'blueimp-md5';
import { useLoginStore } from '@/stores/login';
import { isEmptyValue } from '@/helper/data-process';

class VerifyTokenService {
  constructor(key = 'DEFAULT_VERIFY_KEY') {
    this.key = key;
    this.expiryTime = 30 * 60 * 1000;
    this.loginStore = useLoginStore();
    this.userId = VerifyTokenService.getUserId();
  }

  set() {
    const value = this.generateValue();
    const data = {
      value,
      timestamp: new Date().getTime(),
    };

    localStorage.setItem(this.key, JSON.stringify(data));

    Cookies.set(this.key, JSON.stringify(data), { expires: this.expiryTime / (24 * 60 * 60 * 1000) });
  }

  get() {
    let data = localStorage.getItem(this.key);

    if (data) {
      data = JSON.parse(data);

      if (new Date().getTime() - data.timestamp > this.expiryTime) {
        this.remove();
        return null;
      }

      return data.value;
    }

    const cookieValue = Cookies.get(this.key);

    if (cookieValue) {
      data = JSON.parse(cookieValue);

      if (new Date().getTime() - data.timestamp > this.expiryTime) {
        this.remove();
        return null;
      }

      return data.value;
    }

    return null;
  }

  remove() {
    localStorage.removeItem(this.key);

    Cookies.remove(this.key);
  }

  validate(expectedValue = '') {
    if (typeof expectedValue !== 'string') return false;
    const value = this.get();
    return value === expectedValue;
  }

  generateValue() {
    const { getUser } = storeToRefs(this.loginStore);
    const { id = '', lms_member_id: lmsMemberId = '' } = getUser?.value || {};
    const uid = VerifyTokenService.getUserId();
    const token = isEmptyValue(id) || isEmptyValue(lmsMemberId) ? md5(uid) : md5(`${lmsMemberId}_${id}`);

    return token;
  }

  static getUserId() {
    let userId = localStorage.getItem('DEFAULT_VERIFY_UID');
    if (!userId) {
      userId = VerifyTokenService.generateFingerprint();
      localStorage.setItem('DEFAULT_VERIFY_UID', userId);
    }
    return userId;
  }

  static hashComponents(components) {
    return Array.from(components)
      .reduce((hash, chr) => {
        hash = (hash * 31 + chr.charCodeAt(0)) % 0x100000000;
        return hash;
      }, 0)
      .toString();
  }

  static generateFingerprint() {
    const fingerprintComponents = [
      navigator.userAgent,
      navigator.language,
      screen?.colorDepth,
      screen?.availWidth,
      screen?.availHeight,
      new Date().getTimezoneOffset(),
      !!window.sessionStorage,
      !!window.localStorage,
      !!window.indexedDB,
      typeof document.body.addBehavior,
      typeof window.openDatabase,
      navigator.cpuClass,
      navigator.platform,
      navigator.doNotTrack,
    ];

    return VerifyTokenService.hashComponents(fingerprintComponents.join(''));
  }

  getApiHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.get()}`,
      'User-ID': this.userId,
    };
  }
}

export default VerifyTokenService;
