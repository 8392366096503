/**
 * 超商 store 對應表
 */
export const stores = [
  // 達康電子 (全家, OK, 萊爾富)
  {
    code: 'F',
    name: '全家便利商店',
    type: 'family_mart',
    delivery: '3',
  },
  {
    code: 'L',
    name: '萊爾富便利商店',
    type: 'hilife',
    delivery: '3',
  },
  {
    code: 'K',
    name: 'OK便利商店',
    type: 'ok_mart',
    delivery: '3',
  },
  // 日翊文化 (全家超商電子地圖)
  {
    code: '',
    name: '全家便利商店',
    type: 'family_mart',
    delivery: '9',
  },
  {
    code: '',
    name: '7-ELEVEN',
    type: 'shopping_seven',
    delivery: '5',
  },

  {
    code: '',
    name: '香港OK便利店', // 香港 ok 超商
    type: 'hk_ok_mart',
    delivery: '7',
  },
];

/**
 * @const {string} storeKey 購物車 step2 超商門市紀錄 key
 */
export const storeKey = 'step2-store';
