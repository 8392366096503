import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { isEmptyValue } from '@/helper/data-process';
import { useHistoryStore } from '@/stores/history';

const state = () => ({
  sidebar: [],
  /**
   * 以下紀錄提供 history back 使用，恢復 策展內容、展開紀錄 (scroll位置 紀錄在 store/history)
   * 目前僅供最後一個策展紀錄 !!!
   * (ex. 若 history 是 策展A => 商品a => 策展B => 商品b，則僅有 策展B 頁面紀錄，沒有 策展A 紀錄 )
   */
  savedId: '',
  savedSortedData: null,
  /**
   * 看更多 展開紀錄
   * key: `${item.position}-${booklist.position}`
   * value: quantity
   */
  savedDisplayMoreQuantity: {},
});

const getters = {
  getSidebar: (state) => state.sidebar,
  getIsUseSavedData: (state) => (currentId) => {
    const historyStore = useHistoryStore();
    if (!historyStore.getIsHistoryPop) return false;
    if (state.savedId !== currentId) return false;
    return !isEmptyValue(state.savedSortedData);
  },
  getSavedSortedData: (state) => state.savedSortedData,
  getSavedDisplayMoreQuantity: (state) => ({ itemPosition, booklistPosition }) => state.savedDisplayMoreQuantity[`${itemPosition}-${booklistPosition}`],
};

const actions = {
  saveSidebar(sidebarList) {
    this.sidebar = sidebarList;
  },
  clearSidebar() {
    this.sidebar = [];
  },
  saveExhibIdAndData({ id, allSortedData }) {
    this.savedId = id;
    // clone data，避免 mutate store
    this.savedSortedData = cloneDeep(allSortedData);
    this.savedDisplayMoreQuantity = {};
  },
  updateDisplayMoreQuantity({ itemPosition, booklistPosition, quantity }) {
    const key = `${itemPosition}-${booklistPosition}`;
    this.savedDisplayMoreQuantity[key] = quantity;
  },
};

export const useBookExhibitStore = defineStore('bookExhibit', {
  state,
  getters,
  actions,
});
