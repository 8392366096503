<template>
  <base-product class="hot-sell-horizontal-item" :product="product" :product-gtm-id="productGtmId">
    <template #product-image>
      <product-lazy-image :src="product.image" :is-adult="product.isAdult" :alt="product.title || product.name" :image-size="productSize"></product-lazy-image>
    </template>
  </base-product>
</template>

<script>
import { toRef } from 'vue';
import { useProductItemWidth } from '@/composables/product-item/product-item-width';
import BaseProduct from '@/components/utility/product/base-product.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';

const components = {
  BaseProduct,
  ProductLazyImage,
};

const props = {
  product: {
    type: Object,
    default: () => {},
  },
  productGtmId: {
    type: String,
    default: '',
  },
  productWidth: {
    type: Number,
    default: null,
  },
  freeWidth: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const productWidth = toRef(() => props.productWidth);
  const freeWidth = toRef(() => props.freeWidth);
  const { productSize } = useProductItemWidth({ productWidth, freeWidth });

  return {
    productSize,
  };
};

export default {
  name: 'HotSellHorizontalProduct',
  components,
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.hot-sell-horizontal-item {
  width: 100%;
  :deep(.deep-base-product-card),
  :deep(a) {
    background: none;
    width: 100%;
    height: 100%;
  }
}

@mixin defaultProductStyle() {
  .hot-sell-horizontal-item {
    display: flex;
    align-items: center;
    //flex-direction: row;
    :deep(.e-banner-product > a) {
      display: flex;
    }
    :deep(.product-image-box),
    :deep(.imgbox) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
      margin: 0;
      height: 100%;
    }
    :deep(.text) {
      text-align: left;
      width: 50%;
      height: 100%;
      max-width: 50%;
    }
    :deep(.desc) {
      letter-spacing: normal;
      height: 3.99rem;
      line-height: 1.33rem;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
    }
  }
}

@include defaultProductStyle;

.hot-sell-horizontal-item {
  :deep(.desc) {
    @include fontSize(16);
    @include pxToRem(margin-bottom, 10);

    @include mediaMax($grid-breakpoints-md) {
      @include pxToRem(margin-bottom, 6);
    }
  }
}
// 平板以下
@include mediaMax($grid-breakpoints-lg) {
  .hot-sell-horizontal-item {
    width: 100%;
    height: 100%;
  }
  :deep(.text) {
    padding: 10px;
  }
}

@include mediaIs($grid-breakpoints-lg) {
  .hot-sell-horizontal-item {
    :deep(.product-image-box),
    :deep(.imgbox) {
      width: calc(100% - 9rem);
      flex: 0 0 calc(100% - 9rem);
      max-width: calc(100% - 9rem);
    }
    :deep(.text) {
      width: 9rem;
      max-width: 9rem;
      @include pxToRem(margin-left, 10);
    }
  }
}
@include mediaMin($grid-breakpoints-lg) {
  .hot-sell-horizontal-item {
    padding: 0.48rem 10px 0.4rem 10px;
    min-height: 154px;
    max-height: 46%;
    border-left: none;
    @include fontSize(14);
    :deep(.product-image-box),
    :deep(.imgbox) {
      width: calc(100% - 9rem);
      flex: 0 0 calc(100% - 9rem);
      max-width: calc(100% - 9rem);
    }
  }
  .hot-sell-horizontal-item:nth-child(even) {
    border-top: 1px var(--very-light-pink) solid;
  }
}

@include mediaIs($grid-breakpoints-xl) {
  @include defaultProductStyle;
  .hot-sell-horizontal-item {
    :deep(.text) {
      @include pxToRem(padding, 6, 0, 0, 10);
    }
  }
}

@include mediaMin($grid-breakpoints-xl) {
  .hot-sell-horizontal-item {
    @include pxToRem(padding, 15, 15, 16);
  }
}
// 虛擬貨架
#virtual-shelf-layout {
  .hot-sell-horizontal-item {
    :deep(.desc) {
      @include fontSize(28);
    }
  }
  :deep(.percentage-discount-price) {
    .pre-price-num {
      @include font20;
      .cart-price-black {
        @include font20;
      }
      .cart-price-black::before {
        @include font20;
      }
      span + span {
        font-weight: 400;
      }
    }
  }
}
</style>
