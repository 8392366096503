export const filterUrl = [
  '/member',
  '/forgot',
  '/member/points',
  '/member/notify',
  '/member/account',
  '/member/newsletter',
  '/member/customer',
  '/docs/group-buying-description',
  '/docs/privacy-policy',
];
