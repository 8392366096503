const labelText = (prm) => {
  return prm.label ? `${prm.label}` : '';
};

export default {
  mixed: {
    default: (prm) => `${labelText(prm)}格式有誤。`,
    required: (prm) => `${labelText(prm)}為必填。`,
    oneOf: (prm) => `${labelText(prm)}必為以下其中之一:${prm.values}。`,
    notOneOf: (prm) => `${labelText(prm)}必不為以下其中之一:${prm.values}。`,
    notType: (prm) => {
      if (prm.type === 'number') return `${labelText(prm)}請輸入有效的數字。`;
      if (prm.type === 'date') return `${labelText(prm)}請輸入有效的日期。`;
      return `${labelText(prm)}格式有誤。`;
    },
    defined: `defined`,
  },
  string: {
    required: (prm) => `${labelText(prm)}為必填。`,
    length: (prm) => `${labelText(prm)}須為 ${prm.length} 個字。`,
    min: (prm) => `${labelText(prm)}不得小於 ${prm.min} 個字。`,
    max: (prm) => `${labelText(prm)}不得大於 ${prm.max} 個字。`,
    between: (prm) => `${labelText(prm)} 的長度須為 ${prm.min} 至 ${prm.max} 之間。`,
    matches: (prm) => `${labelText(prm)}格式有誤。`,
    email: (prm) => `${labelText(prm)}格式有誤。`,
    url: (prm) => `${labelText(prm)}格式有誤。`,
    uuid: (prm) => `${labelText(prm)}格式有誤。`,
    trim: (prm) => `${labelText(prm)}格式有誤。`,
    lowercase: (prm) => `${labelText(prm)}必須是小寫。`,
    uppercase: (prm) => `${labelText(prm)}必須是大寫。`,
    nameRequired: (prm) => `${labelText(prm)}為必填欄位。`,
    phoneTW: (prm) => `${labelText(prm)}格式有誤。`,
    phoneHK: (prm) => `${labelText(prm)}格式有誤。`,
    integer: (prm) => `${labelText(prm)}格式有誤。`,
    invoiceMobileDevice: (prm) => `${labelText(prm)}格式有誤。`,
    invoiceIDDevice: (prm) => `${labelText(prm)}格式有誤。`,
    addressEN: (prm) => `${labelText(prm)}輸入不完整。`,
    addressTW: (prm) => `${labelText(prm)}輸入不完整。`,
    isUnifiedNumber: (prm) => `${labelText(prm)}格式有誤。`,
    isNot: (prm) => `${labelText(prm)}欄位必填。`,
  },
  number: {
    min: (prm) => `${labelText(prm)}不得小於 ${prm.min} 個字。`,
    max: (prm) => `${labelText(prm)}不得大於 ${prm.max} 個字。`,
    between: (prm) => `${labelText(prm)} 的長度須為 ${prm.min} 至 ${prm.max} 之間。`,
    lessThan: (prm) => `${labelText(prm)}必須比 ${prm.less} 小。`,
    moreThan: (prm) => `${labelText(prm)}必須比 ${prm.more} 大。`,
    positive: (prm) => `${labelText(prm)}請輸入正數。`,
    negative: (prm) => `${labelText(prm)}請輸入負數。`,
    integer: (prm) => `${labelText(prm)}必須是整數。`,
  },
  date: {
    min: (prm) => `${labelText(prm)}必須比 ${prm.min} 晚。`,
    max: (prm) => `${labelText(prm)}必須比 ${prm.max} 早。`,
  },
  boolean: {
    isValue: (prm) => `${labelText(prm)} 為必選。`,
  },
  array: {
    length: (prm) => `${labelText(prm)}的長度須為 ${prm.length}。`,
    min: (prm) => `${labelText(prm)}不得小於 ${prm.min} 個。`,
    max: (prm) => `${labelText(prm)}不得大於 ${prm.max} 個。`,
  },
};
