<template>
  <div class="virtual-shelf-header" data-test-id="virtual-shelf-header">
    <div class="header-fixed">
      <nav class="header-navbar">
        <router-link class="nav-home" to="/" title="誠品線上"><span class="icon-shelfhome"></span></router-link>
        <template v-for="(l1Item, l1Index) in list">
          <router-link v-if="$isEmpty(l1Item.link)" :key="l1Item.id" class="nav-item" :to="`/category/1/${l1Item.id}`" :class="{ active: l1Item.id === currentCategoryLevel1Id }">{{
            l1Item.description
          }}</router-link>
        </template>
        <router-link class="nav-item" to="/brand" title="品牌旗艦館" :class="{ active: isBrand }">品牌旗艦館</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, nextTick, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalAdStore } from '@/stores/global-ad';
import { useActiveCategory } from '@/composables/utility/active-category';
import getHeaders from '@/api/ad/headers';
import { useCategoriesStore } from '@/stores/categories';

const setup = () => {
  const categoriesStore = useCategoriesStore();
  const { fetchCategories } = categoriesStore;
  const { getGeneralCategories } = storeToRefs(categoriesStore);

  const list = computed(() => getGeneralCategories.value);
  const getMenu = () => fetchCategories();

  const globalAdStore = useGlobalAdStore();
  const { getBrands: brandList } = storeToRefs(globalAdStore);

  const { isBrand, currentCategoryLevel1Id } = useActiveCategory();

  /**
   * methods
   */
  const scrollToActiveItem = () => {
    const activeItem = document.querySelector('.nav-item.active');
    if (!activeItem) return;
    activeItem.scrollIntoView({ block: 'end', inline: 'nearest' });
  };
  const delayScrollToActiveItem = () => {
    nextTick(() => {
      scrollToActiveItem();
      setTimeout(scrollToActiveItem, 300);
    });
  };

  onMounted(() => {
    if (list.value.length <= 0) getMenu();
    // 取得品牌館L2 list
    if (brandList.value.length <= 0) {
      getHeaders().then((res) => {
        globalAdStore.setBrands(res.data);
      });
    }
  });

  watch(
    isBrand,
    (newValue) => {
      if (newValue) delayScrollToActiveItem();
    },
    { immediate: true },
  );
  watch(
    currentCategoryLevel1Id,
    (newValue) => {
      if (newValue) delayScrollToActiveItem();
    },
    { immediate: true },
  );
  watch(
    list,
    (newValue) => {
      if (newValue?.length) delayScrollToActiveItem();
    },
    { immediate: true },
  );

  return {
    list,
    isBrand,
    currentCategoryLevel1Id,
  };
};

export default {
  name: 'VirtualShelfHeader',
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/zindex.scss';

$item-heihgt: 62;
$header-y-padding: 27;
$header-height: $item-heihgt + $header-y-padding * 2;
$item-border: $item-heihgt * 0.7;
$nav-home-x-padding: 27;

.virtual-shelf-header {
  position: relative;
  width: 100%;
  @include pxToRem(height, $header-height);
}
.header-fixed {
  position: fixed;
  @include zindex(menubar-desktop);
  top: 0;
  left: 0;
  right: 0;
  @include pxToRem(height, $header-height);
  overflow-x: scroll;
  background-color: var(--white);
}
.header-navbar {
  display: flex;
  width: max-content;
  @include pxToRem(padding, $header-y-padding, 0);
}
.nav-home,
.nav-item {
  display: inline-block;
  @include pxToRem(height, $item-heihgt);
}
.nav-home {
  @include pxToRem(padding, 0, $nav-home-x-padding);
  border-right: solid pxToRemValue(1.8) var(--eslite-primary);
}
.icon-shelfhome {
  display: inline-block;
  @include fontSize($item-heihgt - 5);
  @include pxToRem(line-height, $item-heihgt + 5);
  @include pxToRem(height, $item-heihgt);
}
.nav-home + .nav-item {
  @include pxToRem(margin-left, $nav-home-x-padding);
}
.nav-item {
  @include fontSize(36);
  @include pxToRem(margin, 0, 9);
  @include pxToRem(padding, 0, 25);
  @include pxToRem(border-radius, $item-border, $item-border, 0, 0);
  color: var(--eslite-primary);
  border: solid pxToRemValue(1.8) var(--eslite-primary);
}
.nav-item.active,
.nav-item:hover {
  color: var(--white);
  background-color: var(--eslite-primary);
}
.nav-item:hover {
  text-decoration: none;
}
</style>
