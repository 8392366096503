import { defineStore } from 'pinia';

const convertCategoriesToKey = (categories) => {
  if (Array.isArray(categories)) return categories.join('-');
  return categories;
};

/**
 * state
 */
const state = () => ({
  keyword: '',
  totalOfKeywordHits: 0,
  facetOfKeyword: {},
  facetsKeyByCategories: {},
});

/**
 * getters
 */
const getKeyword = (state) => state.keyword;
const getTotalOfKeywordHits = (state) => state.totalOfKeywordHits;
const getFacetOfKeyword = (state) => state.facetOfKeyword;
const findFacetOfCategories = (state) => (categories) => {
  if (!categories) return {};
  return state.facetsKeyByCategories[convertCategoriesToKey(categories)];
};

const getters = {
  getKeyword,
  getTotalOfKeywordHits,
  getFacetOfKeyword,
  findFacetOfCategories,
};

/**
 * actions
 */
const clearSearchFacets = function () {
  this.$reset();
};
const overwriteSearchFacets = function ({ keyword, facet, total }) {
  this.keyword = keyword;
  this.totalOfKeywordHits = total;
  this.facetOfKeyword = facet;
  this.facetsKeyByCategories = {};
};
const setFacetOfCategories = function ({ categories, facet }) {
  const key = convertCategoriesToKey(categories);
  this.facetsKeyByCategories = { ...this.facetsKeyByCategories, [key]: facet };
};

const actions = {
  clearSearchFacets,
  overwriteSearchFacets,
  setFacetOfCategories,
};

export const useSearchFacetStore = defineStore('searchFacet', {
  state,
  getters,
  actions,
});
