import { useBookExhibitStore } from '@/stores/book-exhibit';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useForgotStore } from '@/stores/forgot';
import { useGlobalAdStore } from '@/stores/global-ad';
import { useHistoryStore } from '@/stores/history';
import { useHomeAdStore } from '@/stores/home-ad';
import { useLoadingStore } from '@/stores/loading';
import { useLockBodyScrollStore } from '@/stores/lock-body-scroll';
import { useMemberAdStore } from '@/stores/member-ad';
import { useMemberProfileStore } from '@/stores/member-profile';
import { usePriceQueueStore } from '@/stores/price-queue';
import { usePriceStore } from '@/stores/price';
import { useSearchFacetStore } from '@/stores/search-facet';
import { useVirtualShelfAlertStore } from '@/stores/virtual-shelf-alert';
import { useCategoriesStore } from '@/stores/categories';
import { useBrandStore } from '@/stores/brand';
import { useLayoutErrorStore } from '@/stores/layout-error';
import { useVisitStore } from '@/stores/visit';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';
import { useLogoutStore } from '@/stores/logout';
import { useCartStore } from '@/stores/cart';
import { useCartAdStore } from '@/stores/cart-ad';
import { useMemberVerifyStore } from '@/stores/member-verify';
import { useMemberDataStore } from '@/stores/member-data';
import { useValidateStore } from '@/stores/validate';

const allStores = {
  useBookExhibitStore,
  useCustomerServiceStore,
  useForgotStore,
  useGlobalAdStore,
  useHistoryStore,
  useHomeAdStore,
  useLoadingStore,
  useLockBodyScrollStore,
  useMemberAdStore,
  useMemberProfileStore,
  usePriceQueueStore,
  usePriceStore,
  useSearchFacetStore,
  useVirtualShelfAlertStore,
  useCategoriesStore,
  useBrandStore,
  useLayoutErrorStore,
  useVisitStore,
  useCartItemsStore,
  useLoginStore,
  useLogoutStore,
  useCartStore,
  useCartAdStore,
  useMemberVerifyStore,
  useMemberDataStore,
  useValidateStore,
};

export function allStoresInit() {
  Object.keys(allStores).forEach((key) => {
    allStores[key]();
  });
}
