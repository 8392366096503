<template>
  <div ref="btpn" class="Back-to-top" @click="btp">
    <div class="icon-arrow-up-solid"></div>
  </div>
</template>

<script>
import throttle from 'lodash-es/throttle';
import { ref, toRefs, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { isEmptyValue } from '@/helper/data-process';
// 與 底部 或 relative-container (ex. footer) 間距
const bottomSpace = 20;

const setup = (props) => {
  const { relativeContainerSelector } = toRefs(props);
  const route = useRoute();
  const $screen = inject('$screen');
  const btpn = ref(null);
  // 需要Back to top按鈕
  const routeName = ref([
    'index',
    'product-id',
    'category-1-id',
    'category-2-id',
    'category-3-id',
    'search',
    'brand',
    'best-sellers-bookstore',
    'eslite-choice-books',
    'eslite-choice-music',
    'eslite-only',
  ]);

  /**
   * Methods
   */
  const checkPage = () => {
    const tagPage = routeName.value.includes(route.name);
    if (tagPage) btpn.value?.classList.remove('no-page');
    else btpn.value?.classList.add('no-page');
  };
  const checkAndSetRelativePosition = () => {
    if (!btpn.value) return false;
    if (!relativeContainerSelector.value) return false;
    const relativeContainerHeight = document.querySelector(relativeContainerSelector.value)?.offsetHeight || 0;
    // 判斷是否接近 relative-container (ex. footer) 底端
    if (window.scrollY + window.innerHeight > document.body.clientHeight - relativeContainerHeight) {
      if (!isEmptyValue(btpn.value)) {
        btpn.value.style.cssText = `position: absolute; bottom: calc(100% + ${bottomSpace}px);`;
      }
      return true;
    }
    return false;
  };
  const setFixedPosition = () => {
    if (isEmptyValue(btpn.value)) return;
    btpn.value.style.position = `fixed`;
    // 行動版有add-cart要有間距
    if ($screen.isMobileSize && route.name === 'product-id') {
      const addCart = document.querySelector('.add-cart');
      btpn.value.style.bottom = `${addCart?.offsetHeight + bottomSpace}px`;
      return;
    }
    btpn.value.style.bottom = `${bottomSpace}px`;
  };
  const scrollEvent = throttle(function () {
    if (isEmptyValue(btpn.value) || btpn.value === undefined) return;

    // 滾動300距離就顯示btp按鈕
    if (window.scrollY > 200) {
      btpn.value?.classList.add('active');
    } else {
      btpn.value?.classList.remove('active');
    }

    if (checkAndSetRelativePosition()) return;

    setFixedPosition();
  }, 100);
  const btp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollEvent);
  });

  onMounted(() => {
    checkPage(btpn.value);
    window.addEventListener('scroll', scrollEvent);
  });

  watch(
    () => route.name,
    () => {
      checkPage(btpn.value);
    },
  );

  return {
    btpn,
    routeName,
    btp,
  };
};

export default {
  name: 'BackToTop',
  props: {
    relativeContainerSelector: {
      type: String,
      default: '',
    },
  },
  setup,
};
</script>

<style lang="scss" scoped>
.Back-to-top.active.no-page {
  display: none;
}
.Back-to-top.active {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Back-to-top {
  opacity: 0.8;
  display: none;
  position: fixed;
  width: 42px;
  height: 42px;
  bottom: 3%;
  right: 1%;
  z-index: 15;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  &:hover {
    background-color: var(--black);
    .icon-arrow-up-solid {
      color: var(--white);
    }
  }
}
@include mediaMax($grid-breakpoints-lg) {
  .Back-to-top {
    right: 15px;
  }
}
</style>
