import { join, isNil, forEach, map, find, propEq, filter, isEmpty, or, all, prop, includes, pick } from 'ramda';
import { defineStore } from 'pinia';
import getMemberAds from '@/api/ad/member-page';
import { isFunction, rejectEmpty } from '@/helper/data-process';
import { MemberCenterPageADKeyEnum } from '@/constant/ad/member-ad-type';
import { findFormatter } from '@/helper/ad/ad-helper';
import MemberCenterPageAdFormatter from '@/helper/ad/member-page-formatter';

/* 參考home-ad.js */
const state = () => ({
  // 用來判斷是否重取
  timestamp: new Date(),
  // 顯示哪些版位，ajax 參數用。
  displayItems: [],
  // 登入頁橫幅
  loginPageHorizontalPaint: {},
  // 會員中心橫幅
  horizontalPaintWeb: {},
  // 文字廣告
  textAD: {},
});

const stateKeys = Object.keys(state());

const getLoginPageHorizontalPaint = (context) => context.loginPageHorizontalPaint;
const getHorizontalPaintWeb = (context) => context.horizontalPaintWeb;
const getTimeStamp = (context) => context.timestamp;
const getTextAD = (context) => context.textAD;

// getter: isNeedFetch
const getCurrentAd = (context) => map((types) => context[MemberCenterPageADKeyEnum[types]], context.displayItems);
const hasNil = (context) => !all((x) => !isNil(x), getCurrentAd(context));
const hasEmpty = (context) => !all((x) => !isEmpty(x), getCurrentAd(context));
const isNeedFetch = (context) => {
  const state = pick(stateKeys, context);
  return or(hasEmpty(state), hasNil(state));
};

const getters = {
  getLoginPageHorizontalPaint,
  getHorizontalPaintWeb,
  getTimeStamp,
  isNeedFetch,
  getTextAD,
};

// 整理資料部分
const findAd = (types, data) => find(propEq('banner_type', types), data);
const getBannerType = (data) => map((ad) => prop('banner_type', ad), data);
const getFormatter = (types) => MemberCenterPageAdFormatter[findFormatter('memberCentre', types)];
const filterExisted =
  (context) =>
  ({ data }) =>
    filter((ad) => includes(prop('banner_type', ad), context.displayItems), data);
const processAd = (data) => map((types) => (isFunction(getFormatter(types)) ? getFormatter(types)(findAd(types, data)) : {}), getBannerType(data));

const updateCurrentTimeStamp = (context) => (context.timestamp = new Date());
const updateAd = (context) => (data) =>
  forEach((ad) => {
    const stateName = MemberCenterPageADKeyEnum[ad.type];
    context[stateName] = ad;
  }, data);

const actions = {
  // 取得資料部分
  fetchMemberAd() {
    const adTypes = join(',', this.displayItems);
    getMemberAds(adTypes).then(filterExisted(this)).then(processAd).then(rejectEmpty).then(updateAd(this)).then(updateCurrentTimeStamp(this));
  },
  setADTypes(val) {
    this.displayItems = val;
  },
};

export const useMemberAdStore = defineStore('memberAd', {
  state,
  getters,
  actions,
});
