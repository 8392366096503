import { testAndParsePath } from '@/helper/category-page-regex';
import { useBrandStore } from '@/stores/brand';

const setDefaultBrandCategory = (store) => store({ brandId: 0, categoryId: 0 });

export default function ({ route }) {
  const brandStore = useBrandStore();
  const { initCurrentBrandCategory } = brandStore;
  const parseResult = testAndParsePath({ path: route.path, routeName: route.name });
  if (!parseResult) return setDefaultBrandCategory(initCurrentBrandCategory);
  const { brandId = 0, categoryId = 0 } = parseResult;
  return initCurrentBrandCategory({ brandId, categoryId });
}
