<template>
  <div v-if="isShowStepBar" class="step-bar">
    <div class="step-bar-icon d-flex justify-content-center align-items-center">
      <div class="step-number" :class="{ active: isStep1 }" :data-id="header.step1">
        <div class="num"><span>1</span></div>
      </div>
      <div class="step-line" :class="{ active: isStep1 }"></div>
      <div class="step-number" :class="{ active: isStep2 }" :data-id="header.step2">
        <div class="num"><span>2</span></div>
      </div>
      <div class="step-line" :class="{ active: isStep2 }"></div>
      <div class="step-number" :class="{ active: isStep3 }" :data-id="header.step3">
        <div class="num"><span>3</span></div>
      </div>
    </div>
    <div class="step-bar-wording d-flex align-items-center justify-content-between mt-1">
      <div class="step-wording w-10" :class="{ active: isStep1 }">
        <div class="wording"><span>購物車</span></div>
      </div>
      <div class="step-wording w-30" :class="{ active: isStep2 }">
        <div class="wording"><span>選擇配送與付款資訊</span></div>
      </div>
      <div class="step-wording w-10" :class="{ active: isStep3 }">
        <div class="wording"><span>完成訂單</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import cartStepComposables from '@/composables/cart/cart-step';
import { header } from '@/constant/element-id';

export default {
  name: 'StepBar',
  setup() {
    const route = useRoute();

    const step = computed({
      get: () => cartStepComposables(),
      set: (val) => cartStepComposables(val),
    });
    const isStep1 = computed(() => {
      return step.value >= 1;
    });
    const isStep2 = computed(() => {
      return step.value >= 2;
    });
    const isStep3 = computed(() => {
      return step.value >= 3;
    });
    const isShowStepBar = computed(() => {
      return !(route.name === 'login');
    });

    return {
      header: ref(header),
      step,
      isStep1,
      isStep2,
      isStep3,
      isShowStepBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.step-bar-icon {
  width: 95%;
  margin: 0 auto;
}
.num {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--m-gray-c);
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
}
span {
  @include fontSize(15);
}
.step-line {
  width: calc(70% / 2);
  border-top: 2px solid var(--m-gray-c);
}
.step-number {
  width: 10%;
  margin: 0 auto;
  &:first-child {
    margin-left: 12px;
  }
  &:last-child .num {
    margin-right: 20px;
  }
}
.step-bar-wording {
  margin: 0 auto;
  width: 90%;
  color: var(--m-gray-c);
  @include fontSize(15);
}
.active {
  .num {
    background-color: var(--e-dark-gold) !important;
  }
  .wording > span {
    color: var(--e-dark-gold) !important;
  }
  border-color: var(--e-dark-gold) !important;
}
.step-bar {
  width: calc(100% + 30px);
  margin: 0 -15px;
  background-color: var(--l-gray-f4);
  padding-top: 1rem;
  padding-bottom: 0;
}
@include mediaMin($grid-breakpoints-lg) {
  .step-bar {
    width: initial;
    background: none;
    padding-top: 0.5rem;
    margin: initial;
    margin-right: 1.5rem;
  }
  .step-bar-wording,
  .w-10,
  .w-30 {
    width: initial;
  }
  .step-line {
    min-width: 150px;
    margin: 0 0.5rem;
  }
}
@include mediaMax($grid-breakpoints-lg) {
  .step-number:first-child {
    margin: auto;
  }
  .step-number:last-child .num {
    margin: auto;
  }
}
@include mediaMax($grid-breakpoints-sm) {
  .step-number:first-child {
    margin-left: 2.5%;
  }
  .step-number:last-child .num {
    margin-right: 20%;
  }
  .step-line {
    min-width: auto;
    margin: 0 -1.5rem;
  }
}
</style>
