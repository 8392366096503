const GlobalSystemErrorMessage = `系統忙碌中，請稍候再試。`;

export default {
  form: {
    submit: '送出',
    reset: '清除重填',
    submitConfirm: '確認送出',
    goPreviousPage: '返回前頁',
    cancel: '取消',
    confirm: '確認',
    resend: '重新發送',
    sendVerifyCode: '我要綁定手機，發送驗證碼。',
    ignore: '暫不綁定手機，下次再提醒。',
    submitFail: '表單送出失敗，請稍候再試。',
  },
  about: {
    title: 'About',
    introduction: 'This page is made to give you more informations.',
  },
  login: {
    login: '登入',
    signIn: '登入',
    account: '帳號',
    accountPlaceHolder: '手機號(不含國碼)/會員卡號/自訂帳號',
    password: '密碼',
    passwordPlaceHolder: '請輸入密碼',
    rememberMe: '記住我',
  },
  forgot: {
    title: '忘記密碼',
    reset: '重設密碼',
    finished: '新密碼設定完成',
    finishedWording: '您已完成新密碼設定，請重新登入',
    idPlaceHolder: '請輸入有效證件號碼',
    birthdayPlaceHolder: '請輸入西元生日，例如19650103',
    phoneVerify: '簡訊驗證',
    mailVerify: '電子信箱驗證',
    email: '電子信箱',
    phone: '手機',
    resend: '重新發送',
    verifyPlaceHolder: '請輸入六位數驗證碼',
    newPassword: '新密碼',
    newPasswordConfirm: '確認新密碼',
    verifyCodeAlreadySend: '驗證碼已發送至',
  },
  member: {
    loginMessage: {
      title: {
        needRegistry: '登入失敗',
        locked: '連續輸入密碼錯誤，此帳號已被鎖定',
        unverified: '帳號未驗證',
        incomplete: '會員資料不完整',
        unverifiedPhoneToSignIn: '登入失敗',
        invalid: '登入失敗',
        system: '登入失敗',
      },
      content: {
        needRegistry: '您輸入的帳號或密碼不正確',
        locked: '請透過「忘記密碼」解除鎖定',
        unverified: '此帳號尚未完成簡訊驗證',
        incomplete: '請完成會員資料填寫方可登入',
        unverifiedPhoneToSignIn: '輸入您的證件號碼或會員卡號，依指示完成驗證，即可使用手機登入',
        invalid: '您輸入的帳號或密碼不正確',
        system: '系統忙碌中，請稍候再試',
      },
    },
    verify: {
      phone: '手機號碼',
      phoneVerify: '將手機綁定誠品會員帳號',
      finished: '驗證完成',
      phoneFinishedWording: '您已完成驗證，下次即可使用手機號碼登入',
      confirm: '確定',
      loggedIn: '您已登入成功。',
      verifyImmediatelyWording: '邀請您完成簡訊驗證，將手機綁定誠品會員帳號，未來即可使用手機登入，以獲得更便捷的服務體驗。',
      smsNotes: '我們將發送驗證碼至以下您於誠品登錄的手機號碼。',
      syncMobile: '若您在此更換手機號碼並完成綁定驗證後，將同步更新您會員中心【行動電話】欄位資訊。',
      oldPassword: '必填，請輸入正確密碼',
      newPassword: '請設定6~24碼英數混合密碼',
      confirmPassword: '確認密碼需與新密碼相同',
    },
    verifyError: {
      system: GlobalSystemErrorMessage,
      expired: '驗證碼已逾時，請按「重新發送」。',
      invalid: '驗證碼錯誤。',
      limited: '當日僅可發送5次簡訊，已達上限。',
      FailedSendVerificationCode: '驗證碼發送失敗。',
      failedModify: '密碼修改失敗。',
    },
  },
  cart: {
    step2: {
      invoiceMobileDevice: '手機載具號碼',
      invoiceIDDevice: '自然人憑證載具號碼',
      invoicePaper: '紙本證明',
      companyTitle: '公司抬頭',
      companyUid: '統一編號',
    },
  },
  error: {
    system: GlobalSystemErrorMessage,
    coupon: {
      alreadyClaimed: '此優惠券限領1次，您已領取。',
      duplicate: '此序號已重複領取，請重新輸入。',
      notFound: '通關密語/序號不正確或優惠券已過期，請重新輸入。',
      reachedLimit: '很抱歉，此優惠券已全數領取完畢。',
    },
  },
};
