import { defineStore } from 'pinia';
import { forgotPasswordStep, verifyType } from '@/constant/forgot-password';

const state = () => ({
  step: forgotPasswordStep.verify,
  type: verifyType.none,
  token: '',
  email: '',
  phone: '',
  country: '',
});

const actions = {
  setStep(step) {
    this.step = step;
  },
  setType(type) {
    this.type = type;
  },
  setToken(token) {
    this.token = token;
  },
  setEmail(email) {
    this.email = email;
  },
  setPhone(phone) {
    this.phone = phone;
  },
  setCountry(country) {
    this.country = country;
  },
};

const getStep = (state) => state.step;
const getType = (state) => state.type;
const getToken = (state) => state.token;
const getEmail = (state) => state.email;
const getPhone = (state) => state.phone;
const getCountry = (state) => state.country;

const getters = {
  getStep,
  getType,
  getToken,
  getEmail,
  getPhone,
  getCountry,
};

export const useForgotStore = defineStore('forgot', {
  state,
  getters,
  actions,
});
