<template>
  <div class="cart-header header-bg-color" :class="{ 'pt-2': notInlineApp }">
    <div class="container-lg d-block d-lg-flex justify-content-between">
      <div v-if="notInlineApp" class="logo-wrapper col-6 col-sm-4 col-lg-2 mt-0 mb-1 mt-lg-1 mb-lg-2 p-0">
        <router-link class="navbar-brand" to="/" :data-id="header.logo">
          <div class="logo"></div>
        </router-link>
      </div>
      <step-bar v-if="isShowStepBar"></step-bar>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { includes } from 'ramda';
import StepBar from '@/components/cart/step-bar.vue';
import { cartPath } from '@/constant/login-path';
import { header } from '@/constant/element-id';
import checkIsInlineApp from '@/helper/check-inline-app';

const components = {
  StepBar,
};

export default {
  name: 'CartHeader',
  components,
  setup() {
    const route = useRoute();
    const notInlineApp = ref(false);

    const isShowStepBar = computed(() => {
      return includes(route.path, cartPath);
    });

    onBeforeMount(() => {
      notInlineApp.value = !checkIsInlineApp();
    });

    return {
      notInlineApp,
      header: ref(header),
      isShowStepBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  align-items: center;
}
.logo {
  width: 130px;
  height: 33px;
  background-image: url('@/static/images/logo_r.webp');
  background-size: contain;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}
.header-bg-color {
  background-color: var(--white);
}
@include mediaMin($grid-breakpoints-lg) {
  .container {
    padding: 1rem 0;
  }
  .header-bg-color {
    background-color: var(--white);
  }
  .logo {
    background-image: url('@/static/images/logo_r.webp');
    width: 150px;
    height: 39px;
  }
}
</style>
