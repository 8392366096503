import { defineStore } from 'pinia';
import { find, propEq } from 'ramda';
import { formatKeywords, formatGeneralType, formatHorizontalPaint, formatBrands } from '@/helper/ad/global-page-formatter';
import { isEmptyValue } from '@/helper/data-process';
import { globalAdEnum } from '@/constant/ad';

const findByBannerType = (type, res) => find(propEq('banner_type', type), res);
const findByType = (type, res) => find(propEq('type', type), res);

const state = () => ({
  horizontalPaint: {},
  mobileHorizontalPaint: {},
  littleBanner: {},
  coverBanner: {},
  coverBannerWatermark: {},
  brands: [],
  categoryBanner: {},
  keywords: [],
  timestamp: +new Date(),
});

const formatterByStateName = {
  horizontalPaint: formatHorizontalPaint,
  mobileHorizontalPaint: formatHorizontalPaint,
  littleBanner: formatGeneralType,
  coverBanner: formatGeneralType,
  coverBannerWatermark: formatGeneralType,
  brands: formatBrands,
  categoryBanner: (val) => val,
  keywords: (val) => formatKeywords(val).keywords,
};

const adContentGetterByStateName = {
  horizontalPaint: (data) => findByBannerType(globalAdEnum.horizontalPaint, data),
  mobileHorizontalPaint: (data) => findByBannerType(globalAdEnum.mobileHeadAd, data),
  littleBanner: (data) => findByBannerType(globalAdEnum.littleBanner, data),
  coverBanner: (data) => findByBannerType(globalAdEnum.coverBanner, data),
  coverBannerWatermark: (data) => findByBannerType(globalAdEnum.coverBannerWatermark, data),
  brands: (data) => findByType(globalAdEnum.brands, data),
  categoryBanner: (data) => findByType(globalAdEnum.categoryBanner, data),
  keywords: (data) => findByBannerType(globalAdEnum.keywords, data),
};

const getters = {
  getHorizontalPaint(context) {
    return context.horizontalPaint;
  },
  getMobileHorizontalPaint(context) {
    return context.mobileHorizontalPaint;
  },
  getLittleBanner(context) {
    return context.littleBanner;
  },
  getCoverBanner(context) {
    return context.coverBanner;
  },
  getCoverBannerWatermark(context) {
    return context.coverBannerWatermark;
  },
  getKeywords(context) {
    return context.keywords;
  },
  getTimeStamp(context) {
    return context.timestamp;
  },
  // get header menu category banner
  getCategoryBanner(context) {
    return context.categoryBanner;
  },
  // get header menu brands
  getMenuBarBrands(context) {
    return context.brands.slice(0, 16);
  },
  getBrands(context) {
    return context.brands;
  },
};

const actions = {
  saveObjectTypeAD(stateName, data) {
    const content = adContentGetterByStateName[stateName](data);
    if (isEmptyValue(content)) {
      this[stateName] = {};
      return;
    }

    const formattedContent = formatterByStateName[stateName](content);
    this[stateName] = formattedContent;
  },
  setData(data) {
    this.saveObjectTypeAD('keywords', data);
    this.saveObjectTypeAD('horizontalPaint', data);
    this.saveObjectTypeAD('littleBanner', data);
    this.saveObjectTypeAD('coverBanner', data);
    this.saveObjectTypeAD('coverBannerWatermark', data);
  },
  setMobileData(data) {
    this.saveObjectTypeAD('mobileHorizontalPaint', data);
  },
  // action save header menu category banner
  setCategoryBanner(data) {
    this.saveObjectTypeAD('categoryBanner', data);
  },
  // action save header menu brands
  setBrands(data) {
    const content = adContentGetterByStateName.brands(data);
    this.brands = formatBrands(content);
  },
};

export const useGlobalAdStore = defineStore('globalAd', {
  state,
  getters,
  actions,
});
