export const loadRecaptcha = function () {
  const recaptchaScriptElementId = 'recaptcha-script';
  const find = document.querySelector(`#${recaptchaScriptElementId}`);
  if (find) return;
  const script = document.createElement('script');
  script.id = recaptchaScriptElementId;
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY}`;
  script.defer = true;
  script.async = true;

  script.addEventListener('load', () => {
    window.grecaptcha.enterprise.ready(() => {
      window.esliteRecaptchaReady = true;
    });
  });
  script.addEventListener('error', () => {
    document.head.removeChild(script);
    window.esliteRecaptchaReady = false;
  });
  document.head.appendChild(script);
};
