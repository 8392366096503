import { includes } from 'ramda';
import { loadRecaptcha } from '@/helper/recaptcha';

// 需要recaptcha的 page name
const roleList = ['login', 'member-customer-customer-service'];

export default function ({ route }) {
  if (!includes(route.name, roleList)) return;
  loadRecaptcha();
}
