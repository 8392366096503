import { emptyReplace, linkTarget } from '@/helper/data-process';
import { adTypeEnum } from '@/constant/ad/ad-type';

const formatBigBanner = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.brandLevel3),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
  target: linkTarget(ad.blank),
  alt: emptyReplace(ad.alt, ''),
  image: emptyReplace(ad.image, ''),
  link: emptyReplace(ad.link, '#'),
});

export default {
  formatBigBanner,
};
