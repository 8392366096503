<template>
  <div class="fraud-prevention tw-flex tw-items-center tw-justify-center lg:tw-justify-start">
    <div class="icon">
      <span class="icon-gold-info">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
        <span class="path6"></span>
        <span class="path7"></span>
        <span class="path8"></span>
      </span>
    </div>
    <a href="https://events.eslite.com/temp/211228-Fraud/edm.html">
      <span class="words">防詐3要訣：不聽信、不操作、掛斷電話</span>
      <span class="detail">(詳)</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FraudPrevention',
};
</script>

<style lang="scss" scoped>
.fraud-prevention {
  background-color: var(--l-gray-f4);
  @include pxToRem('padding-top', 10);
  @include pxToRem('padding-bottom', 10);
  span.words,
  span.detail {
    vertical-align: top;
    @include fontSize(15);
    font-weight: 500;
    @include pxToRem('letter-spacing', 0.5);
  }
  span.words {
    color: var(--black);
  }
  span.words:hover {
    color: var(--brownish-grey);
  }
  span.detail {
    color: var(--ocean-blue);
  }
  a {
    text-decoration-color: var(--brownish-grey);
  }
}
@include mediaMin($grid-breakpoints-lg) {
  .fraud-prevention {
    background-color: var(--white);
    @include pxToRem('padding-top', 0);
    @include pxToRem('padding-bottom', 6);
    span.words,
    span.detail {
      @include fontSize(17);
      @include pxToRem('line-height', 22);
      @include pxToRem('letter-spacing', 1);
    }
    span.detail {
      @include pxToRem('padding-left', 8);
    }
    .icon {
      display: inline-block;
      width: 33px;
    }
    span.icon-gold-info {
      @include fontSize(22);
      @include pxToRem('margin-right', 8);
    }
  }
}
</style>
