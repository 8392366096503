import { map, values } from 'ramda';
import { emptyReplace, linkTarget } from '@/helper/data-process';

// 是相對路徑？ (第一個字斜線但不是雙斜線、不含 http)
const isRelativePath = (path) => path.indexOf('/') === 0 && path.indexOf('//') !== 0 && path.indexOf('http') === -1;

export const formatKeywords = (data) => {
  return {
    id: emptyReplace(data.id, ''),
    page: emptyReplace(data.page, 'GlobalPage'),
    type: data.banner_type,
    blank: emptyReplace(data.blank, false),
    keywords: map(
      (set) => ({
        link: set?.link,
        text: set?.text,
      }),
      values(emptyReplace(data.keywords, {})),
    ),
  };
};

export const formatHorizontalPaint = (data, defaultImage = '') => {
  if (data === undefined) return {};
  return {
    id: emptyReplace(data.id, ''),
    page: emptyReplace(data.page, 'GlobalPage'),
    type: data.banner_type,
    image: emptyReplace(data?.image, defaultImage),
    alt: emptyReplace(data?.alt, ''),
    link: emptyReplace(data?.link, '#'),
    blank: emptyReplace(data?.blank, '#'),
    color: emptyReplace(data?.color, '#F4F4F4'),
  };
};

export const formatGeneralType = (data, defaultImage = '') => {
  return {
    id: emptyReplace(data.id, ''),
    page: emptyReplace(data.page, 'GlobalPage'),
    type: data.banner_type,
    image: emptyReplace(data?.image, defaultImage),
    alt: emptyReplace(data?.alt, ''),
    link: emptyReplace(data?.link, '#'),
    blank: emptyReplace(data?.blank, '#'),
  };
};

export const formatBrands = (data) => {
  if (!data) return [];
  const mappingKey = 'Brand_ID_';
  return (
    Object.keys(data)
      .map((key) => {
        if (key.indexOf(mappingKey) < 0) return null;
        const brandContent = data[key];
        if (!brandContent.link) return null;
        return {
          id: parseInt(key.replace(mappingKey, ''), 10),
          image: emptyReplace(brandContent.image, ''),
          name: emptyReplace(brandContent.name, ''),
          link: brandContent.link,
          target: linkTarget(data.blank),
          isLinkTag: !isRelativePath(brandContent.link) || data.blank === true, // 是否 a 連結 (不是 nuxt-link)
        };
      })
      .filter((item) => item !== null) || []
  );
};
