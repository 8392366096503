import { scrollBehaviorOffsetY } from '@/constant/scroll';
import { ignorePages, setHistory, getHistory } from '@/helper/url/history';

// 避免頁面內容還沒更新，就先執行scroll
function asyncScroll(value, timer = 300) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value);
    }, timer);
  });
}

export default function (to, from, savedPosition) {
  const isFromLogin = from.name === 'login';
  const isSamePage = to.path === from.path;
  const page = to.name || '';
  const hashPosition = {
    el: to.hash,
    top: scrollBehaviorOffsetY,
  };

  // 同頁切換 hash
  if (isSamePage && to.hash) {
    setHistory({ ...to, left: 0, top: 0 });
    return hashPosition;
  }

  const position = getHistory(to);

  if (savedPosition && position !== false) {
    if (ignorePages.includes(page) === false) {
      const usePosition = savedPosition.top > position.top ? savedPosition : position;
      setHistory({ ...to, left: 0, top: 0 });
      return asyncScroll({ left: usePosition.left, top: usePosition.top }, 1000);
    }

    return { left: 0, top: 0 };
  }

  if (to.hash) {
    return hashPosition;
  }

  if (position !== false && isFromLogin) {
    const rtn = { left: position.left, top: position.top };
    setHistory({ ...to, left: 0, top: 0 });
    return asyncScroll(rtn, 2500);
  }

  setHistory({ ...to, left: 0, top: 0 });
  return { left: 0, top: 0 };
}
