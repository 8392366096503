export default function ({ route }) {
  if (!route.path.match(/^\/events\/210312online/)) return false;

  const toPath = `${import.meta.env.VITE_BASE_URL}/events/210305signup/index.html`;
  const { href } = window.location;
  const queryStartIndex = href.indexOf('?');
  if (queryStartIndex < 0) {
    window.location = toPath;
    return true;
  }
  window.location = toPath + href.substr(queryStartIndex);
  return true;
}
