import { siteQueryKeys, layoutQueryEnum } from '@/constant/site-query-string';
import { virtualShelfRouteNames, virtualShelfNotSupportHash, virtualShelfNotSupportHashAtHome, virtualShelfAlertRedirectStatus } from '@/constant/virtual-shelf';
import { useVirtualShelfAlertStore } from '@/stores/virtual-shelf-alert';

/**
 * 確保『虛擬貨架』的 route link 都有帶虛擬貨架 query string
 */
export default function ({ route, from, redirect }) {
  const virtualShelfAlertStore = useVirtualShelfAlertStore();
  const { setIsShowVirtualShelfPageNotSupport, setRedirectStatus } = virtualShelfAlertStore;

  // from 不含 虛擬貨架 query string，不需處理
  if (from.query[siteQueryKeys.layout] !== layoutQueryEnum.virtualShelf) return false;

  // 活動嵌入頁(sub frame)，a-link 外部不支援網站，以 target=_parent 開啟「首頁」+ 此頁不支援 alert
  if (route.hash === virtualShelfNotSupportHashAtHome) {
    setIsShowVirtualShelfPageNotSupport(true);
    return false;
  }
  // a-link 導 virtual-shelf not support 頁面
  if (route.hash === virtualShelfNotSupportHash) {
    setIsShowVirtualShelfPageNotSupport(true);
    setRedirectStatus(virtualShelfAlertRedirectStatus.isFromNotSupportHash);
    redirect({ ...route, hash: null });
    return true;
  }

  // 確認route是virtual-shelf允許的頁面，若不是，顯示alert
  if (!virtualShelfRouteNames[route.name]) {
    setIsShowVirtualShelfPageNotSupport(true);
    // 和前頁不同，提前返回前頁
    const isEarlyRedirectBack = route.name !== from.name;
    if (isEarlyRedirectBack) setRedirectStatus(virtualShelfAlertRedirectStatus.isEarlyRedirectBack);
    if (isEarlyRedirectBack) redirect(from);
    return isEarlyRedirectBack;
  }

  // route 已有 虛擬貨架 query string，不需處理
  if (route.query[siteQueryKeys.layout] === layoutQueryEnum.virtualShelf) return false;

  console.log('virtual shelf redirect', route);
  // redirect 帶上虛擬貨架 query string
  redirect({
    ...route,
    query: {
      ...route.query,
      [siteQueryKeys.layout]: layoutQueryEnum.virtualShelf,
      [siteQueryKeys.virtualShelfStore]: from.query[siteQueryKeys.virtualShelfStore],
    },
  });
  return true;
}
