<template>
  <div class="bgc d-flex flex-column">
    <eslite-header style="background-color: var(--white)"></eslite-header>
    <router-view style="flex: 1; min-height: 50vh" />
    <eslite-footer></eslite-footer>
    <loading v-if="getLoadingStatus"></loading>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import EsliteHeader from '@/components/layout/header.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import { getDefaultMeta } from '@/helper/meta';
import Loading from '@/components/utility/loading.vue';

export default {
  name: 'HomeMemberProfile',
  components: {
    EsliteHeader,
    EsliteFooter,
    Loading,
  },
  metaInfo() {
    return getDefaultMeta();
  },
  setup() {
    const loadingStore = useLoadingStore();
    const { getLoadingStatus } = storeToRefs(loadingStore);

    return {
      getLoadingStatus,
    };
  },
};
</script>

<style scoped lang="scss">
.bgc {
  background-color: var(--l-gray-f4);
  min-height: 100vh;
}
</style>
