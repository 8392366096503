import { defineStore } from 'pinia';

const state = () => ({
  isHistoryPop: false,
  savedPosition: 0,
});

const getters = {
  getIsHistoryPop: (state) => state.isHistoryPop,
  getSavedPosition: (state) => state.savedPosition,
};

const actions = {
  setIsHistoryPop(value) {
    this.isHistoryPop = value;
  },
  setSavedPosition(value) {
    // console.log(state.savedPosition);
    this.savedPosition = value;
  },
};

export const useHistoryStore = defineStore('history', {
  state,
  getters,
  actions,
});
