/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import { Base64 } from 'js-base64';

export default class Base64XorCipherV2 {
  constructor(key) {
    this.key = key;
  }

  // eslint-disable-next-line class-methods-use-this
  boolDecode(boolStr = '') {
    return boolStr.toLowerCase() === 'true' ? true : boolStr.toLowerCase() === 'false' ? false : boolStr;
  }

  encrypt(input) {
    const inputStr = typeof input === 'string' ? input : JSON.stringify(input);
    const b64EncodeStr = Base64.encode(inputStr);

    return [...b64EncodeStr].map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ this.key.charCodeAt(i % this.key.length))).join('');
  }

  decrypt(encryptedInput) {
    const xorStr = [...encryptedInput].map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ this.key.charCodeAt(i % this.key.length))).join('');
    let B64decryptData = '';

    const isValid = Base64.isValid(xorStr);

    // console.group(`base64 valid`);
    // console.log(xorStr);
    // console.log({ valid: Base64.isValid(xorStr) });
    // console.groupEnd();

    if (isValid) B64decryptData = Base64.decode(xorStr);
    else return false;

    try {
      return JSON.parse(B64decryptData);
    } catch (error) {
      return B64decryptData;
    }
  }
}
